import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import RegisterTrademarkForm from './registerTrademarkForm.js';
import Footer from "./footer.js";


const ServicesPage = () => {
  const [showForm, setShowForm] =   useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if(e.target.className === 'modal') {
      setShowForm(false);
    }
  };  
  return (
      <div className='services-page'>
          {/* <Header /> */}
          <div className='comp'>
            <span className='comp-name'>Services</span>
            <span className='comp-descrip'>Excelling in Trademark Protection - Our Specialized Services</span>
          </div>
        <main>
          <div className='services-list'>
            <p>San Novus Trademark specializes exclusively in trademark law. We offer unparalleled expertise and precision in protecting your 
              brand’s identity. We’re the best in the industry and can help you with the following:</p>
          </div>
          <div className='services-list-alt'>
            <h3><a href='/trademark-searches'>Trademark Searching</a></h3>
            <p>Ensuring unique and defensible branding for your mark</p>
          </div>
          <div className='services-list'>
            <h3><a href='/trademark-registration-applications'>Securing Your Trademark</a></h3>
            <p>Comprehensive assistance with registration applications</p>
          </div>
          <div className='services-list-alt'>
            <h3 href='/trademark-oar'><a href='/trademark-registration-applications'>Navigating Registration Challenges</a></h3>
            <p>Expert responses to application rejections</p>
          </div>
          <div className='services-list'>
            <h3 href='/trademark-sou'><a href='/trademark-sou'>Trademark Statements of Use</a></h3>
            <p>Finalizing your registration with accurate usage declarations</p>
          </div>
          <div className='services-list-alt'>
            <h3><a href='/trademark-renewals'>Trademark Maintenance</a></h3>
            <p>Renew your registered trademark for ongoing protection</p>
          </div>
          <div className='services-list'>
            <h3><a href='/international-trademarks'>International Coverage</a></h3>
            <p>Extend your brand’s reach with foreign trademark applications</p>
          </div>
              {/* <div style={{textAlign: 'left', alignContent:'flex-start', alignItems:'flex-start', fontFamily:'Roboto-Serif'}}>
                <ul>
                  <li><a href='/trademark-searches'>Trademark Searching</a>: Ensuring unique and defensible branding for your mark.</li>
                  <li style={{padding:'10px 0px 0px 0px'}}><a href='/trademark-oar'style={{color:'blue'}}>Securing your trademark</a>: Comprehensive assistance with registration applications.</li>
                  <li style={{padding:'10px 0px 0px 0px'}}><a href='/trademark-registration-applications'style={{color:'blue'}}>Navigating Registration Challenges</a>: Expert responses to application rejections.</li>
                  <li style={{padding:'10px 0px 0px 0px'}}><a href='/trademark-sou'style={{color:'blue'}}>Trademark Statements of Use</a>: Finalizing your registration with accurate usage declarations.</li>
                  <li style={{padding:'10px 0px 0px 0px'}}><a href='/trademark-renewals'style={{color:'blue'}}>Trademark Maintenance</a>: Renew your registered trademark for ongoing protection.</li>
                  <li style={{padding:'10px 0px 0px 0px'}}><a href='/international-trademarks'style={{color:'blue'}}>International Coverage</a>: Extend your brand’s reach with foreign trademark applications.</li>
                </ul>
              </div> */}
              
            {/* <p><b>Ready to move forward?</b> Click <span onClick={toggleForm} style={{cursor:'pointer', color:'blue'}}>here</span> to get started on our new 
          application form to start protecting your brand today! Have questions first? Contact us at <a href='info@sannovustrademark.com' style={{color:'blue'}}>info@sannovustrademark.com</a>.<br /></p> */}
          {/* </div> */}
          
          
          {showForm && (
              <div onClick={closeModal}>
                <RegisterTrademarkForm />
              </div>
            )}
        </main>
      </div>
    );
  };
  
  export default ServicesPage;