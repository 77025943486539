import React, { useEffect, useState } from 'react';

const Flowchart = () => {
  const [nodes, setNodes] = useState([
    { id: 1, x: 0, y: 50, text: 'Trademark Application Filed' },
    { id: 2, x: 0, y: 150, text: 'Application examined by examining attorney' },
    { id: 3, x: -400, y: 350, text: 'Application published for public opposition' },
    { id: 4, x: 400, y: 350, text: 'Examiner issues rejection of application' },
    { id: 5, x: 400, y: 500, text: 'File response to rejection' },
    { id: 6, x: -700, y: 800, text: 'Trademark Registers' },
    { id: 7, x: -100, y: 800, text: 'Notice of Allowance' },
  ]);

  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
      adjustNodesForResize();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    adjustNodesForResize();
  }, [dimensions]);

  const adjustNodesForResize = () => {
    const centerX = dimensions.width / 2; // Center of the viewport
    const maxX = Math.max(...nodes.map(node => node.x)); // Maximum x coordinate of the nodes
    const minX = Math.min(...nodes.map(node => node.x)); // Minimum x coordinate of the nodes
    const offsetX = centerX - ((maxX + minX) / 2 + 150); // Calculate the offset to center all nodes

    setNodes((prevNodes) =>
      prevNodes.map((node) => ({
        ...node,
        x: node.x + offsetX,
      }))
    );
  };

  const calculateNodeSize = () => {
    const width = dimensions.width < 600 ? dimensions.width * 0.9 : 300;
    const height = width / 4; // Maintain aspect ratio
    const fontSize = width / 20;
    return { width, height, fontSize };
  };

  const { width, height, fontSize } = calculateNodeSize();

  const getLines = () => {
    return [
      { from: nodes[0], to: nodes[1], type: 'straight' },
      { from: nodes[1], to: { x: nodes[1].x, y: nodes[1].y + 100 }, type: 'vertical' }, // Vertical line from node 2
      { from: { x: nodes[1].x, y: nodes[1].y + 100 }, to: { x: nodes[2].x, y: nodes[1].y + 100 }, type: 'horizontal' }, // Horizontal branch to the left
      { from: { x: nodes[1].x, y: nodes[1].y + 100 }, to: { x: nodes[3].x, y: nodes[1].y + 100 }, type: 'horizontal' }, // Horizontal branch to the right
      { from: { x: nodes[2].x, y: nodes[1].y + 100 }, to: nodes[2], type: 'vertical' }, // Vertical line to node 3
      { from: { x: nodes[3].x, y: nodes[1].y + 100 }, to: nodes[3], type: 'vertical' }, // Vertical line to node 4
      { from: { x: nodes[2].x, y: nodes[2].y + 14 }, to: { x: nodes[3].x, y: nodes[3].y + 14 }, type: 'straight'}, // Horizontal line between nodes 3 and 4
      { from: nodes[3], to: nodes[4], type: 'straight', text: '3 months (extensions of time available)' }, // Vertical line to node 5
      { from: { x: nodes[4].x, y: nodes[4].y + 12 }, to: { x: nodes[4].x - 400, y: nodes[4].y + 12 }, type: 'horizontal' }, // Horizontal left from node 5
      { from: { x: nodes[4].x - 400, y: nodes[4].y + 12 }, to: { x: nodes[4].x - 400, y: nodes[4].y - 136 }, type: 'horizontal' }, // Vertical up to next horizontal line
      { from: nodes[2], to: { x: nodes[2].x, y: nodes[2].y + 300 }, type: 'vertical' }, // Vertical line from node 3
      { from: { x: nodes[2].x, y: nodes[2].y + 300 }, to: { x: nodes[2].x - 300, y: nodes[2].y + 300 }, type: 'horizontal' }, // Horizontal branch to the left
      { from: { x: nodes[2].x, y: nodes[2].y + 300 }, to: { x: nodes[2].x + 300, y: nodes[2].y + 300 }, type: 'horizontal' }, // Horizontal branch to the right
      { from: { x: nodes[2].x - 300, y: nodes[2].y + 300 }, to: nodes[5], type: 'vertical' }, // Vertical line to node 6
      { from: { x: nodes[2].x + 300, y: nodes[2].y + 300 }, to: nodes[6], type: 'vertical' }, // Vertical line to node 7
    //   { from: nodes[4], to: nodes[6], type: 'straight' },
    ];
  };

  const generatePath = (line) => {
    const { from, to, type } = line;
    if (!from || !to) return ''; // Add this check to prevent accessing properties of undefined

    if (type === 'straight') {
      return `M${from.x + 150},${from.y + 25} L${to.x + 150},${to.y + 25}`;
    } else if (type === 'vertical') {
      return `M${from.x + 150},${from.y + 25} L${to.x + 150},${to.y + 25}`;
    } else if (type === 'horizontal') {
      return `M${from.x + 150},${from.y + 25} L${to.x + 150},${to.y + 25}`;
    }
    return '';
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '1500px' }}>
      <svg width="100%" height="1000px" style={{ position: 'absolute', top: 0, left: 0 }}>
        {getLines().map((line, index) => (
          <React.Fragment key={index}>
            <path
              d={generatePath(line)}
              stroke="black"
              fill="transparent"
            />
            {line.text && (
              <text x={(line.from.x + line.to.x) / 2 + 175} y={line.from.y + 120} fill="black" fontSize="1em">
                {line.text}
              </text>
            )}
          </React.Fragment>
        ))}
      </svg>
      {nodes.map((node) => (
        <div
          key={node.id}
          style={{
            width: 300,
            height: 75,
            backgroundColor: 'lightblue',
            textAlign: 'center',
            alignContent: 'center',
            lineHeight: '1',
            borderRadius: '10px',
            position: 'absolute',
            left: node.x,
            top: node.y,
            fontSize: '1.125rem',
            fontFamily: 'Roboto Serif',
            transition: 'font-size 0.3s ease',
          }}
        >
          {node.text}
        </div>
      ))}
    </div>
  );
};

export default Flowchart;
