// Footer.js
import React from "react";
import logo from './FooterLogo.png';
import './App.css';

const Footer = () => {
  return (
    <footer /* className="footer bg-dark text-light py-4" */ className="footer">
      <div className="container">
        <div className="row align-items-center mb-3">
          {/* Footer Logo and Contact Info */}
          <div className="col-md-6 text-center text-md-start">
            <img src={logo} alt='Company Logo' className="footer-logo mb-2"/>
            <p className="mb-0">
              Email: <a href='mailto:info@sannovustrademark.com' style={{color: '#00AFEF'}}>info@sannovustrademark.com</a>
            </p>
          </div>

          {/* Navigation Links */}
          <div className="col-md-6 text-center text-md-end">
            <ul className="list-inline mb-0">
              <li className="list-inline-item"><a href="/" className="footer-link">Home</a></li>
              <li className="list-inline-item"><a href="/services" className="footer-link">Services</a></li>
              <li className="list-inline-item"><a href="/about" className="footer-link">About</a></li>
              <li className="list-inline-item"><a href="/why-us" className="footer-link">Why Us?</a></li>
              <li className="list-inline-item"><a href="/faq" className="footer-link">FAQ</a></li>
              <li className="list-inline-item"><a href="/pricing" className="footer-link">Pricing</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
