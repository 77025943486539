//protectedRoute.js
import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext.js';
import { useAuth } from './AuthContext.js';

const ProtectedRoute = () => {
  const { user, loading, setIntendedPath } = useContext(AuthContext);
  const location = useLocation();
  // const { loading } = useContext(AuthContext);
  // const { user } = useAuth();

  if(loading) {
    return <div>Loading...</div>;
  }

  if(!user) {
    setIntendedPath(location.pathname);
    return <Navigate to='/atty-login' />
  }
  // console.log("ProtectedRoute:", user);
  // return user ? <Outlet /> : <Navigate to="/atty-login" />;

  // if (!user) {
  //   // Redirect to login if user is not authenticated
  //   return <Navigate to="/atty-login" />;
  // }

  // return children;

  return <Outlet />;
};

export default ProtectedRoute;

