import React, {useState} from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";
import RegisterTrademarkForm from "./registerTrademarkForm.js";
import Footer from "./footer.js";

const TrademarkSOUPage = () => {
  const [showForm, setShowForm] =   useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if(e.target.className === 'modal') {
      setShowForm(false);
    }
  };          
  return (
      <div className='trademark-sou' style={{ fontFamily: 'Arial, sans-serif'}}>
          {/* <Header /> */}
  
        <main>
          <div className='comp'>
            <span className='comp-name'>Trademark Statements of Use</span>
            <span className='comp-descrip'>Completing Your Registration with Proof of Use</span>
          </div>
            <div className='main-section-alt'>
              <p>Congratulations on your federal trademark application being allowed! San Novus Trademark is here to assist you in 
                filing the Statement of Use – the final step in the trademark registration process for applications filed under an “intent-to-use” basis. 
                The U.S. Trademark Office requires proof of commercial use to officially register a trademark.</p>
            </div>
            <div className='main-section' style={{textAlign:'left'}}>
              <h3>A Statement of Use includes:</h3>
              <ol>
                <li><b><u>Evidence of Use:</u></b> Photographs or screenshots, showing your mark being used in connection with the sale of
                  the goods and/or services listed in your application.</li>
                <li><b><u>First Use Anywhere:</u></b> The date that you first started using your mark anywhere.</li>
                <li><b><u>First Use in Commerce:</u></b> The first date that you started using your mark in commerce. 
                  What is the date of the first sale, or the date that you first started offering services? 
                  These dates can be, and often are, identical or they can be different. We just need to submit accurate information to the 
                  Trademark Office.</li>
              </ol>
            </div>
            <div className='main-section'>
              <h3>Filing Requirements:</h3>
              <div className='use-req-container'>
                <p><b><u>For Goods (Trademarks)</u></b></p>
                <ul>
                    <li><u>Acceptable:</u> The mark on a product itself, the mark on product packaging, website order page, user manuals, clothing 
                    hang tags, software screenshots.</li>
                    <li><u>NOT Acceptable:</u> The mark on invoices, non-transactional websites, shipping labels, advertisements, 
                    news articles, ornamental clothing use, pre-order promotions.</li>
                </ul>
                <p><b><u>For Services (Service Marks)</u></b></p>
                <ul>
                  <li><u>Acceptable:</u> The mark in advertisements, physical store signage, invoices, website service registration links, business vehicles.</li>
                  <li><u>NOT Acceptable:</u> PDF/image files generated only for filing with the Trademark Office, marks without 
                    context, radio ad recordings.</li>
                </ul>
                <p><b>Not quite ready for commercial use yet?</b> That's not a problem! The U.S. Trademark Office allows for the filing of up to five (5) 
                extensions of time, in six-month increments, before requiring you to file the Statement of Use. That's an available time limit of up to 
                three (3) years before you are required to provide evidence of use.</p>
              </div>
            </div>
           {showForm && (
              <div onClick={closeModal}>
                <RegisterTrademarkForm />
              </div>
            )}
            <div className='main-section'>
              <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
              <FAQs tags="usage" />
          </div>
        </main>
      </div>
    );
  };
  
  export default TrademarkSOUPage;