import React, { useState, useEffect } from "react";
import UseSelection from "./useBox.js";
import CountryCombobox from "./countryCB.js";
import goodsProduct from './goods-product.jpg';
import goodsPackaging from './goods-product_packaging.jpg';
import goodsWebsite from './goods-website.jpg';
import servicesSignage from './services-signage.jpg';
import servicesWebsite from './services-website.jpg';
import { Modal, Button, Form, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import HelpGuide from './useHelpGuide.js'

const clioURLMapping = {
    1:17455355, //ID for "URL 1"
    2:17455370, //ID for "URL 2"
    3:17455385, //ID for "URL 3"
    4:17455400, //ID for "URL 4"
    5:17455415, //ID for "URL 5"
    6:17455430, //ID for "URL 6"
    7:17455445, //ID for "URL 7"
    8:17455460, //ID for "URL 8"
    9:17455475, //ID for "URL 9"
    10:17455490, //ID for "URL 10"
};

const clioForeignAppCountryMapping = {
    1:17400410, //ID for "Foreign Application Country 1"
    2:17400470, //ID for "Foreign Application Country 2"
    3:17400530, //ID for "Foreign Application Country 3"
    4:17400560, //ID for "Foreign Application Country 4"
    5:17400605, //ID for "Foreign Application Country 5"
    6:17400650, //ID for "Foreign Application Country 6"
    7:17400695, //ID for "Foreign Application Country 7"
    8:17400740, //ID for "Foreign Application Country 8"
    9:17400785, //ID for "Foreign Application Country 9"
    10:17400845, //ID for "Foreign Application Country 10"
};

const clioForeignAppMapping = {
    1:17400440, //ID for "Foreign App Serial Number 1"
    2:17400485, //ID for "Foreign App Serial Number 2"
    3:17400515, //ID for "Foreign App Serial Number 3"
    4:17400575, //ID for "Foreign App Serial Number 4"
    5:17400620, //ID for "Foreign App Serial Number 5"
    6:17400665, //ID for "Foreign App Serial Number 6"
    7:17400710, //ID for "Foreign App Serial Number 7"
    8:17400755, //ID for "Foreign App Serial Number 8"
    9:17400800, //ID for "Foreign App Serial Number 9"
    10:17400830, //ID for "Foreign App Serial Number 10"
};

const clioForeignAppFilingDateMapping = {
    1:17400455, //ID for "Foreign App Filing Date 1"
    2:17400500, //ID for "Foreign App Filing Date 2"
    3:17400545, //ID for "Foreign App Filing Date 3"
    4:17400590, //ID for "Foreign App Filing Date 4"
    5:17400635, //ID for "Foreign App Filing Date 5"
    6:17400680, //ID for "Foreign App Filing Date 6"
    7:17400725, //ID for "Foreign App Filing Date 7"
    8:17400770, //ID for "Foreign App Filing Date 8"
    9:17400815, //ID for "Foreign App Filing Date 9"
    10:17400860, //ID for "Foreign App Filing Date 10"
};

const clioRegFieldSetMapping = {
    1: 1006655, // "Foreign Registration 1"
    2: 1006670, // "Foreign Registration 2"
    3: 1006685, // "Foreign Registration 3"
    4: 1006700, // "Foreign Registration 4"
    5: 1006715, // "Foreign Registration 5"
    6: 1006730, // "Foreign Registration 6"
    7: 1006745, // "Foreign Registration 7"
    8: 1006760, // "Foreign Registration 8"
    9: 1006775, // "Foreign Registration 9"
    10:1006790, // "Foreign Registration 10"
};

const clioForeignRegCountryMapping = {
    1:17407700, //ID for "Foreign Reg Country 1"
    2:17407715, //ID for "Foreign Reg Country 2"
    3:17407730, //ID for "Foreign Reg Country 3"
    4:17407760, //ID for "Foreign Reg Country 4"
    5:17407775, //ID for "Foreign Reg Country 5"
    6:17407790, //ID for "Foreign Reg Country 6"
    7:17407820, //ID for "Foreign Reg Country 7"
    8:17407835, //ID for "Foreign Reg Country 8"
    9:17407850, //ID for "Foreign Reg Country 9"
    10:17407865, //ID for "Foreign Reg Country 10"
};

const clioForeignRegMapping = {
    1:17407880, //ID for "Foreign Reg Number 1"
    2:17407895, //ID for "Foreign Reg Number 2"
    3:17407910, //ID for "Foreign Reg Number 3"
    4:17407925, //ID for "Foreign Reg Number 4"
    5:17407940, //ID for "Foreign Reg Number 5"
    6:17407955, //ID for "Foreign Reg Number 6"
    7:17407970, //ID for "Foreign Reg Number 7"
    8:17407985, //ID for "Foreign Reg Number 8"
    9:17408000, //ID for "Foreign Reg Number 9"
    10:17408015, //ID for "Foreign Reg Number 10"
};

const clioForeignRegDateMapping = {
    1:17408030, //ID for "Foreign Reg Date 1"
    2:17408045, //ID for "Foreign Reg Date 2"
    3:17408060, //ID for "Foreign Reg Date 3"
    4:17408075, //ID for "Foreign Reg Date 4"
    5:17408090, //ID for "Foreign Reg Date 5"
    6:17408105, //ID for "Foreign Reg Date 6"
    7:17408120, //ID for "Foreign Reg Date 7"
    8:17408135, //ID for "Foreign Reg Date 8"
    9:17408150, //ID for "Foreign Reg Date 9"
    10:17408165, //ID for "Foreign Reg Date 10"
};

const clioForeignRegRenewedMapping = {
    1:17408180, //ID for "Foreign Reg Renewed Date 1"
    2:17408195, //ID for "Foreign Reg Renewed Date 2"
    3:17408210, //ID for "Foreign Reg Renewed Date 3"
    4:17408225, //ID for "Foreign Reg Renewed Date 4"
    5:17408240, //ID for "Foreign Reg Renewed Date 5"
    6:17408255, //ID for "Foreign Reg Renewed Date 6"
    7:17408270, //ID for "Foreign Reg Renewed Date 7"
    8:17408285, //ID for "Foreign Reg Renewed Date 8"
    9:17408300, //ID for "Foreign Reg Renewed Date 9"
    10:17408315, //ID for "Foreign Reg Renewed Date 10"
};

const clioForeignRegExpirationMapping = {
    1:17408330, //ID for "Foreign Reg Expiration Date 1"
    2:17408345, //ID for "Foreign Reg Expiration Date 2"
    3:17408360, //ID for "Foreign Reg Expiration Date 3"
    4:17408375, //ID for "Foreign Reg Expiration Date 4"
    5:17408390, //ID for "Foreign Reg Expiration Date 5"
    6:17408405, //ID for "Foreign Reg Expiration Date 6"
    7:17408420, //ID for "Foreign Reg Expiration Date 7"
    8:17408435, //ID for "Foreign Reg Expiration Date 8"
    9:17408450, //ID for "Foreign Reg Expiration Date 9"
    10:17408465, //ID for "Foreign Reg Expiration Date 10"
};

const RenewalUseInfo = ({classBlocks, formData, onUpdateClassBlocks, onUpdateSpecimens, onFormDataChange, setFormData, onDeleteSpecimens, onValidationChange, attemptedSubmit }) => {
    const [selectedUse, setSelectedUse] = useState({});
    const [localUrl, setLocalUrl] = useState("");
    const [dateValue, setDateValues] = useState({});
    const [filePreviews, setFilePreviews] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState({});
    const [showHelp, setShowHelp] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isFormValid, setIsFormValid] = useState('false');
    const [blockValidity, setBlockValidity] = useState({});

    useEffect(() => {
        // Validate form data whenever there's a change
        updateBlockValidity();        
    }, [classBlocks, selectedUse]);

    const updateBlockValidity = () => {
        let newBlockValidity = {};
        let allBlocksAreValid = true;
      
        for (const block of classBlocks) {
          const useType = selectedUse[block.id];
          let fieldsValidity = {};
      
          switch (useType) {
            case 'web':
              fieldsValidity = {
                dateFirstUse: !!block.dateFirstUse,
                dateFirstUseInCommerce: !!block.dateFirstUseInCommerce,
                url: !!block.url,
              };
              break;
            case 'use':
              fieldsValidity = {
                dateFirstUse: !!block.dateFirstUse,
                dateFirstUseInCommerce: !!block.dateFirstUseInCommerce,
                specimens: block.specimens && block.specimens.length > 0,
              };
              break;
            default:
              fieldsValidity = {
                isNotRequired: true,
              };
              break;
          }
      
          newBlockValidity[block.id] = fieldsValidity;

          // Check if all fields within this block are valid
          allBlocksAreValid = allBlocksAreValid && Object.values(fieldsValidity).every(Boolean);
        }
      
        setBlockValidity(newBlockValidity);
        setIsFormValid(allBlocksAreValid);
    };
      

    const handleUseSelectionChange = (blockId, value) => {
        setSelectedUse(prev => ({ ...prev, [blockId]: value }));
        
        if(value=== 'itu') {
            //Reset the file previews for this specific block
            const resetFilePreviews = filePreviews.filter(preview => preview.blockId !== blockId);
            setFilePreviews(resetFilePreviews);

            // Clear the specimens from the class block in the parent component's state
            const updatedBlock = {
                ...classBlocks.find(block => block.id === blockId),
                specimens: [], // Clear specimens
                dateFirstUse: '', // Clear date of first use
                dateFirstUseInCommerce: '', // Clear date of first use in commerce
                foreignCountry: '', // Clear foreign application country
                foreignAppNo: '', // Clear foreign application number
                foreignAppFilingDate: '', // Clear foreign application filing date
                foreignRegNo: '', // Clear foreign registration number
                foreignRegDate: '', // Clear foreign registration date
                foreignRegRenewedDate: '', // Clear foreign registration renewal date
                foreignExpirationDate: '', // Clear foreign expiration date
                foreignRegCertificate: [], // Clear foreign certificates
                url: '', // Clear URL
            };

            //Call onUpdateSpecimens to update the parent state
            onDeleteSpecimens(blockId, updatedBlock);
        } else if(value=== 'use') {
            //Reset the file previews for this specific block
            const resetFilePreviews = filePreviews.filter(preview => preview.blockId !== blockId);
            setFilePreviews(resetFilePreviews);

            // Clear the specimens from the class block in the parent component's state
            const updatedBlock = {
                ...classBlocks.find(block => block.id === blockId),
                foreignCountry: '', // Clear foreign application country
                foreignAppNo: '', // Clear foreign application number
                foreignAppFilingDate: '', // Clear foreign application filing date
                foreignRegNo: '', // Clear foreign registration number
                foreignRegDate: '', // Clear foreign registration date
                foreignRegRenewedDate: '', // Clear foreign registration renewal date
                foreignExpirationDate: '', // Clear foreign expiration date
                foreignRegCertificate: [], // Clear foreign certificates
                url: '', // Clear URL
            };

            //Call onUpdateSpecimens to update the parent state
            onDeleteSpecimens(blockId, updatedBlock);
        } else if(value=== 'web') {
            //Reset the file previews for this specific block
            const resetFilePreviews = filePreviews.filter(preview => preview.blockId !== blockId);
            setFilePreviews(resetFilePreviews);

            // Clear the specimens from the class block in the parent component's state
            const updatedBlock = {
                ...classBlocks.find(block => block.id === blockId),
                specimens: [], // Clear specimens
                foreignCountry: '', // Clear foreign application country
                foreignAppNo: '', // Clear foreign application number
                foreignAppFilingDate: '', // Clear foreign application filing date
                foreignRegNo: '', // Clear foreign registration number
                foreignRegDate: '', // Clear foreign registration date
                foreignRegRenewedDate: '', // Clear foreign registration renewal date
                foreignExpirationDate: '', // Clear foreign expiration date
                foreignRegCertificate: [], // Clear foreign certificates
            };

            //Call onUpdateSpecimens to update the parent state
            onDeleteSpecimens(blockId, updatedBlock);
        } else if(value=== 'foreignApp') {
            //Reset the file previews for this specific block
            const resetFilePreviews = filePreviews.filter(preview => preview.blockId !== blockId);
            setFilePreviews(resetFilePreviews);
            
            const blockIndex = classBlocks.findIndex(block => block.id === blockId);
            // Clear the specimens from the class block in the parent component's state
            const updatedBlock = {
                ...classBlocks.find(block => block.id === blockId),
                specimens: [], // Clear specimens
                dateFirstUse: '', // Clear date of first use
                dateFirstUseInCommerce: '', // Clear date of first use in commerce
                foreignCountry: '', // Clear foreign application country
                foreignRegNo: '', // Clear foreign registration number
                foreignRegDate: '', // Clear foreign registration date
                foreignRegRenewedDate: '', // Clear foreign registration renewal date
                foreignExpirationDate: '', // Clear foreign expiration date
                foreignRegCertificate: [], // Clear foreign certificates
                url: '', // Clear URL
            };

            //Call onUpdateSpecimens to update the parent state
            onDeleteSpecimens(blockId, updatedBlock);
        } else if(value=== 'foreignReg') {
            //Reset the file previews for this specific block
            const resetFilePreviews = filePreviews.filter(preview => preview.blockId !== blockId);
            setFilePreviews(resetFilePreviews);

            const blockIndex = classBlocks.findIndex(block => block.id === blockId);
            // Clear the specimens from the class block in the parent component's state
            const updatedBlock = {
                ...classBlocks.find(block => block.id === blockId),
                specimens: [], // Clear specimens
                dateFirstUse: '', // Clear date of first use
                dateFirstUseInCommerce: '', // Clear date of first use in commerce
                foreignCountry: '', // Clear foreign application country
                foreignAppNo: '', // Clear foreign application number
                foreignAppFilingDate: '', // Clear foreign application filing date
                url: '', // Clear URL
            };

            //Call onUpdateSpecimens to update the parent state
            onDeleteSpecimens(blockId, updatedBlock);
        }
        
        //Update classBlock
        updateClassBlock(blockId, value);
    };

    const updateClassBlock = (blockId, useSelection) => {
        // Logic to update classBlocks state in the parent component
        // This could involve adding a URL value or indicating that a file will be uploaded
    };

    // const handleLocalUrlChange = (e, blockId) => {
    //     const newValue = e.target.value;
    //     setLocalUrl(newValue); // Update local state to reflect input changes immediately
    
    //     // Update the specific class block within classBlocks array
    //     const updatedClassBlocks = classBlocks.map(block => {
    //         if (block.id === blockId) {
    //             return { ...block, url: newValue };
    //         }
    //         return block;
    //     });
    
    //     // Call the parent update function with the updated class blocks
    //     onUpdateClassBlocks(updatedClassBlocks);
    // };

    const handleLocalUrlChange = (e, blockId) => {
        const newValue = e.target.value;
        setLocalUrl(newValue);

        // Find the index of the class block
        const blockIndex = classBlocks.findIndex(block => block.id === blockId);

        // Use the block index to get the corresponding clioURLID
        const clioURLID = clioURLMapping[blockIndex + 1];

        const updateData = { url: newValue, clioURLID: clioURLID };
        onUpdateClassBlocks(blockId, updateData);
    };
    
    const handleUrlChange = (blockId, newValue) => {
        const updatedClassBlocks = classBlocks.map((block) => {
            if (block.id === blockId) {
                // Update the URL for the matched block
                return { ...block, url: newValue };
            }
            return block;
        });
    
        // Call the method to update the parent component's state
        onUpdateClassBlocks(updatedClassBlocks);
    };     
    
    const handleDateFirstUseChange = (e, blockId) => {
        const value = e.target.value;
        // Construct the updated block object with the new dateFirstUse value
        const updatedClassBlocks = { id: blockId, dateFirstUse: value };
    
        // Pass this updated block to updateClassBlockInfo
        onUpdateClassBlocks(updatedClassBlocks);
    };
    
    
    const handleDateFirstUseInCommerceChange = (e, blockId) => {
        const value = e.target.value;
        const updatedClassBlocks = { id: blockId, dateFirstUseInCommerce: value };


        onUpdateClassBlocks(updatedClassBlocks);
    };

    const handleSpecimenFileChange = (event, blockId) => {
        const files = Array.from(event.target.files);
        const updatedFilePreviews = [...filePreviews];
    
        const block = classBlocks.find(block => block.id === blockId);
        if (!block) {
            console.error("Block not found for blockId:", blockId);
            return;
        }
    
        files.forEach((file, index) => {
            const standardizedFileName = `${block.classNo} - Specimen ${block.specimens.length + index + 1}.${file.name.split('.').pop()}`;
            const newFile = new File([file], standardizedFileName, { type: file.type });
            const reader = new FileReader();
    
            reader.onloadend = () => {
                const fileInfo = {
                    id: newFile.name + '-' + Date.now(),
                    blockId: blockId,
                    name: standardizedFileName,
                    url: reader.result,
                    file: newFile,
                };
    
                updatedFilePreviews.push(fileInfo);
                setFilePreviews(updatedFilePreviews);
                
                // Update the specimens array within the class block
                onUpdateClassBlocks(blockId, { specimens: [fileInfo] });
            };
    
            reader.readAsDataURL(newFile);
        });
    };
    
    
    const removeFilePreview = (fileId) => {
        setFilePreviews(filePreviews.filter(preview => preview.id !== fileId));
    };
    
    const handleForeignCountryChange = (selectedOption, blockId, selectedUse) => {
        // Assuming selectedOption is the object with { value, label, isSeparator }
        const selectedValue = selectedOption ? selectedOption.value : '';
        setFormData(prevFormData => {

            const updatedClassBlocks = prevFormData.classBlocks.map(block => 
                block.id === blockId 
                ? { ...block, foreignCountry: selectedValue, /* clioForeignCountryID: clioFieldId */ }
                : block
            );

            // Return the updated formData, including the updated classBlocks array
            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };
    

    const handleInputChange = (event) => {
        // const { id, value } = event.target;
        // const [field, blockId] = id.split('-'); // Assuming id is `foreignAppNo-0`, `foreignAppNo-1`, etc.     
        const { id, value } = event.target;
        const [field, blockIdStr] = id.split('-'); // Splitting the ID to extract field and block ID.
        const blockId = parseInt(blockIdStr, 10); // Parsing blockId as integer.

        setFormData(prevFormData => ({
            ...prevFormData,
            classBlocks: prevFormData.classBlocks.map(block => 
                block.id === parseInt(blockId, 10) ? { ...block, [field]: value } : block
            )
        }));
    };

    const handleForeignAppFilingDateChange = (e, blockId) => {
        const value = e.target.value;
        // const blockIndex = classBlocks.findIndex(block => block.id === blockId);
        const updatedClassBlocks = { id: blockId, foreignAppFilingDate: value, /* clioForeignAppFilingDateID: clioForeignAppFilingDateMapping[blockIndex + 1] */ };
        onUpdateClassBlocks(updatedClassBlocks);
    };

    const handleForeignRegDateChange = (e, blockId) => {
        const value = e.target.value;
        // const blockIndex = classBlocks.findIndex(block => block.id === blockId);
        const updatedClassBlocks = { id: blockId, foreignRegDate: value, /* clioForeignRegDateID: clioForeignRegDateMapping[blockIndex + 1] */ };

        onUpdateClassBlocks(updatedClassBlocks);
    };

    const handleForeignRegRenewedDateChange = (e, blockId) => {
        const value = e.target.value;
        // const blockIndex = classBlocks.findIndex(block => block.id === blockId);
        const updatedClassBlocks = { id: blockId, foreignRegRenewedDate: value, };

        onUpdateClassBlocks(updatedClassBlocks);
    };

    const handleForeignExpirationDateChange = (e, blockId) => {
        const value = e.target.value;
        const updatedClassBlocks = { id: blockId, foreignExpirationDate: value };

        onUpdateClassBlocks(updatedClassBlocks);
    };
    

    const handleForeignAppChange = (e, blockId) => {
        const value = e.target.value;
        // Construct the updated block object with the new dateFirstUse value
        const updatedClassBlocks = { id: blockId, dateFirstUse: value };
    
        // Pass this updated block to updateClassBlockInfo
        onUpdateClassBlocks(updatedClassBlocks);
    };

    const handleForeignCertificateChange = (event, blockId) => {
        const files = Array.from(event.target.files); // Correctly converting FileList to Array
        const updatedFilePreviews = [...filePreviews]; // Copy existing previews
    
        // Process each selected file
        files.forEach((file) => {
            const reader = new FileReader();
    
            reader.onloadend = () => {
                // Prepare fileInfo object for each file
                const fileInfo = {
                    id: `${file.name}-${Date.now()}`,
                    blockId: blockId,
                    name: `Certificate ${updatedFilePreviews.length + 1}`,
                    url: reader.result, // This is the file preview URL
                    file: file, // Keep the file reference if needed for submission
                };
    
                // Add fileInfo to the previews array
                updatedFilePreviews.push(fileInfo);
    
                // Use setState to update the filePreviews state with the new array
                setFilePreviews(updatedFilePreviews);
    
                // Here, pass fileInfo to update the corresponding class block
                // Assuming onUpdateClassBlocks can handle an array of fileInfo objects or you need to adjust its logic
                onUpdateSpecimens(blockId, fileInfo); // Adjust based on how onUpdateClassBlocks is implemented
            };
    
            reader.readAsDataURL(file);
        });
    };

    const formatDescriptions = (descriptions) => {
        return descriptions.flatMap(desc => {
          // Initialize an array to hold the formatted strings
          let formattedArray = [];
          
          // Iterate over the core array and corresponding additional inputs together
          desc.core.forEach((coreItem, index) => {
            formattedArray.push(coreItem); // Add core description
            // Check if there's a corresponding additional input and add it
            if (desc.additionalInputs[index]) {
              formattedArray.push(desc.additionalInputs[index]);
            }
          });
      
          return formattedArray;
        }).join(' ');
    };
      
    const toggleHelpGuide = () => {
        setShowHelp(!showHelp);
    };

    const ImageEnlargeView = ({ src, onClose }) => {
        if (!src) return null;
      
        return (
          <div className="image-enlarge-modal" onClick={onClose}>
            <img src={src} alt="Enlarged view" style={{ maxWidth: '90%', maxHeight: '80vh' }} />
          </div>
        );
    };
      
    return (
        <div className="container-fluid">
          {(attemptedSubmit && !isFormValid) && (
            <div className="alert alert-danger">
              <strong>Please provide information about your mark before proceeding:</strong>
            </div>
          )}
          <div className="text-center mb-3">
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="button-tooltip-2">Click here for more details</Tooltip>}
                >
                    <Button variant="link" onClick={toggleHelpGuide}>CLICK HERE</Button>
                </OverlayTrigger>for details about acceptable proof of use
            </div>
          
          {classBlocks.map((block, index) => (
            <div key={block.id} className="card mb-3">
              <div className="card-body">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="use-group">
                      <label htmlFor={`classLabel-${block.id}`} className="use-label">International Class:</label>
                      <span id={`classLabel-${block.id}`} className="form-text">{block.classNo}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="use-group">
                      <label htmlFor={`intClassLabel-${block.id}`} className="use-label">Goods/Services: </label>
                      <span id={`intClassLabel-${block.id}`} className="form-text gs">{block.description}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="use-group">
                      <label htmlFor={`useLabel-${block.id}`} className="use-label">Mark Usage:</label>
                      <UseSelection
                        value={selectedUse[block.id]}
                        onChange={(value) => handleUseSelectionChange(block.id, value)}
                        className="form-control"
                        excludeOptions={['itu', 'foreignApp', 'foreignReg']}
                      />
                    </div>
                  </div>
                </div>
                {selectedUse[block.id] === 'web' && (
                    <div className='use-class-container'>
                        <div className='col-12 px-custom use-component'>
                            <label htmlFor={`url-${block.id}`} className='first-use-label'>URL:*</label>
                            <input 
                                type='text' 
                                id={`url-${block.id}`} 
                                className={`form-control urlInput ${attemptedSubmit && blockValidity[block.id]?.url === false ? 'invalid-input' : ''}`}
                                value={block.url || ''}
                                onChange={(e) => handleLocalUrlChange(e, block.id)}
                            />
                        </div> 
                    </div> 
                )}
                {selectedUse[block.id] === 'use' && (
                    <div className='use-class-container'>
                        <div className='col-12 px-custom specimen-input'>
                            <label htmlFor='specimenFileButton' className='use-label'>Attach your evidence of use:*</label>
                            <button
                                id='specimenFileButton'
                                onClick={() => document.getElementById(`specimenFile-${block.id}`).click()}
                                className={`fileSelectButton ${attemptedSubmit && !blockValidity[block.id]?.specimens ? 'invalid-button' : ''}`}
                            >Upload File</button>
                            <input
                                type="file"
                                id={`specimenFile-${block.id}`}
                                name='specimenFile'
                                style={{ display: 'none' }}
                                onChange={(e) => handleSpecimenFileChange(e, block.id)}
                            />
                        </div>
                        <div className='specimens'>
                            {filePreviews.map((preview, index) => (
                                preview.blockId === block.id && ( // Ensure we're displaying files for the correct block
                                    <div key={index} className='specimen-container'>
                                        <img src={preview.url} alt={preview.name} className='thumbnail' />
                                        <p>{preview.name}</p>
                                        <button
                                            onClick={() => removeFilePreview(preview.id)}
                                            className='remove-document'
                                        >Remove</button>
                                    </div>
                                )
                            ))}
                        </div>
                    </div> 
                )}
              </div>
            </div>
          ))}
          <HelpGuide 
            isOpen={showHelp} 
            onClose={toggleHelpGuide} 
            toggleHelpGuide={toggleHelpGuide}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
          <ImageEnlargeView src={selectedImage} onClose={() => setSelectedImage(null)} />
        </div>
      );
}

export default RenewalUseInfo;
