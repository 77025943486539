import React from "react";
import './App.css';
import Header from './header.js';
import Footer from "./footer.js";

const AboutPage = () => {
    return (
        <div>
        {/* <Header /> */}
        <main>
          <div className='comp'>
            <span className='comp-name'>About</span>
            <span className='comp-descrip'>A Forward-Thinking, Virtual Law Firm Dedicated to Trademarks</span>
          </div>
          <div className='about-section'>
            <p><b>San Novus Trademark LLP</b> is a virtual law firm that specializes in the intricacies of trademark law. Our specialization extends to 
              offering comprehensive representation in trademark applications and trademark renewals across all 50 states, as well as internationally.</p>
            <p>We provide a range of trademark-related services, from initial application filings to handling office actions and renewals. Our virtual 
              model is designed for modern convenience, offering our clients efficient and flexible legal services no matter where they are located.</p> 
            <p>Our client base spans virtually every industry. Whether you’re a startup or an established business, our goal is to help you safeguard 
              your brand’s identity, giving you peace of mind to focus on what you do best – running your business.</p>
            <p>We believe in complete cost transparency. Our pricing model is clear and straightforward, ensuring that you receive the best 
              representation with no hidden fees.</p>  
            <p>Contact <b>San Novus Trademark LLP</b> today for comprehensive trademark advice and services tailored to your unique needs. Let us handle the 
              complexities of trademark law, so you can concentrate on your business’s success.</p>
          </div>
        </main>
      </div>
    );
  };
  
  export default AboutPage;