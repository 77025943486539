import React, { useState, useContext, useEffect } from 'react';
import './App.css';
import Header from './header.js';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext.js';
import LogoutButton from './logoutButton.js';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext); // Correctly access the login function from AuthContext

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from being submitted traditionally
        try {
            await login(username, password); // Attempt to log in
            //navigate('/pricing'); // Navigate to the homepage on successful login
        } catch (error) {
            console.error('Login failed:', error.message);
        }
    };
    
    return (
        <div classNames="container mt-5"> {/* Bootstrap container with top margin */}
            <form onSubmit={handleSubmit}>
                <div className="row mb-3"> {/* Bootstrap row with bottom margin */}
                    <div className="col-12">
                        <label htmlFor="userNameInput" className="form-label">Username:</label>
                        <input 
                            type="text" 
                            id="userNameInput" 
                            className="form-control" // Bootstrap form control
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <label htmlFor="passwordInput" className="form-label">Password:</label>
                        <input 
                            type="password" 
                            id="passwordInput" 
                            className="form-control"
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <LogoutButton />
                        <button type="submit" className="btn btn-primary login-button">Log in</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
