import React, { useState } from 'react';
import Select from 'react-select';

const states = [
    {name: "Select State", abbreviation: ""},
    {name: "Alabama", abbreviation: "AL"},
    {name: "Alaska", abbreviation: "AK"},
    {name: "Arizona", abbreviation: "AZ"},
    {name: "Arkansas", abbreviation: "AR"},
    {name: "California", abbreviation: "CA"},
    {name: "Colorado", abbreviation: "CO"},
    {name: "Connecticut", abbreviation: "CT"},
    {name: "Delaware", abbreviation: "DE"},
    {name: "District of Columbia", abbreviation: "DC"},
    {name: "Florida", abbreviation: "FL"},
    {name: "Georgia", abbreviation: "GA"},
    {name: "Guam", abbreviation: "GU"},
    {name: "Hawaii", abbreviation: "HI"},
    {name: "Idaho", abbreviation: "ID"},
    {name: "Illinois", abbreviation: "IL"},
    {name: "Indiana", abbreviation: "IN"},
    {name: "Iowa", abbreviation: "IA"},
    {name: "Kansas", abbreviation: "KS"},
    {name: "Kentucky", abbreviation: "KY"},
    {name: "Louisiana", abbreviation: "LA"},
    {name: "Maine", abbreviation: "ME"},
    {name: "Maryland", abbreviation: "MD"},
    {name: "Massachusetts", abbreviation: "MA"},
    {name: "Michigan", abbreviation: "MI"},
    {name: "Minnesota", abbreviation: "MN"},
    {name: "Mississippi", abbreviation: "MS"},
    {name: "Missouri", abbreviation: "MO"},
    {name: "Montana", abbreviation: "MT"},
    {name: "Nebraska", abbreviation: "NE"},
    {name: "Nevada", abbreviation: "NV"},
    {name: "New Hampshire", abbreviation: "NH"},
    {name: "New Jersey", abbreviation: "NJ"},
    {name: "New Mexico", abbreviation: "NM"},
    {name: "New York", abbreviation: "NY"},
    {name: "North Carolina", abbreviation: "NC"},
    {name: "North Dakota", abbreviation: "ND"},
    {name: "Ohio", abbreviation: "OH"},
    {name: "Oklahoma", abbreviation: "OK"},
    {name: "Oregon", abbreviation: "OR"},
    {name: "Pennsylvania", abbreviation: "PN"},
    {name: "Puerto Rico", abbreviation: "PR"},
    {name: "Rhode Island", abbreviation: "RI"},
    {name: "South Carolina", abbreviation: "SC"},
    {name: "South Dakota", abbreviation: "SD"},
    {name: "Tennessee", abbreviation: "TN"},
    {name: "Texas", abbreviation: "TX"},
    {name: "Utah", abbreviation: "UT"},
    {name: "Vermont", abbreviation: "VT"},
    {name: "Virgina", abbreviation: "VA"},
    {name: "Washington", abbreviation: "WA"},
    {name: "West Virginia", abbreviation: "WV"},
    {name: "Wisconsin", abbreviation: "WI"},
    {name: "Wyoming", abbreviation: "WY"},
  ];

  const options = states.map(state => ({
    value: state.abbreviation,
    label: state.name,
  }));

  const StateCombobox = ({ id, value, onChange, isValid, attemptedSubmit }) => {

    const selectedOption = options.find(option => option.value === value)

    const handleChange = (selectedOption) => {
        onChange(selectedOption, id);
    };

    const customStyles = {
        control: (styles) => ({
          ...styles,
          height: '38px',
          minHeight: '38px',
          width: '14rem',
          borderColor: attemptedSubmit && !isValid ? 'red' : 'defaultBorderColor',
          borderWidth: attemptedSubmit && !isValid ? '1.5px' : '1px',
          backgroundColor: 'white',
          fontSize: '1.2rem',
        }),
        singleValue: (styles) => ({...styles,
        color: 'black',
        }),
        valueContainer: (styles) => ({
          ...styles,
          height: '30px',
          padding: '0 5px',
          outerWidth: '150px'

        }),
        input: (styles) => ({
          ...styles,
          margin: '0px',
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          height: '30px',
        }),
        option: (styles, { isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isFocused ? 'lightgray' : isSelected ? 'gray' : null,
            color: 'black',
          };
        },
        placeholder: (styles) => ({
          ...styles,
          color: 'black',
        }),
      };

    return (
        <Select
          styles={customStyles}    
          options={options}
          onChange={handleChange}
          value={selectedOption}
          placeholder="Select State"
          isSearchable={true}
          name='stateCB'
          id='stateCB'
        />
    );
};
  
  export default StateCombobox;