import React, { useState, useEffect } from 'react';

const AppRetrival = ({
    formData,
    searchTmApplication,
    searchResults,
    setFormData,
    searchTmDocs,
    // fetchImage,
    oaURL,
    drawingURL,
    searchError,
    validationError,
    }) => {

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [appSearchNo, setAppSearchNo] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [teasPlusEligible, setTeasPlusEligible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [ownerEmail, setOwnerEmail] = useState('');
    const [docInfo, setDocInfo] = useState('');
    const [error, setError] = useState('');    

    
    //Handle search initiation by pressing 'Enter' key
    const handleSearchKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default action to avoid submitting the form (if any)
            await triggerSearch();
        }
    };

    const triggerSearch = async () => {
        setLoading(true);
        let newFormData = {
            ...formData,
            serialNumber: '',
            filingDate: '',
            applicationType: '',
            markLiteralElement: '',
            markDescription: '',
            markColorClaim: '',
            ownerFirstName: '',
            ownerLastName: '',
            ownerCompany: '',
            ownerCompanyType: '',
            ownerAddress1: '',
            ownerAddress2: '',
            ownerCity: '',
            ownerState: '',
            ownerZip: '',
            ownerCountry: '',
            ownerCompanyCountry: '',
            ownerEmail: '',
            wordMark: '',
            logoURL: '',
            designMarkFile: '',
        };
        setFormData(newFormData);
        const searchNo = appSearchNo.replace(/\D/g,'');
        // let searchTerm = `sn?ids=${searchNo}`;
        // if(searchNo.length >= 8){
        //     searchTerm = `sn?ids=${searchNo}`;
        // } else {
        //     searchTerm = `rn?ids=${searchNo}`;
        // }
        await searchTmApplication(searchNo);
        // setLoading(false);
    }; 

    const getTmDocs = async () => {
        const data = await searchTmDocs(appSearchNo);
        setDocInfo(data);
        // const url = getDrawingDocumentUrlPath(data);
        // return(url);
    }

    //Function to convert 'yyyymmdd' formatted dates to 'MM/DD/YYYY' format
    function formatDate(dateStr) {
        return `${dateStr.substring(4, 6)}/${dateStr.substring(6, 8)}/${dateStr.substring(0, 4)}`;
    }

    const formatFilingDate = (dateStr) => {
        // Extracting the date part before the timezone offset
        const datePart = dateStr.substring(0,10); 
        const [year, month, day] = datePart.split('-');
        return `${month}/${day}/${year}`;
    };

    // Inside your component
    useEffect(() => {
        if (searchResults) {
            try {
                updateFormData();
            } catch (error) {
                console.error("Error parsing search results:", error);
            }
        }
    }, [searchResults]);

    const updateFormData = async () => {
        //Blue Acorn - 98336256
        //LaRosa - 97298409
        //Wood (design logo) - 87588756
        // image request: https://tsdrapi.uspto.gov/ts/cd/casedocs/bundle.xml?sn=87588756
        //Respofit - 98454585
        //Jupiter (liquid) - shows "summary of issues" - 97657135

        if (!searchResults || !searchResults.transactionList || searchResults.transactionList.length === 0) {
            console.error("No search results or unexpected structure!");
            return;
        }

        const trademark = searchResults.transactionList[0].trademarks[0];
        const tmStatus = trademark.status;
        const ownerGroupKey = Object.keys(trademark.parties.ownerGroups)[0];
        const owner = trademark.parties.ownerGroups[ownerGroupKey][0];

        let ownerFirstName;
        let ownerLastName;
        let ownerCompany;
        let url;
        let designLogoFile;

        await getTmDocs(appSearchNo);

        if(owner.entityType.description === 'INDIVIDUAL') {
            const ownerFullName = owner.name.split(' ');
            ownerFirstName = ownerFullName[0];
            ownerLastName = ownerFullName[ownerFullName.length -1];
        } else {
            ownerCompany = owner.name;
        }

        // Parse goods/services information safely
        const classBlocks = trademark.gsList.map((gs, index) => {
            let clioMappings = { clioID: null, clioClassID: null, clioDescriptionID: null, clioDateOfFirstUseID: null, clioDateOfFirstUseInCommerceID: null };
            // if (index > 0) {
            //     clioMappings = getClioMappingsForNewClassBlock(index);
            // }

            clioMappings = getClioMappingsForNewClassBlock(index);

            return {
                classNo: gs.primeClassCode,
                description: gs.description,
                dateFirstUse: gs.firstUseDate ? formatDate(gs.firstUseDate.toString()) : '',
                dateFirstUseInCommerce: gs.firstUseInCommerceDate ? formatDate(gs.firstUseInCommerceDate.toString()) : '',
                specimens: [],
                ...clioMappings,
            };
        });

        let newFormData = {
            ...formData,
            serialNumber: tmStatus.serialNumber.toString(),
            filingDate: formatFilingDate(tmStatus.filingDate),
            applicationType: tmStatus.standardChar ? 'word' : 'design',
            markLiteralElement: tmStatus.standardChar ? '': tmStatus.markElement,
            markDescription: tmStatus.standardChar ? '' : (tmStatus.descOfMark ? tmStatus.descOfMark.replace('The mark consists of ', '') : ''),
            markColorClaim: tmStatus.standardChar || tmStatus.colorClaimed === 'Color is not claimed a feature of the mark' ? 'None' : tmStatus.colorClaimed,
            ownerFirstName: ownerFirstName ? ownerFirstName : '',
            ownerLastName: ownerLastName ? ownerLastName : '',
            ownerCompany: ownerCompany ? ownerCompany : '',
            ownerCompanyType: owner.entityType.description ? owner.entityType.description : '',
            ownerAddress1: owner.address1,
            ownerAddress2: owner.address2 || '',
            ownerCity: owner.city,
            ownerState: owner.addressStateCountry.stateCountry.code || '',
            ownerZip: owner.zip,
            ownerCountry: owner.addressStateCountry.iso.name,
            ownerCompanyCountry: ownerCompany ? owner.citizenship.iso.name : '',
            ownerEmail: '',
            wordMark: tmStatus.standardChar ? tmStatus.markElement : '',
            logoURL: drawingURL ? drawingURL : '',
            designMarkFile: designLogoFile ? designLogoFile : '',
            classBlocks,
        };
        setFormData(newFormData);
        setLoading(false);
    };

    function getDrawingDocumentUrlPath(xmlString) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");
        
        // Find all <Document> elements
        const documents = xmlDoc.getElementsByTagName("Document");

        let drawingUrl = null;
        let mostRecentOfficeActionUrl = null;
        let mostRecentOfficeActionDate = null;
        
        // Loop through <Document> elements to find the one with <DocumentTypeCode>DRW</DocumentTypeCode>
        for (let i = 0; i < documents.length; i++) {
            const documentTypeCode = documents[i].getElementsByTagName("DocumentTypeCode")[0].textContent;
            const mailRoomDate = documents[i].getElementsByTagName("MailRoomDate")[0].textContent;
            const urlPath = documents[i].getElementsByTagName("UrlPath")[0].textContent;

            if (documentTypeCode === "DRW") { // Check if the document type is "DRW"
                // Extract the <UrlPath> from the first "Drawing" document found
                const drawingUrl = urlPath;
                // return urlPath; // Return the found URL path
            }

            // Check for office action and determine the most recent one
            if (documentTypeCode === "OOA" || documentTypeCode === "NFIN") {
                if (!mostRecentOfficeActionDate || mailRoomDate > mostRecentOfficeActionDate) {
                    mostRecentOfficeActionDate = mailRoomDate;
                    mostRecentOfficeActionUrl = urlPath;
                }
            }
        }
        
        // return null; // Return null if no "Drawing" document found
        if(drawingUrl && mostRecentOfficeActionUrl) {
            return {
                drawingUrl,
                mostRecentOfficeActionUrl,
            };
        } else {
            return null;
        }
    }

    const getClioMappingsForNewClassBlock = (index) => {
        const clioIdMapping = {
          1: 1008725, // "Goods and Services 1"
          2: 964309, // "Goods and Services 2"
          3: 964324, // "Goods and Services 3"
          4: 964339, // "Goods and Services 4"
          5: 964354, // "Goods and Services 5"
          6: 964369, // "Goods and Services 6"
          7: 964384, // "Goods and Services 7"
          8: 964399, // "Goods and Services 8"
          9: 964414, // "Goods and Services 9"
          10: 964429, // "Goods and Services 10"
        };
    
        const clioClassIdMapping = {
          1:16752904, //ID for "Class 1"
          2:16752934, //ID for "Class 2"
          3:16752949, //ID for "Class 3"
          4:16752964, //ID for "class 4"
          5:16752979, //ID for "class 5"
          6:16752994, //ID for "class 6"
          7:16753009, //ID for "class 7"
          8:16753024, //ID for "class 8"
          9:16753039, //ID for "class 9"
          10:16753054, //ID for "class 10"
        };
    
        const clioDescriptionMapping = {
          1:16590184, //ID for "Description 1"
          2:16590469, //ID for "Description 2"
          3:16590484, //ID for "Description 3"
          4:16590499, //ID for "Description 4"
          5:16590514, //ID for "Description 5"
          6:16590529, //ID for "Description 6"
          7:16590544, //ID for "Description 7"
          8:16590559, //ID for "Description 8"
          9:16590574, //ID for "Description 9"
          10:16590589, //ID for "Description 10"
        };
    
        const clioDateofFirstUseMapping = {
          1:16628284, //ID for "Date of First Use 1"
          2:16628314, //ID for "Date of First Use 2"
          3:16628344, //ID for "Date of First Use 3"
          4:16628374, //ID for "Date of First Use 4"
          5:16628404, //ID for "Date of First Use 5"
          6:16628434, //ID for "Date of First Use 6"
          7:16628464, //ID for "Date of First Use 7"
          8:16628494, //ID for "Date of First Use 8"
          9:16628524, //ID for "Date of First Use 9"
          10:16628554, //ID for "Date of First Use 10"
        };
    
        const clioDateofFirstUseInCommerceMapping = {
          1:16628299, //ID for "Date of First Use in Commerce 1"
          2:16628329, //ID for "Date of First Use in Commerce 2"
          3:16628359, //ID for "Date of First Use in Commerce 3"
          4:16628389, //ID for "Date of First Use in Commerce 4"
          5:16628419, //ID for "Date of First Use in Commerce 5"
          6:16628449, //ID for "Date of First Use in Commerce 6"
          7:16628479, //ID for "Date of First Use in Commerce 7"
          8:16628509, //ID for "Date of First Use in Commerce 8"
          9:16628539, //ID for "Date of First Use in Commerce 9"
          10:16628569, //ID for "Date of First Use in Commerce 10"
        };
    
        return {
          clioID: clioIdMapping[index + 1] || 1008725,
          clioClassID: clioClassIdMapping[index + 1] || 16752904,
          clioDescriptionID: clioDescriptionMapping[index + 1] || 16590184,
          clioDateOfFirstUseID: clioDateofFirstUseMapping[index + 1] || 16628284,
          clioDateOfFirstUseInCommerceID: clioDateofFirstUseInCommerceMapping[index + 1] || 16628299,
        };
    };

    const handleOwnerEmailUpdate = (email) => {
        setOwnerEmail(email);
        setFormData(prevFormData => ({
            ...prevFormData,
            ownerEmail: email,
        }));
    }
    
    return (
        <div className="container">
            <div className="mb-3">
                <div className="input-group">
                    <input
                        id="appSearchNo"
                        type="text"
                        placeholder="serial or registration number..."
                        value={appSearchNo}
                        onChange={(e) => setAppSearchNo(e.target.value)}
                        className="form-control tm-search-box"
                        onKeyDown={handleSearchKeyPress}
                    />
                    <button 
                        onClick={triggerSearch}
                        className="btn btn-primary search-button"
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="trademark-application">
                {(loading && !searchError) && <div>Retrieving trademark...</div>}
                {searchError && <div className="alert alert-danger">Error retrieving trademark number {appSearchNo}. Please verify the application/registration number and try again.</div>}
                {(!loading && formData.serialNumber) && <div>
                    <div className="mb-3 oaOwnerEmail-group">
                        <label htmlFor="ownerEmailInput" className="form-label">Trademark Owner Email:</label>
                        <input
                            id="ownerEmailInput"
                            type="text"
                            defaultValue={formData.ownerEmail || ''}
                            className="form-control"
                            onBlur={(e) => handleOwnerEmailUpdate(e.target.value)}
                        />
                    </div>
                    <div className='trademark-review-block'>
                        <div className="col">
                            <div className="col-12 col-md-6 mb-3 trademark-info-block">
                                <h2>Owner Information:</h2>
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Owner:</span>
                                    <span className='trademark-info-value'>{formData.ownerFirstName} {formData.ownerLastName || formData.ownerCompany}</span>
                                </div>
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Entity Type:</span>
                                    <span className='trademark-info-value'>{formData.ownerCompanyType || "Individual"}</span>
                                </div>
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Address:</span>
                                    <span className='trademark-info-value'>
                                        {formData.ownerAddress1}<br />
                                        {formData.ownerAddress2 && <>, {formData.ownerAddress2}<br /></>}
                                        {formData.ownerCity}, {formData.ownerState || ''} {formData.ownerZip}
                                    </span>
                                </div>
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Country:</span>
                                    <span className='trademark-info-value'>{formData.ownerCountry}</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-3 trademark-info-block">
                                <h2>Mark Information:</h2>
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Mark:</span>
                                    <span className='trademark-info-value'>
                                        <img src={drawingURL} alt="Trademark Logo" className="img-fluid trademark-logo" onError={(e) => { e.target.onerror = null; e.target.src = ''; }} />
                                    </span>
                                </div>
                                {formData.markDescription && (
                                    <div className='trademark-info-comp'>
                                        <span className='trademark-info-label'>Description:</span>
                                        <span className='trademark-info-value'>{formData.markDescription}</span>
                                    </div>
                                )}
                                {formData.markLiteralElement && (
                                    <div className='trademark-info-comp'>
                                        <span className='trademark-info-label'>Literal Element:</span>
                                        <span className='trademark-info-value'>{formData.markLiteralElement}</span>
                                    </div>
                                )}
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Serial Number:</span>
                                    <span className='trademark-info-value'>{formData.serialNumber}</span>
                                </div>
                                <div className='trademark-info-comp'>
                                    <span className='trademark-info-label'>Filing Date:</span>
                                    <span className='trademark-info-value'>{formData.filingDate}</span>
                                </div>
                            </div>
                            <div className="col-12 trademark-info-block">
                                <h2>Goods & Services:</h2>
                                {formData.classBlocks && formData.classBlocks.map((block, index) => (
                                    <div key={index}>
                                        <div className="mb-3 trademark-info-comp">
                                            <span className='trademark-info-label'>Class Number:</span>
                                            <span className='trademark-info-value'>{block.classNo}</span>
                                        </div>
                                        <div className='mb-3 trademark-info-comp'>
                                            <span className='trademark-info-label'>Description:</span>
                                            <span className='trademark-info-value'>{block.description}</span>
                                        </div>
                                        <div className='mb-3 trademark-info-comp'>
                                            <span className='trademark-info-label'>Date of First Use:</span>
                                            <span className='trademark-info-value'>{block.dateFirstUse}</span>
                                        </div>
                                        <div className='mb-3 trademark-info-comp'>
                                            <span className='trademark-info-label'>Date of First Use in Commerce:</span>
                                            <span className='trademark-info-value'>{block.dateFirstUseInCommerce}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default AppRetrival;