import React, { useState, useEffect } from 'react';
import ClassBlock from './classBlock.js';
import SearchModal from './searchModal.js';
import { Modal, Button, Form, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

const MarkClasses = ({
    classBlocks,
    setClassBlocks,
    onAddClassBlock,
    onRemoveClassBlock,
    onClassChange,
    onUpdateClassBlocks,
    handleAddClassDescription,
    formData,
    handleClassBlockChange,
    searchResults,
    performSearch,
    selectedDescriptions,
    handleInputChange,
    onSaveDescriptionInput,
    onUpdateDescription,
    onRemoveDescription,
    updateTeasType,
    onValidationChange,
    attemptedSubmit,
    }) => {

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [teasPlusEligible, setTeasPlusEligible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [isFormValid, setIsFormValid] = useState('false');

    useEffect(() => {
        // Validate form data whenever there's a change
        const isValid = validateForm();
        setIsFormValid(isValid);
        onValidationChange(isValid);
    }, [formData]);

    const validateForm = () => {
        let isValid = false;

        //Check for at least one class
        isValid = formData.classBlocks.length > 0; 
        onValidationChange(isValid);
        return isValid;
    };


    // Function to extract existing descriptions from classBlocks
    const extractDescriptions = (classBlocks) => {
        let descriptionsList = [];
        classBlocks.forEach(block => {
            // Assuming each block has a descriptions property that is an array of description objects
            block.descriptions.forEach(desc => {
                descriptionsList.push({ class_id: block.id, description_tx: desc.core }); // Adjust based on your actual description structure
            });
        });
        return descriptionsList;
    };

    // Initialize selectedDescriptionsList state with existing descriptions
    const [selectedDescriptionsList, setSelectedDescriptionsList] = useState(extractDescriptions(formData.classBlocks));

    useEffect(() => {
        // Update selectedDescriptionsList if formData.classBlocks changes
        setSelectedDescriptionsList(extractDescriptions(formData.classBlocks));
    }, [formData.classBlocks]);
    
    const handleAddClassDescriptionAndSetSearched = (class_id, description_tx) => {
        const parsedDescription = parseDescriptionForPlaceholders(description_tx);
        handleAddClassDescription(class_id, parsedDescription);
        setHasSearched(true); // Update the state when a class description is added
        setSelectedDescriptionsList(prevList => [...prevList, { class_id, description_tx }]);
    };

    const toggleSearchModal = () => {
        setShowSearchModal(!showSearchModal);
        console.log('showSearchModal:', !showSearchModal);
    };

    const handleSearchKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default action to avoid submitting the form (if any)
            await triggerSearch();
        }
    };

    const triggerSearch = async () => {
        console.log('Triggering search for:', searchTerm);
        setLoading(true);
        await performSearch(searchTerm);
        setLoading(false);
        toggleSearchModal();
    };   

    const parseDescriptionForPlaceholders = (description) => {
        const regex = /{([^}]+)}/g;
        let parsedDescription = description;
        let match;
        while ((match = regex.exec(description)) !== null) {
            parsedDescription = parsedDescription.replace(match[0], '');
        }
        return parsedDescription;
    }

    const updateTeasPlusEligibility = (isEligible) => {
        if (!isEligible) {
            setTeasPlusEligible(false);
        } else {
            setTeasPlusEligible(true);
        }

        updateTeasType(isEligible);
    };
    
    const calculateFilingFee = () => {
        const feePerClassBlock = teasPlusEligible ? 250 : 350;
        return formData.classBlocks.length * feePerClassBlock;
    };

    // const HelpGuide = ({ isOpen }) => {
    //     if (!isOpen) return null;
      
    //     return (
    //       <div className='goodsService-help-modal'>
    //         <div className='goodsService-help-content'>
    //             <h2>Describing Your Goods/Services</h2>
    //             <p>Let’s find the perfect description for what you’re selling. Our search tool taps directly into the U.S. Trademark ID Manual, 
    //                 delivering results that align with your search terms.</p>
    //             <p>Begin by typing in keywords related to your offerings. Imagine Ford entering “automobiles,” McDonald’s typing “restaurant services,” or 
    //                 Nike looking up “athletic shoes.” You get the idea!</p>
    //             <p>As you see results roll in, hit “Add” next to any description that fits. They’ll be neatly tucked into our intake form. And don’t worry
    //                  — you can edit or remove any descriptions later on. Plus, you can keep hunting for more without starting over; 
    //                  we’ll keep your selections in order.</p>
    //             <p>Can’t spot the perfect match? No problem! You can tweak the descriptions once you've finished searching. 
    //                 Just a quick heads up: customized descriptions may mean a higher filing fee later.</p>
    //             <p>Finished with your search? Close out the tool, do a final review of your choices, and pat yourself on the back. 
    //                 You’re now closer to securing your federal trademark. Go you!</p>
    //             <div className='warning-modal-buttons'>
    //                 <button onClick={toggleHelpGuide} className='goodsService-help-button'>Close</button>
    //             </div>
    //         </div>
    //       </div>
    //     );
    // };

    const HelpGuide = ({ isOpen }) => {
        if (!isOpen) return null;
        return (
            <Modal show={isOpen} onHide={toggleHelpGuide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Describing Your Goods/Services</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Let’s find the perfect description for what you’re selling. Our search tool taps directly into the U.S. Trademark ID Manual, 
                        delivering results that align with your search terms.</p>
                    <p>Begin by typing in keywords related to your offerings. Imagine Ford entering “automobiles,” McDonald’s typing “restaurant services,” or 
                        Nike looking up “athletic shoes.” You get the idea!</p>
                    <p>As you see results roll in, hit “Add” next to any description that fits. They’ll be neatly tucked into our intake form. And don’t worry
                        — you can edit or remove any descriptions later on. Plus, you can keep hunting for more without starting over; 
                        we’ll keep your selections in order.</p>
                    <p>Can’t spot the perfect match? No problem! You can tweak the descriptions once you've finished searching. 
                        Just a quick heads up: customized descriptions may mean a higher filing fee later.</p>
                    <p>Finished with your search? Close out the tool, do a final review of your choices, and pat yourself on the back. 
                        You’re now closer to securing your federal trademark. Go you!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleHelpGuide} style={{backgroundColor:'rgb(13, 110, 253)'}}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const toggleHelpGuide = () => {
        setShowHelp(!showHelp);
    };
      

    // return (
    //     <div>
    //         {(attemptedSubmit && !isFormValid) && (
    //             <div className='form-invalid-descrip'>
    //                 <span>Please submit at least one trademark class before proceeding</span>
    //             </div>
    //         )}
    //         <div className='goodsServices-help'>
    //             <p>Click <span onClick={toggleHelpGuide} className='goodsService-help-span'>HERE</span> for a more detailed explanation of this form</p>
    //         </div>
    //         <label htmlFor='searchHelp'>Search Term:</label>
    //         <input
    //             id='searchHelp'
    //             type='text'
    //             placeholder='Enter search term'
    //             value={searchTerm}
    //             onChange={(e) => setSearchTerm(e.target.value)}
    //             style={{ marginBottom: '20px' }}
    //             // className='search-box'
    //             className={`search-box ${attemptedSubmit && !isFormValid ? 'invalid-input' : ''}`} 
    //             onKeyDown={handleSearchKeyPress}
    //         />
    //         <button 
    //             onClick={() => {
    //                 // performSearch(searchTerm);
    //                 triggerSearch();
    //                 // toggleSearchModal();
    //             }}
    //             style={{ marginBottom: '20px' }}
    //             className='search-button'
    //         >Search</button>
    //         {/* {hasSearched && ( */}
    //             <div className='class-blocks-container'>
    //             {formData.classBlocks.map((block) => (
    //                 <ClassBlock
    //                     // key={block.id}
    //                     key={`${block.id}-${block._version}`}
    //                     block={block}
    //                     classValue={block}
    //                     classBlocks={formData.classBlocks}
    //                     version={formData.version}
    //                     descriptions={block.descriptions}
                        // onClassChange={(field, value) => handleClassBlockChange(block.id, field, value)}
                        // onRemove={() => onRemoveClassBlock(block.id)}
                        // // canRemove={formData.classBlocks.length > 1}
                        // selectedDescriptions={selectedDescriptionsList.filter(desc => desc.class_id === block.classNo)}
                        // additionalInputs={block.additionalInputs}
                        // onUpdateDescription={onUpdateDescription}
                        // formData={formData}
                        // onRemoveDescription={onRemoveDescription}
                        // updateTeasPlusEligibility={updateTeasPlusEligibility}
    //                 />
    //             ))}
    //         </div>
    //         {/* )} */}
    //         <div className='main-section'>
    //             <h3>Government Filing Fee: ${calculateFilingFee()}</h3>
    //         </div>
    //         {showSearchModal && (
    //             <SearchModal
    //                 showModal={showSearchModal}
    //                 searchTerm={searchTerm}
    //                 setShowModal={setShowSearchModal}
    //                 onAddClassDescription={handleAddClassDescriptionAndSetSearched}
    //                 searchResults={searchResults}
    //                 performSearch={performSearch}
    //                 onSaveDescriptionInput={onSaveDescriptionInput}
    //                 loading={loading}
    //             />
    //         )}
    //         <HelpGuide isOpen={showHelp} onClose={toggleHelpGuide} />
    //     </div>
    // );
    return (
        <div className="container sub-modal">
            {attemptedSubmit && !isFormValid && (
                <Alert variant="danger">Please submit at least one trademark class before proceeding.</Alert>
            )}
            <div className="text-center mb-3">
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="button-tooltip-2">Click here for more details</Tooltip>}
                >
                    <Button variant="link" onClick={toggleHelpGuide}>CLICK HERE</Button>
                </OverlayTrigger> for a more detailed explanation of this form.
            </div>
            <Form /* className='col-md-4 search-box' */>
                <Form.Group className="mb-3" controlId="searchHelp">
                    <Form.Label>Search Term:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter search term"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleSearchKeyPress}
                        isInvalid={attemptedSubmit && !isFormValid}
                        // style={{width:'100%'}}
                        // className='search-box-input'
                    />
                </Form.Group>
                <Button variant="primary" onClick={triggerSearch} disabled={loading}>
                    {loading ? 'Loading...' : 'Search'}
                </Button>
            </Form>
            <div className="class-blocks-container">
                {formData.classBlocks.map((block, index) => (
                    <ClassBlock
                        key={`${block.id}-${block._version}`}
                        block={block}
                        classValue={block}
                        descriptions={block.descriptions}
                        onClassChange={(field, value) => handleClassBlockChange(block.id, field, value)}
                        onRemove={() => onRemoveClassBlock(block.id)}
                        selectedDescriptions={selectedDescriptionsList.filter(desc => desc.class_id === block.classNo)}
                        additionalInputs={block.additionalInputs}
                        onUpdateDescription={onUpdateDescription}
                        formData={formData}
                        onRemoveDescription={onRemoveDescription}
                        updateTeasPlusEligibility={updateTeasPlusEligibility}
                    />
                ))}
            </div>
            <h3 className="text-center">Government Filing Fee: ${calculateFilingFee()}</h3>
            {showSearchModal && (
                <SearchModal
                    showModal={showSearchModal}
                    setShowModal={setShowSearchModal}
                    searchTerm={searchTerm}
                    searchResults={searchResults}
                    onAddClassDescription={handleAddClassDescription}
                    performSearch={performSearch}
                    onSaveDescriptionInput={onSaveDescriptionInput}
                    loading={loading}
                />
            )}
            <HelpGuide isOpen={showHelp} />
        </div>
    );
}

export default MarkClasses;
