//contactUs.js
import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure this path is correct for your CSS styles
import Contact from './Contact2.png'; // Ensure the image path is correct
import { escape } from 'lodash';
import Header from './header.js';
import Submission from './submission.png';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        contactFirstName: '',
        contactLastName: '',
        contactMiddleName: '',
        contactCompany: '',
        contactEmail: '',
        contactMessage: '',
        contactRefNo: '',
    });

    const [fieldValidity, setFieldValidity] = useState({
        contactFirstName: true,
        contactLastName: true,
        contactCompany: true,
        contactEmail: true,
        contactMessage: true,
    });

    const [formStatus, setFormStatus] = useState({
        isSubmitting: false,
        isSubmitted: false,
        errorMessage: '',
    });

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const [remainingChars, setRemainingChars] = useState(1000);


    useEffect(() => {
        const isValid = validateForm();
        setFieldValidity(isValid);
    }, [formData]);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [id]: sanitizeInput(value, id === 'contactEmail' ? 'email' : 'text')
        }));
        if (id === 'contactMessage') {
            setRemainingChars(1000 - value.length);
        }
    };

    const sanitizeInput = (value, type) => {
        // return type === 'email' ? value : escape(value);
        return type === 'email' ? value : value;
    };

    const validateForm = () => {
        const isValidFirstNameOrCompany = formData.contactFirstName || formData.contactCompany;
        const isValidLastNameOrCompany = formData.contactLastName || formData.contactCompany;
        const isValidEmail = emailRegex.test(formData.contactEmail);
        const isValidContactMessage = formData.contactMessage.trim().length > 0;

        return {
            contactFirstName: isValidFirstNameOrCompany,
            contactLastName: isValidLastNameOrCompany,
            contactCompany: formData.contactCompany || (formData.contactFirstName && formData.contactLastName),
            contactEmail: isValidEmail,
            contactMessage: isValidContactMessage,
        };
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setAttemptedSubmit(true);
        
        const allValid = Object.values(fieldValidity).every(Boolean);

        if (allValid) {
            sendEmail();
        }
    };

    const sendEmail = async () => {
        setFormStatus({ ...formStatus, isSubmitting: true });
        const emailSubject = 'New Inquiry Received'; 
        const msgType = 'inquiry';

        const emailHtml = createInquiryEmailHtml(formData); // Generate HTML on the front-end
        console.log(emailHtml);
        
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ emailHtml, emailSubject, msgType })
            });
            const result = await response.json();
            if (result.success) {
                setFormData({
                    contactFirstName: '',
                    contactLastName: '',
                    contactMiddleName: '',
                    contactCompany: '',
                    contactEmail: '',
                    contactMessage: '',
                    contactRefNo: '',
                });
                setFormStatus({ isSubmitting: false, isSubmitted: true, errorMessage: '' });
                setAttemptedSubmit(false);
            } else {
                console.error('Failed to send email');
                setFormStatus({ isSubmitting: false, isSubmitted: false, errorMessage: 'Failed to send the inquiry. Please try again.' });
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setFormStatus({ isSubmitting: false, isSubmitted: false, errorMessage: 'Failed to send the inquiry. Please try again.' });
        }
    };

    //Function to create email body for notification about new website submission
    const createInquiryEmailHtml = (formData) => {
        
        return `
        <html>
        <head>
            <style>
            body { font-family: Times-New-Roman, serif; }
            h2 { color: #333; }
            p { margin: 0; }
            </style>
        </head>
        <body>
            <h2><u>Contact Information:</u></h2>
            <p>First Name: ${formData.contactFirstName}</p>
            <p>Middle Name: ${formData.contactMiddleName}</p>
            <p>Last Name: ${formData.contactLastName}</p>
            <p>Company Name: ${formData.contactCompany}</p>
            <p>Email: ${formData.contactEmail}</p>
            <p>Alt Reference: ${formData.contactRefNo || ''}</p>

            <br>

            <h2><u>Inquiry:</u></h2>
            <p>${formData.contactMessage}</p>
            
            <br>
    
        </body>
        </html>
        `;
    };

    useEffect(() => {
        console.log('Updated formData:', formData);
      }, [formData]);

    return (
        <div className='container mt-5'>
            <form onSubmit={handleSubmit} className='contactUs-Container'>
                {attemptedSubmit && !Object.values(fieldValidity).every(Boolean) && (
                    <div className='alert alert-danger'>
                        <span>Please complete the required fields before proceeding:</span>
                    </div>
                )}
                {formStatus.isSubmitting && 
                    <p className='alert alert-info'>Sending your inquiry...</p>
                }
                {formStatus.isSubmitted && 
                    <p className='alert alert-success'>Thank you for your inquiry! We'll be in touch as quickly as possible.</p>
                }
                {formStatus.errorMessage && 
                    <p className="alert alert-danger">{formStatus.errorMessage}</p>
                }
                {!formStatus.isSubmitting && !formStatus.isSubmitted && !formStatus.errorMessage && (
                    <div>
                        <div className='row g-2'>
                            <div className='col-md-4 mb-2'>
                                <input 
                                    type='text'
                                    id='contactFirstName'
                                    className={`form-control ${attemptedSubmit && !fieldValidity.contactFirstName ? 'is-invalid' : ''}`}
                                    placeholder='First Name'
                                    value={formData.contactFirstName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-md-4 mb-2'>
                                <input 
                                    type='text'
                                    id='contactMiddleName'
                                    className='form-control'
                                    placeholder='Middle Name'
                                    value={formData.contactMiddleName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-md-4 mb-2'>
                                <input 
                                    type='text'
                                    id='contactLastName'
                                    className={`form-control ${attemptedSubmit && !fieldValidity.contactLastName ? 'is-invalid' : ''}`}
                                    placeholder='Last Name'
                                    value={formData.contactLastName}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='row g-2'>
                            <div className='col-md-6 mb-2'>
                                <input 
                                    type='text'
                                    id='contactCompany'
                                    className={`form-control ${attemptedSubmit && !fieldValidity.contactCompany ? 'is-invalid' : ''}`}
                                    placeholder='Company Name'
                                    value={formData.contactCompany}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-md-6 mb-2'>
                                <input 
                                    type='email'
                                    id='contactEmail'
                                    className={`form-control ${attemptedSubmit && !fieldValidity.contactEmail ? 'is-invalid' : ''}`}
                                    placeholder='Email Address'
                                    value={formData.contactEmail}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <textarea
                                id='contactMessage'
                                className={`form-control contact-us-message ${attemptedSubmit && !fieldValidity.contactMessage ? 'is-invalid' : ''}`}
                                placeholder='Your message here...'
                                value={formData.contactMessage}
                                onChange={handleInputChange}
                                rows='6'
                                maxLength={1000}
                            />
                            <span className='remaining-characters'>Characters remaining: {remainingChars}/1000</span>
                        </div>
                        <div className='text-center'>
                            <button type='submit' className='btn btn-primary form-buttons'>Submit</button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default ContactUs;

