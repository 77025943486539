import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";
import RegisterTrademarkForm from "./registerTrademarkForm.js";
import RenewTm from "./renewTm.js";
import Footer from "./footer.js";

const TrademarkRenewalsPage = () => {
  const [showForm, setShowForm] =   useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if(e.target.className === 'modal') {
      setShowForm(false);
    }
  };          
  return (
      <div className='trademark-renewals' style={{ fontFamily: 'Arial, sans-serif'}}>
          {/* <Header /> */}
          <div className='comp'>
            <span className='comp-name'>Trademark Registration Renewals</span>
            <span className='comp-descrip'>Ensuring the Longevity of Your Trademark</span>
          </div>
          <button className='form-button' onClick={toggleForm}>File a Trademark Renewal</button>
        <main>
          <div className='main-section'>
            <h3>Did you know U.S. trademark registrations can be maintained indefinitely with continuous commercial use?</h3>
            <p>A classic example is Coca-Cola<span style={{fontSize:'50%', verticalAlign:'super'}}>&reg;</span>, federally registered since 1893 and it is still in force!</p>
          </div>
          <div className='main-section-alt'>
            <h3>When does my trademark need to be renewed?</h3>
            <ul>
              <li><b>Initial Renewal:</b> Between the fifth and sixth year from the registration date.</li>
              <li><b>Subsequent Renewal:</b> Between the ninth and tenth year from the registration
              date, then every 10 years after that.</li>
            </ul>
          </div>
          <div className='main-section'>
            <h3>Easy Renewal with San Novus Trademark</h3>
              <p>Just fill out our brief questionnaire, upload at least one photo showing evidence of use, 
              and we’ll take care of the rest, ensuring that your trademark remains active.</p>
          </div>
          <div className='main-section-alt'>
            <h3>Beware of Predatory Notices</h3>
            <p>Have you received an official-looking notification about renewing your trademark? Be cautious of misleading 
              third-parties like “Patent and Trademark Bureau” or “Patent and Trademark Office,” which are not affiliated with the U.S. Patent and 
              Trademark Office. These companies often solicit renewal services prematurely and at inflated prices. For more details, visit the USPTO’s 
              warning page at: <a href='https://www.uspto.gov/trademarks/protect/caution-misleading-notices' target="_blank" rel="noopener noreferrer">
                https://www.uspto.gov/trademarks/protect/caution-misleading-notices</a>.</p>
          </div> 
          <div style={{marginTop:'2rem'}}>
            <button className='form-button' onClick={toggleForm}>File a Trademark Renewal</button>   
          </div>      
           {showForm && (
              <div onClick={closeModal}>
                <RenewTm toggleForm={toggleForm}/>
              </div>
            )}
            <div className='main-section'>
              <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
              <FAQs tags="renewal" />
            </div>
        </main>
      </div>
    );
  };
  
  export default TrademarkRenewalsPage;