import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";

const AmazonBrandRegistryPage = () => {
       
  return (
      <div>
        {/* <Header /> */}
        <main>
          <div className='comp'>
            <span className='comp-name'>Amazon Brand Registry</span>
            <span className='comp-descrip'>Safeguard Your Trademark, Enhance Your Visibility</span>
          </div>
          <div className='main-section'>
            <p>Looking to protect your brand on Amazon? Let San Novus Trademark guide you through the Amazon Brand Registry process with 
                expertise and ease.</p>
          </div>
          <div className='main-section-alt'>
            <h3>What is the Amazon Brand Registry</h3>
            <p>The Amazon Brand Registry empowers sellers to protect their brands from unauthorized listings and infringements. 
                Unlike traditional legal routes that can be slow and costly, Amazon provides proactive tools to identify and remove problematic 
                listings quickly. With proof of your registered or pending IP, Amazon actively compares new listings to your trademark, preventing 
                potential infringements before they can impact your business.
            </p>
          </div>
          <div className='main-section'>
            <h3>Eligibility Requirements</h3>
            <p>To enroll in the Amazon Brand Registry, you need:</p>
                <ol style={{textAlign:'left'}}>
                    <li style={{listStyleType:'decimal', width:'100%'}}>An active Amazon Seller Account. <a href="https://sellercentral.amazon.com/" target="_blank" rel="noopener noreferrer">Learn how to create one.</a></li>
                    <li style={{listStyleType:'decimal', width:'100%'}}>A registered or pending trademark in any country where you plan to sell.</li>
                </ol>
          </div>
          <div className='main-section-alt'>
            <h3>Streamlining Your Application</h3>
            <p>San Novus Trademark ensures a smooth registration process by monitoring Amazon's notifications and providing swift 
                responses to get you verified quickly.</p>
          </div>
          <div className='main-section'>
            <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
            <FAQs tags="amazon" />
          </div>
        </main>
      </div>
    );
  };
  
  export default AmazonBrandRegistryPage;