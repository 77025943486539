import React from 'react';

export const createClientEmail = (formData, submissionType) => {

    return `
        <html>
        <head>
            <style>
                body { font-family: Times-New-Roman, serif; }
                h2 { color: #2E59A6; }
                p { margin: 0; }
                ul { padding-left: 20px; }
                li { margin-bottom: 5px; }
            </style>
        </head>
        <body>
            <p style="color: #2E59A6;">Thank you for your submission! We have received your request for ${submissionType}. Attached for your records is a copy of the executed letter 
                of engagement, as well as a copy of the information that you provided to us.</p>
            <br />
            ${formData.potentialConflicts.length > 0 ? `
                <p style="color: #2E59A6;">Based on the information you provided, it looks like we might have a conflict on our end. Because the initial conflict check was 
                    conducted using keywords as part of the trademark, it's possible that this isn't a true conflict. In the interest of providing each of 
                    our clients with the best possible service, We will give this matter a detailed review and get back to you as soon as possible. We 
                    complete our analysis within one business day. Please rest assured that if we determine that there is a conflict in place, the 
                    information you provided will be promptly removed from our systems, and no further action will be taken. We are ethically bound to not 
                    share your information with our existing client about the trademark you are seeking.</p>
            ` : `
                <p style="color: #2E59A6;">We are looking forward to the opportunity to assist you with your trademark needs. Our office will be in touch shortly if we require any 
                additional information or materials. In the meantime, please do not hesitate to contact us at 
                <a href='mailto:info@sannovustrademark.com' style="color: blue;">info@sannovustrademark.com</a> if you have any 
                questions or need additional information about anything.</p>
            `}
            <br />
            <p style="font-size: 12pt; font-style: italic; color: red;">This is an automated message from an unmonitored mailbox. Please do not reply directly to 
                this message.</p>
            <img src="COMPANY_LOGO_PLACEHOLDER" alt="San Novus Trademark Logo" style="width: 2.25rem; height: auto; margin-top: 15px;">
            <p style="font-size: 9pt; color: #2E59A6; margin-top: 15px">This message contains information intended only for the use of the addressee(s) named above and may contain 
                information that is legally privileged. This e-mail is covered by the Electronics Communications Privacy Act, 18 U.S.C. §§ 2510-2521. If 
                you are not the addressee, or the person responsible for delivering it to the addressee, you are hereby notified that reading, disseminating, 
                distributing or copying this message is strictly prohibited. If you have received this message by mistake, please immediately notify us by 
                replying to the message and delete the original message immediately thereafter. Thank you.</p>
            <p style="font-size: 9pt; color: #2E59A6; margin-top: 15px">ATTORNEY-CLIENT, COMMUNITY OF INTEREST PRIVILEGE AND/OR WORK PRODUCT PRIVILEGED COMMUNICATION</p>
            <p style="font-size: 9pt; color: #2E59A6; margin-top: 15px">Depending upon the recipient, this communication may be protected by the attorney-client, community of interest 
                privilege and/or the work product privilege and should be treated in a confidential manner. Any disclosure to other than key management 
                personnel on a need-to-know basis may jeopardize the privilege and require disclosure to adverse parties in litigation.</p>
        </body>
        </html>
    `;
};
