import React, { useState, useEffect } from 'react';
import './App.css';
import { useAuth } from './AuthContext.js';

const MattersList = () => {
  const { user, loading: authLoading } = useAuth();
  const [matter, setMatter] = useState(null);
  const [activeMatterId, setActiveMatterId] = useState('');
  const [editableFields, setEditableFields] = useState({});
  const [clientData, setClientData] = useState(null);
  const [documentUrls, setDocumentUrls] = useState({ designLogo: [], specimen: {} });
  const [markType, setMarkType] = useState("");
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [classBlocks, setClassBlocks] = useState([]);
  const [fieldStates, setFieldStates] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const [finalFormData, setFinalFormData] = useState({});
  const [statusFieldId, setStatusFieldId] = useState('');
  const [reviewingAtty, setReviewingAtty] = useState('');
  const [reviewingAttyFieldId, setReviewingAttyFieldId] = useState('');
  const [reviewedOnFieldId, setReviewedOnFieldId] = useState('');

  useEffect(() => {
    // Fetch data and initialize form data if user is available
    if (user && !authLoading) {
      const atty = formatEmailToName(user.email);
      setReviewingAtty(atty);
    }
  }, [user, authLoading]);

  const formatEmailToName = (email) => {
    // Extract the part before '@'
    const username = email.split('@')[0];
  
    // Split the username into first character and remaining characters
    const firstCharacter = username.charAt(0).toUpperCase();
    const remainingCharacters = username.slice(1).toLowerCase();

    // Capitalize the first letter of remainingCharacters
    const formattedRemainingCharacters = remainingCharacters.charAt(0).toUpperCase() + remainingCharacters.slice(1);

  
    // Combine to form "F. Last"
    return `${firstCharacter}. ${formattedRemainingCharacters}`;
  };


  const excludeFields = [
    'Application Filing Date', 'Registration Date', 'Application Review Date', 
    'Register Type', 'Trademark Status', 'Application Serial Number', 'Registration Number','Application Reviewed By'
  ];

  const markTypeMapping = {
    '9417019': 'Word Mark',
    '9417034': 'Design Logo',
    '9417049': 'Sound Mark'
  };

  useEffect(() => {
    if (matter) {
      const markTypeField = matter.custom_field_values.find(field => field.field_name === "Mark Type");
      if (markTypeField) {
        const type = markTypeMapping[markTypeField.value.toString()] || "";
        setMarkType(type);
      }

      const blocks = {};
      matter.custom_field_values.forEach(field => {
        const match = field.field_name.match(/\d+$/);
        if (match) {
          const id = match[0];
          blocks[id] = blocks[id] || {};
          blocks[id][field.field_name.replace(/\s+\d+$/, '')] = field;
        }
      });

      setClassBlocks(Object.values(blocks));
    }
  }, [matter]);

  const resetStates = () => {
    setMatter(null);
    setEditableFields({});
    setClientData(null);
    setDocumentUrls({ designLogo: [], specimen: {} });
    setMarkType("");
    setEnlargedImage(null);
    setClassBlocks([]);
    setFieldStates({});
    setLoading(false);
    setStatusFieldId('');
    setActiveMatterId('');
    setReviewingAttyFieldId('');
    setReviewedOnFieldId('');
  };

  const fetchMatter = async () => {
    resetStates();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/matters`);
      const data = await response.json();
      if (data.data && data.data.length > 0) {
        const firstMatter = data.data[0];
        setMatter(firstMatter);

        if (firstMatter.id) {

          const trademarkStatusField = firstMatter.custom_field_values.find(field => field.field_name === "Trademark Status");
          const trademarkReviewingAtty = firstMatter.custom_field_values.find(field => field.field_name === "Application Reviewed By");
          const reviewedOn = firstMatter.custom_field_values.find(field => field.field_name === "Application Reviewed On");
          
          const trademarkStatusFieldId = trademarkStatusField ? trademarkStatusField.id : null;
          const trademarkReviewingAttyId = trademarkReviewingAtty ? trademarkReviewingAtty.id : null;
          const reviewedOnId = reviewedOn ? reviewedOn.id : null;

          setStatusFieldId(trademarkStatusFieldId);
          setActiveMatterId(firstMatter.id);
          setReviewingAttyFieldId(trademarkReviewingAttyId);
          console.log('Initially gathered trademarkReviewingAttyId:',trademarkReviewingAttyId);
          setReviewedOnFieldId(reviewedOnId);
          console.log('Initially gathered reviewedOnFieldId:',reviewedOnId);

          if (trademarkStatusFieldId) {
            await matterUnderReview(firstMatter.id, trademarkStatusFieldId);
          } else {
            console.error('Trademark Status field ID not found');
          }

          await fetchRelatedContacts(firstMatter.id);
          await fetchDocs(firstMatter.id);
        } else {
          console.error('Matter ID is undefined');
        }

        const initialFields = {};
        firstMatter.custom_field_values.forEach((field) => {
          if (field.value) {
            initialFields[field.field_name] = field.value;
          }
        });
        setEditableFields(initialFields);
      } else {
        setMatter(null);
      }
    } catch (error) {
      console.error('Error fetching matters:', error);
    }
    setLoading(false);
  };

  const matterUnderReview = async (matterId, trademarkStatusFieldId) => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/${matterId}/status`;
    const statusUpdate = {
      data: {
        "custom_field_values": [
          {
            "id": trademarkStatusFieldId,
            "custom_field": { "id": "16055884" }, 
            "value": "9744529"
          }
        ]
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusUpdate)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
    } catch (error) {
      console.error('Error updating matter status:', error);
    }
  };

  const matterApproved = async () => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/${activeMatterId}/status`;
    const approvedDate = await formatDateTime();

    const statusUpdate = {
      data: {
        "custom_field_values": [
          {
            "id": statusFieldId,
            "custom_field": { "id": "16055884" }, 
            "value": "9416689"
          },
          {
            "id":reviewingAttyFieldId,
            "custom_field": {"id": "16055404"},
            "value":reviewingAtty
          },
          {
            "id":reviewedOnFieldId,
            "custom_field": {"id": "18269225"},
            "value":approvedDate
          }
        ]
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusUpdate)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
    } catch (error) {
      console.error('Error updating matter status:', error);
    }
  };

  const formatDateTime = () => {
    const now = new Date();
  
    // Extract date components
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();
  
    // Extract time components
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    // Get timezone offset in hours and minutes
    const timezoneOffset = now.getTimezoneOffset();
    const timezoneHours = String(Math.abs(Math.floor(timezoneOffset / 60))).padStart(2, '0');
    const timezoneMinutes = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');
    const timezoneSign = timezoneOffset > 0 ? '-' : '+';
  
    // Format the date and time string with "AZ TIME"
    const formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} AZ TIME`;
 
    return formattedDateTime;
  };
  
  // Example usage
  console.log(formatDateTime());
  

  const fetchClient = async (matterId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/matters/${matterId}/client`);
      const data = await response.json();
      console.log('fetchClient:', data);
      setClientData(data.data);
      console.log('clientData after fetchClient:', data.data);
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  const fetchRelatedContacts = async (matterId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/matters/${matterId}/related_contacts`);
      const data = await response.json();
      const trademarkOwner = data.data.find(contact => contact.relationship && contact.relationship.description === "Trademark Owner");
      console.log('fetchRelatedContacts:', data);
      if (trademarkOwner) {
        setClientData(trademarkOwner);
        console.log('clientData after fetchRelatedContacts:', trademarkOwner);
      } else {
        await fetchClient(matterId);
      }
    } catch (error) {
      console.error('Error fetching related contacts:', error);
    }
  };

  const fetchDocs = async (matterId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/documents/${matterId}`);
      const data = await response.json();

      const uniqueDocs = new Map();
      data.data.forEach(doc => {
        if (doc.document_category && ["Design Logo", "Specimen"].includes(doc.document_category.name)) {
          uniqueDocs.set(doc.id, doc);
        }
      });

      const downloadPromises = Array.from(uniqueDocs.values()).map(doc => {
        const category = doc.document_category.name.toLowerCase().replace(" ", "");
        const classBlockNumber = category === "specimen" ? doc.name.match(/^(\d+)/)?.[1] : undefined;
        return downloadDocs(doc.id, category, classBlockNumber);
      });

      const downloadedDocs = await Promise.all(downloadPromises);

      const newDocumentUrls = downloadedDocs.reduce((acc, { category, url, classBlockNumber }) => {
        if (category === "designlogo") {
          acc.designLogo = [...new Set([...acc.designLogo, url])];
        } else if (category === "specimen" && classBlockNumber) {
          acc.specimen[classBlockNumber] = [...new Set([...(acc.specimen[classBlockNumber] || []), url])];
        }
        return acc;
      }, { ...documentUrls });

      setDocumentUrls(newDocumentUrls);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
    setLoading(false);
  };

  const downloadDocs = async (docId, category, classBlockNumber) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/documents/${docId}/download`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return { category, url, classBlockNumber };
    } catch (error) {
      console.error('Error downloading document:', error);
      return { category, url: '', classBlockNumber };
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setFieldStates(prevStates => ({
      ...prevStates,
      [fieldName]: {
        ...prevStates[fieldName],
        value: value
      }
    }));
  };

  const renderFieldsBasedOnMarkType = () => {
    let fieldsToDisplay = [];

    if (markType === "Word Mark") {
      fieldsToDisplay = [
        renderField("Mark Type"),
        renderField("Mark"),
      ];
    } else if (markType === "Design Logo") {
      fieldsToDisplay = [
        renderField("Mark Type"),
        renderDocuments("designLogo"),
        renderField("Literal Element"),
        renderField("Color Claim"),
      ];
    }

    return fieldsToDisplay;
  };

  const renderField = (fieldName) => {
    const field = matter.custom_field_values.find(f => f.field_name === fieldName);
    if (!field) return null;

    if (!fieldStates[fieldName]) {
      const initialValue = markTypeMapping[field.value] || field.value;
      fieldStates[fieldName] = { value: initialValue || '', isEditable: false };
    }

    const displayValue = fieldStates[fieldName].value;

    const handleBlur = () => {
      setFieldStates(prevStates => ({
        ...prevStates,
        [fieldName]: {
          ...prevStates[fieldName],
          isEditable: false
        }
      }));
    };

    return (
      <div key={fieldName} className='info-line mb-2'>
        <label className="form-label">{fieldName}:</label>
        <input
          type="text"
          className="form-control"
          value={displayValue || ''}
          onChange={(e) => handleFieldChange(fieldName, e.target.value)}
          readOnly={!fieldStates[fieldName].isEditable}
          onBlur={handleBlur}
        />
        {/* <button onClick={() => toggleEdit(fieldName)} className='btn btn-primary mt-1'>Edit</button> */}
      </div>
    );
  };

  const renderDocuments = (category) => {
    if (!category || !documentUrls[category]) {
      console.error("Attempted to render documents for an undefined or unrecognized category:", category);
      return null;
    }

    const handleClick = (url) => {
      setEnlargedImage(url);
    };

    return (
      <div className='info-line mb-3'>
        {category === "specimen" && 
          <div className='specimen-container'>
            <div className="document-header">Specimen(s):</div>
            <div className="row">
              {documentUrls[category].map((url, index) => {
                const isValidUrl = url && typeof url === 'string';
                return isValidUrl ? (
                  <div key={`${category}-${index}`} className="col-6 col-md-4 col-lg-3">
                    <img 
                      src={url} alt={`${category} Document ${index}`} 
                      className='img-fluid'
                      onClick={() => handleClick(url)}
                    />
                  </div>
                ) : null;
              })}
            </div>
          </div>
        }
        {category === "designLogo" && (
        <>
          <label>Mark:</label>
          <div className="row">
            {documentUrls[category].map((url, index) => {
              const isValidUrl = url && typeof url === 'string';
              return isValidUrl ? (
                <div key={`${category}-${index}`} className="col-6 col-md-4 col-lg-3">
                  <img key={`${category}-${index}`} src={url} alt='' className='img-fluid'/>
                </div>
              ) : null;
            })}
          </div>
        </>
      )}
      </div>
    );
  };

  const handleImageClick = (url) => {
    setEnlargedImage(url);
  };

  const handleCloseEnlargedImage = () => {
    setEnlargedImage(null);
  };

  const renderClassBlock = (block, index) => {
    const classBlockNumber = index + 1;

    const specimensForBlock = documentUrls.specimen[classBlockNumber] || [];

    const fieldsOrder = [
      `Class ${classBlockNumber}`,
      `Description ${classBlockNumber}`
    ];

    const fieldsToRender = fieldsOrder.map(fieldName => {
      const fieldKey = fieldName.replace(/\s+\d+$/, '');
      const field = block[fieldKey];
      if (!field) return null;

      if (!fieldStates[fieldName]) {
        const initialValue = field.picklist_option ? field.picklist_option.option : field.value;
        setFieldStates(prevStates => ({
          ...prevStates,
          [fieldName]: { value: initialValue || '', isEditable: false }
        }));
      }

      const value = fieldStates[fieldName] ? fieldStates[fieldName].value : '';

      const handleBlur = () => {
        setFieldStates(prevStates => ({
          ...prevStates,
          [fieldName]: {
            ...prevStates[fieldName],
            isEditable: false
          }
        }));
      };

      return (
        <div key={fieldName} className='info-line mb-2'>
          <label className="form-label">{fieldName}:</label>
          <input
            type="text"
            className="form-control"
            value={value || ''}
            onChange={(e) => handleFieldChange(fieldName, e.target.value)}
            readOnly={!fieldStates[fieldName]?.isEditable}
            onBlur={handleBlur}
          />
          {/* <button onClick={() => toggleEdit(fieldName)} className='btn btn-primary mt-1'>Edit</button> */}
        </div>
      );
    });

    const dateOfFirstUseField = block["Date of First Use"];
    const dateOfFirstUseInCommerceField = block["Date of First Use in Commerce"];
    const hasFirstUseDates = (dateOfFirstUseField && dateOfFirstUseField.value) || (dateOfFirstUseInCommerceField && dateOfFirstUseInCommerceField.value);

    if (dateOfFirstUseField && dateOfFirstUseField.value) {
      fieldsToRender.push(
        <div key={`Date of First Use ${classBlockNumber}`} className='info-line mb-2'>
          <label className="form-label">Date of First Use:</label>
          <input
            type="text"
            className="form-control"
            value={dateOfFirstUseField.value || ''}
            readOnly
          />
        </div>
      );
    }

    if (dateOfFirstUseInCommerceField && dateOfFirstUseInCommerceField.value) {
      fieldsToRender.push(
        <div key={`Date of First Use in Commerce ${classBlockNumber}`} className='info-line mb-2'>
          <label className="form-label">Date of First Use in Commerce:</label>
          <input
            type="text"
            className="form-control"
            value={dateOfFirstUseInCommerceField.value || ''}
            readOnly
          />
        </div>
      );
    }

    return (
      <div key={index} className="app-review-class-block mb-3">
        {fieldsToRender}

        {!hasFirstUseDates && (
          <div className='info-line mb-2'>
            <label className="form-label">Use:</label>
            <span>Intent-to-Use</span>
          </div>
        )}

        {specimensForBlock.length > 0 && (
          <div className='specimen-container'>
            <div className="document-header">Specimen(s) for Class {classBlockNumber}:</div>
            <div className="row">
              {specimensForBlock.map((url, idx) => {
                const isValidUrl = url && typeof url === 'string';
                return isValidUrl ? (
                  <div key={`specimen-${classBlockNumber}-${idx}`} className="col-6 col-md-4 col-lg-3">
                    <img 
                      src={url}
                      alt={`Specimen for Class ${classBlockNumber}`}
                      className='img-fluid'
                      onClick={() => setEnlargedImage(url)}
                    />
                  </div>
                ) : null;
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const toggleEdit = (fieldName) => {
    setFieldStates(prevStates => ({
      ...prevStates,
      [fieldName]: {
        ...prevStates[fieldName],
        isEditable: !prevStates[fieldName].isEditable
      }
    }));
  };

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <div>
      <div className='container mt-3 app-review-container'>
        <div className='row'>
          <div className='col-12'>
            <button onClick={fetchMatter} className='btn btn-primary mb-3'>Retrieve Matter and Client Info</button>
            <button onClick={matterApproved} className='btn btn-primary mb-3'>Approve</button>
          </div>
        </div>
        {matter && clientData ? (
          <div className='row'>
            <div className='col-12'>
              <div className='info-line mb-2'>
                <label className="form-label">Matter Number:</label>
                <span>{matter.display_number}</span>
              </div>
              <div className="client-review mb-3">
                <div className='app-section-header mb-2'>
                  <label>Contact:</label>
                </div>

                <div className="info-line mb-2">
                  <label className="form-label">Name:</label>
                  <span>{clientData.name}</span>
                </div>

                {clientData.addresses?.length > 0 && (
                  <div className="info-line mb-2">
                    <label className="form-label">Address:</label>
                    <span>
                      {clientData.addresses[0].street}<br />
                      {`${clientData.addresses[0].city}, ${clientData.addresses[0].province} ${clientData.addresses[0].postal_code}`}<br />
                      {clientData.addresses[0].country}
                    </span>
                  </div>
                )}

                {clientData.email_addresses?.length > 0 && (
                  <div className="info-line mb-2">
                    <label className="form-label">Email:</label>
                    <span>{clientData.email_addresses[0].address}</span>
                  </div>
                )}
              </div>

              <div className='app-section-header mb-2'>
                <label>Mark:</label>
              </div>

              <div className='info-section mb-3'>
                {renderFieldsBasedOnMarkType()}
              </div>

              <div className='app-section-header mb-2'>
                <label>Goods/Services:</label>
              </div>

              <div className='info-section'>
                {classBlocks.map(renderClassBlock)}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center mt-5">Please retrieve the matter and client info.</div>
        )}
      </div>

      {enlargedImage && (
        <div className="enlarged-specimen" onClick={handleCloseEnlargedImage}>
          <span className="close-enlarged-specimen">&times;</span>
          <img className="enlarged-specimen-content img-fluid" src={enlargedImage} alt="Enlarged specimen" />
        </div>
      )}
    </div>
  );
};

export default MattersList;
