// import React from "react";
// import logo from './FullLogo6.png';
// import './App.css';
// import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// const Header = () => {
    
//       return (
//         <div className='header-container'>
//           <div>
//             <div><Link to='/'><img src={logo} alt="San Novus Trademark Logo" className='full-logo' /></Link></div>
//             <div className='navbar'>
//               <nav>
//                 <ul>
//                   <li><a href='/'>Home</a></li>
//                   <li className='dropdown'>
//                     <a href='/services' /* style={navItemStyle} */>Services</a>
//                     <div className='dropdown-content'>
//                       <a href='/trademark-searches'>Trademark Searches</a>
//                       <a href='/trademark-registration-applications'>Trademark Registration Applications</a>
//                       <a href='/trademark-oar'>Trademark Office Action Responses</a>
//                       <a href='/trademark-sou'>Trademark Statements of Use</a>
//                       <a href='/trademark-renewals'>Trademark Registration Renewals</a>
//                       <a href='/international-trademarks'>International Trademark Applications</a>
//                       <a href='/amazon-brand-registry'>Amazon Brand Registry</a>
//                     </div>
//                   </li>
//                   <li><a href='about'>About</a></li>
//                   <li><a href='why-us'>Why Us?</a></li>
//                   <li><a href='faq'>FAQ</a></li>
//                   <li><a href='pricing'>Pricing</a></li>
//                   <li><a href='contact-us'>Contact Us</a></li>
//                   {/* <li><a href='contact' style={navItemStyle}>Contact Us</a></li> */}
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>
//     );
// };

// export default Header;

import React, { useEffect, useRef, useState } from "react";
import logo from './FullLogo6.png';
import './App.css';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Collapse } from "bootstrap";

const Header = () => {
    const location = useLocation();
    const navbarRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const navbarCollapse = document.getElementById('navbarNavDropdown');
        const collapseInstance = new Collapse(navbarCollapse, {
            toggle: false
        });

        const handleNavbarToggle = () => {
            collapseInstance.toggle();
        };

        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                collapseInstance.hide();
            }
        };

        const navbarToggler = document.getElementById('navbar-toggler');
        navbarToggler.addEventListener('click', handleNavbarToggle);
        document.addEventListener('click', handleClickOutside);

        // Ensure the navbar is collapsed when the route changes
        return () => {
            collapseInstance.hide();
            navbarToggler.removeEventListener('click', handleNavbarToggle);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [location]);

    const handleServicesClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            const dropdown = document.getElementById('servicesDropdown');
            const dropdownInstance = Collapse.getOrCreateInstance(dropdown);
            dropdownInstance.toggle();
        }
    };

    return (
        <header className="header-container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid" style={{ height: '8rem', position: 'relative' }}>
                    {/* Toggler Button for Mobile View */}
                    <button
                        id="navbar-toggler"
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        // data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style={{ position: 'absolute', left: '7rem', top: '2rem', zIndex: 1050 }}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/* Brand Logo */}
                    <Link to='/' className="navbar-brand" style={{ position: 'absolute', right: '1rem', top: '1rem', zIndex: 1050 }}>
                        <img src={logo} alt="San Novus Trademark Logo" className="full-logo"/>
                    </Link>
                </div>

                {/* Navbar Content */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link
                                to="/services"
                                className="nav-link dropdown-toggle"
                                id="servicesDropdown"
                                role="button"
                                data-bs-toggle='dropdown'
                                aria-expanded="false"
                                onClick={handleServicesClick}
                            >
                                Services
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                                <li className="dropdown-item"><Link to="/trademark-searches" className="dropdown-item">Trademark Searches</Link></li>
                                <li className="dropdown-item"><Link to="/trademark-registration-applications" className="dropdown-item">Trademark Registration Applications</Link></li>
                                <li className="dropdown-item"><Link to="/trademark-oar" className="dropdown-item">Trademark Office Action Responses</Link></li>
                                <li className="dropdown-item"><Link to="/trademark-sou" className="dropdown-item">Trademark Statements of Use</Link></li>
                                <li className="dropdown-item"><Link to="/trademark-renewals" className="dropdown-item">Trademark Registration Renewals</Link></li>
                                <li className="dropdown-item"><Link to="/international-trademarks" className="dropdown-item">International Trademark Applications</Link></li>
                                <li className="dropdown-item"><Link to="/amazon-brand-registry" className="dropdown-item">Amazon Brand Registry</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item"><Link to="/about" className="nav-link">About</Link></li>
                        <li className="nav-item"><Link to="/why-us" className="nav-link">Why Us?</Link></li>
                        <li className="nav-item"><Link to="/faq" className="nav-link">FAQ</Link></li>
                        <li className="nav-item"><Link to="/pricing" className="nav-link">Pricing</Link></li>
                        <li className="nav-item"><Link to="/contact-us" className="nav-link">Contact Us</Link></li>
                        <li className="nav-item"><Link to="/clientPortal" className="nav-link">Client Portal</Link></li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;


