import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { components } from 'react-select';

const domesticEntities = [
    {name: "Individual", abbreviation: "Individual"},
    {name: "Corporation", abbreviation: "Corporation"},
    {name: "Limited Liability Company", abbreviation: "Limited liability company"},
    {name: "Limited liability partnership", abbreviation: "Limited liability partnership"},
    {name: "Partnership", abbreviation: "Partnership"},
    {name: "Limited partnership", abbreviation: "Limited partnership"},
    {name: "Sole Proprietorship", abbreviation: "Sole proprietorship"},
    {name: "Trust", abbreviation: "Trust"},
    {name: "Estate", abbreviation: "Estate"},
    {name: "Joint venture", abbreviation: "Joint venture"},
    {name: "Conservatorship", abbreviation: "Conservatorship"},
    {name: "Association", abbreviation: "Association"},
    {name: "Banking Institutions", abbreviation: "", isSeparator: true},
    {name: "Chartered Bank", abbreviation: "Chartered Bank"},
    {name: "Congressionally Chartered Nonprofit Organization", abbreviation: "Congressionally Chartered Nonprofit Organization"},
    {name: "Cooperative Corporation", abbreviation: "Cooperative Corporation"},
    {name: "Credit Union", abbreviation: "Credit Union"},
    {name: "Federally Chartered Credit Union", abbreviation: "Federally Chartered Credit Union"},
    {name: "Federally Recognized Indian Tribe", abbreviation: "Federally Recognized Indian Tribe"},
    {name: "Limited Liability Limited Partnership", abbreviation: "Limited Liability Limited Partnership"},
    {name: "State-Recognized Indian Tribe", abbreviation: "State-Recognized Indian Tribe"},
    {name: "Corporation, Association, Organization and Company", abbreviation: "", isSeparator: true},
    {name: "Charitable Corporation", abbreviation: "Charitable Corporation"},
    {name: "Charitable, Non-Profit Corporation", abbreviation: "Charitable, Non-Profit Corporation"},
    {name: "Cooperative Association", abbreviation: "Cooperative Association"},
    {name: "Federally Charted Corporation", abbreviation: "Federally Charted Corporation"},
    {name: "Incorporated association", abbreviation: "Incorporated association"},
    {name: "Joint Stock Company", abbreviation: "Joint Stock Company"},
    {name: "National Banking Association", abbreviation: "National Banking Association"},
    {name: "Nonprofit association", abbreviation: "Nonprofit association"},
    {name: "Non-profit Corporation", abbreviation: "Non-profit Corporation"},
    {name: "Non-stock Corporation", abbreviation: "Non-stock Corporation"},
    {name: "Professional Corporation", abbreviation: "Professional Corporation"},
    {name: "Public Benefit Corporation", abbreviation: "Public Benefit Corporation"},
    {name: "Stock Company", abbreviation: "Stock Company"},
    {name: "Tax Exempt Corporation", abbreviation: "Tax Exempt Corporation"},
    {name: "Unincorporated association", abbreviation: "Unincorporated association"},
    {name: "Governmental Bodies and Universities", abbreviation: "", isSeparator: true},
    {name: "Agency of the U.S. Government", abbreviation: "Agency of the U.S. Government"},
    {name: "Body Politic and Corporate", abbreviation: "Body Politic and Corporate"},
    {name: "Federal Agency", abbreviation: "Federal Agency"},
    {name: "Instrumentality", abbreviation: "Instrumentality"},
    {name: "Municipal Corporation", abbreviation: "Municipal Corporation"},
    {name: "State Agency", abbreviation: "State Agency"},
    {name: "State University", abbreviation: "State University"},
    {name: "Trusts, Conservatorship, and Estates", abbreviation: "", isSeparator: true},
    {name: "Business Trusts", abbreviation: "Business Trusts"},
    {name: "Charitable Trust", abbreviation: "Charitable Trust"},
    {name: "Conservatorship", abbreviation: "Conservatorship"},
    {name: "Foundation", abbreviation: "Foundation"},   
];

const foreignEntities = [
    {name: "Corporation", abbreviation: "Corporation"},
    {name: "Limited Liability Company", abbreviation: "Limited liability company"},
    {name: "Limited liability partnership", abbreviation: "Limited liability partnership"},
    {name: "Partnership", abbreviation: "Partnership"},
    {name: "Limited partnership", abbreviation: "Limited partnership"},
    {name: "Sole Proprietorship", abbreviation: "Sole proprietorship"},
    {name: "Trust", abbreviation: "Trust"},
    {name: "Estate", abbreviation: "Estate"},
    {name: "Joint venture", abbreviation: "Joint venture"},
    {name: "Conservatorship", abbreviation: "Conservatorship"},
    {name: "Association", abbreviation: "Association"},  
    {name: "", abbreviation: "", isSeparator: true},
    {name: "Joint stock company or company limited by shares", abbreviation: "Joint stock company or company limited by shares"},
    {name: "Limited Company or Limited Liability Company (A company in which the liability of the shareholders is limited)", abbreviation: "Limited Company or Limited Liability Company (A company in which the liability of the shareholders is limited)"},
    {name: "Limited liability company by quotas", abbreviation: "Limited liability company by quotas"},
    {name: "Limited liability company by shares", abbreviation: "Limited liability company by shares"},
    {name: "Mixed liability company by shares", abbreviation: "Mixed liability company by shares"},
    {name: "Unlimited liability company with two or more owners", abbreviation: "Unlimited liability company with two or more owners"},
    {name: "Banking Institutions", abbreviation: "", isSeparator: true},
    {name: "Chartered Bank", abbreviation: "Chartered Bank"},
    {name: "Congressionally Chartered Nonprofit Organization", abbreviation: "Congressionally Chartered Nonprofit Organization"},
    {name: "Cooperative Corporation", abbreviation: "Cooperative Corporation"},
    {name: "Credit Union", abbreviation: "Credit Union"},
    {name: "Federally Chartered Credit Union", abbreviation: "Federally Chartered Credit Union"},
    {name: "Federally Recognized Indian Tribe", abbreviation: "Federally Recognized Indian Tribe"},
    {name: "Limited Liability Limited Partnership", abbreviation: "Limited Liability Limited Partnership"},
    {name: "State-Recognized Indian Tribe", abbreviation: "State-Recognized Indian Tribe"},
    {name: "Corporation, Association, Organization and Company", abbreviation: "", isSeparator: true},
    {name: "Charitable Corporation", abbreviation: "Charitable Corporation"},
    {name: "Charitable, Non-Profit Corporation", abbreviation: "Charitable, Non-Profit Corporation"},
    {name: "Cooperative Association", abbreviation: "Cooperative Association"},
    {name: "Federally Charted Corporation", abbreviation: "Federally Charted Corporation"},
    {name: "Incorporated association", abbreviation: "Incorporated association"},
    {name: "Joint Stock Company", abbreviation: "Joint Stock Company"},
    {name: "National Banking Association", abbreviation: "National Banking Association"},
    {name: "Nonprofit association", abbreviation: "Nonprofit association"},
    {name: "Non-profit Corporation", abbreviation: "Non-profit Corporation"},
    {name: "Non-stock Corporation", abbreviation: "Non-stock Corporation"},
    {name: "Professional Corporation", abbreviation: "Professional Corporation"},
    {name: "Public Benefit Corporation", abbreviation: "Public Benefit Corporation"},
    {name: "Stock Company", abbreviation: "Stock Company"},
    {name: "Tax Exempt Corporation", abbreviation: "Tax Exempt Corporation"},
    {name: "Unincorporated association", abbreviation: "Unincorporated association"},
    {name: "Governmental Bodies and Universities", abbreviation: "", isSeparator: true},
    {name: "Agency of the U.S. Government", abbreviation: "Agency of the U.S. Government"},
    {name: "Body Politic and Corporate", abbreviation: "Body Politic and Corporate"},
    {name: "Federal Agency", abbreviation: "Federal Agency"},
    {name: "Instrumentality", abbreviation: "Instrumentality"},
    {name: "Municipal Corporation", abbreviation: "Municipal Corporation"},
    {name: "State Agency", abbreviation: "State Agency"},
    {name: "State University", abbreviation: "State University"},
    {name: "Trusts, Conservatorship, and Estates", abbreviation: "", isSeparator: true},
    {name: "Business Trusts", abbreviation: "Business Trusts"},
    {name: "Charitable Trust", abbreviation: "Charitable Trust"},
    {name: "Conservatorship", abbreviation: "Conservatorship"},
    {name: "Foundation", abbreviation: "Foundation"},
  ];

/*   const [ selectedDomesticEntity, setSelectedDomesticEntity] = useState(null);
  const [ selectedForeignEntity, setSelectedForeignEntity] = useState([]);

  const options = foreignEntities.map(foreignEntity => ({
    value: foreignEntity.abbreviation,
    label: foreignEntity.name,
    isSeparator: foreignEntity.isSeparator
  })); */

   const CustomOption = (props) => {
    // Check if the option is a separator
    if (props.data.isSeparator) {
      return (
        <div style={{ margin: '10px 0', borderTop: '1px solid #ccc', pointerEvents: 'none' }}></div>
      );
    }
    
    // Apply bold styling conditionally
    const isBold = props.data.label.includes('United') || props.data.label.includes('China');
    return (
      <components.Option {...props}>
        <div style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  
  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children.map(child => {
          // Check if the current child is a separator and apply custom rendering
          if (child.props.data.isSeparator) {
            return (
              <div key="separator" style={{ margin: '10px 0', borderTop: '5px solid #ccc' }} />
            );
          }
          return child;
        })}
      </components.MenuList>
    );
  };

  const CompanyType = ({ id, value, onChange, isDisabled, ownerCompanyCountry }) => {
    // const selectedOption = options.find(option => option.value === value)
    const [options, setOptions] = useState ([]);

    useEffect(() => {
        const entityOptions = ownerCompanyCountry === 'USX' ? domesticEntities : foreignEntities;
        const formattedOptions = entityOptions.map(entity => ({
            value: entity.abbreviation,
            label: entity.name,
            isSeparator: entity.isSeparator
        }));
        setOptions(formattedOptions);
    }, [ownerCompanyCountry]);

    const handleChange = (selectedOption) => {
        onChange(selectedOption, id);
    };

    const customStyles = {
      control: (styles) => ({
        ...styles,
        height: '38px',
        minHeight: '38px',
        // width: '275px'
        width: '100%',
        minWidth: '12rem',
      }),
      singleValue: (styles) => ({...styles,
      color: 'black',
      }),
      valueContainer: (styles) => ({
        ...styles,
        height: '30px',
        padding: '0 5px',
        outerWidth: '150px'

      }),
      input: (styles) => ({
        ...styles,
        margin: '0px',
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        height: '30px',
      }),
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? 'lightgray' : isSelected ? 'gray' : null,
          color: 'black',
        };
      },
      placeholder: (styles) => ({
        ...styles,
        color: 'black',
      }),
    };

   return (
        <Select
          styles={customStyles}  
          options={options}
          onChange={handleChange}
          value={options.find(option => option.value === value)}
          placeholder="Select Entity"
          isSearchable={true}
          components={{ Option: CustomOption, MenuList }}
          isOptionDisabled={(option) => option.isSeparator}
          name='companyType'
          id='companyType'
          isDisabled={isDisabled}
        />
    );
};
  
  export default CompanyType;