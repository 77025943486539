import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from './supabase.js';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [intendedPath, setIntendedPath] = useState('/');
  const navigate = useNavigate();

  // Effect to handle the initial check of the user's session
  useEffect(() => {
    const checkSession = async () => {
        const { data, error } = await supabase.auth.getSession();
        setUser(data.session?.user || null);
        setLoading(false);
        if (error) {
            console.error('Error retrieving session:', error.message);
            return;
        }
        setUser(data.session?.user || null);
    };
  
    checkSession();

     
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
    //   setUser(session?.user || null);
    });
  
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);
  

  // Function to handle login
  const login = async (email, password) => {
    const { error, user } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
        console.error('Login error:', error.message);
    } else {
        // setUser(user);
        setUser({email});
        navigate(intendedPath);
        //Reset intendPath (assuming navigation is successful)
        setIntendedPath('/');
    }
    setLoading(false);
  };

//   // Function to handle logout
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
        console.log("Logout error:", error);
    }
    setUser(null);
    window.location.reload();
    // navigate('/atty-login');
    setLoading(false);
  };

  // Inactivity timer logic (optional)
  useEffect(() => {
    const handleInactivity = () => {
      logout();
    };

    let inactivityTimeout = setTimeout(handleInactivity, 15 * 60 * 1000); // 15 minutes

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(handleInactivity, 15 * 60 * 1000);
    };

    window.addEventListener('mousemove', resetInactivityTimeout);
    window.addEventListener('keypress', resetInactivityTimeout);

    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener('mousemove', resetInactivityTimeout);
      window.removeEventListener('keypress', resetInactivityTimeout);
    };
  }, [logout]);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, setIntendedPath }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => useContext(AuthContext);

export default AuthContext;
