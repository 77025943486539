import { createClient } from '@supabase/supabase-js';

// console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
// console.log('Supabase Anon Key:', process.env.REACT_APP_SUPABASE_ANON_KEY);
// console.log('Supabase CONFLICT URL:', process.env.REACT_APP_SUPABASE_URL_CONFLICT);
// console.log('Supabase Anon CONFLICT Key:', process.env.REACT_APP_SUPABASE_ANON_KEY_CONFLICT);

// export const supabaseConflictCheck = createClient(
//     'https://lkumzcvsjgaoxrsithqe.supabase.co',
//     process.env.REACT_APP_SUPABASE_ANON_KEY_CONFLICT
// );

// export const supabase = createClient(
//     'https://gsrgetkddjzhcvlzaocf.supabase.co',
//     process.env.REACT_APP_SUPABASE_ANON_KEY
// );

export const supabase = createClient(
    'https://gsrgetkddjzhcvlzaocf.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdzcmdldGtkZGp6aGN2bHphb2NmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYwNDgzMzAsImV4cCI6MjAyMTYyNDMzMH0.PANWaSsvYshjXx00H3AuSy4U5R3SSapq99D8Q_oygRU'
);

export const supabaseConflictCheck = createClient(
    'https://lkumzcvsjgaoxrsithqe.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxrdW16Y3Zzamdhb3hyc2l0aHFlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDgyMDA4NTcsImV4cCI6MjAyMzc3Njg1N30.puG_vEu7xMj4X9TD1IlKOODB2Vemf5ZMZofOQ1OnpLo'
);



// const supabaseUrl = 'https://lkumzcvsjgaoxrsithqe.supabase.co';
// const supabaseAnonKey = process.env.SUPABASE_ANON_KEY_CONFLICT;

// export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// const { createClient } = require('@supabase/supabase-js');

// const supabaseUrl = 'https://lkumzcvsjgaoxrsithqe.supabase.co';
// // Ensure this environment variable is correctly set in your environment
// // const supabaseAnonKey = process.env.SUPABASE_ANON_KEY_CONFLICT; 
// const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxrdW16Y3Zzamdhb3hyc2l0aHFlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDgyMDA4NTcsImV4cCI6MjAyMzc3Njg1N30.puG_vEu7xMj4X9TD1IlKOODB2Vemf5ZMZofOQ1OnpLo'

// const supabase = createClient(supabaseUrl, supabaseAnonKey);

// // module.exports = { supabase };
// export default supabase;

