// import React, { useState } from "react";
// import './App.css';
// import Header from './header.js';
// import RegisterTrademarkForm from './registerTrademarkForm.js';
// import FAQs from "./FAQs.js";
// import { Link } from 'react-router-dom';

// const HomePage = () => {
//   const [showForm, setShowForm] =   useState(false);

//   const toggleForm = () => {
//     setShowForm(!showForm);
//   };

//   const closeModal = (e) => {
//     if(e.target.className === 'modal') {
//       setShowForm(false);
//     }
//   };

//   return (
//       <div>
//         <Header />
//         <main>
//           <div className='comp'>
//             <span className='comp-name'>San Novus Trademark LLP</span>
//             <span className='comp-descrip'>Protecting Your Brand with Expertise and Precision</span>
//           </div>
//           {/* <p><button className='form-button' onClick={toggleForm}>Register A Trademark</button></p> */}
//           <p><button className='form-button' onClick={toggleForm}>File A Trademark Application</button></p>

//           <div className='main-section-alt'>
//             <h2 className='comp-name'><i>Our Services</i></h2>
//             <span className='comp-descrip'>Discover what sets our trademark and renewal services apart</span>
//             <div className='services-descrip'>
//               <div className='service-block'>
//                 <Link to='/trademark-searches'>
//                   <h3>Trademark Searching</h3>
//                   <p>Ensuring unique and defensible branding for your mark</p>
//                 </Link>
//               </div>
//               <div className='service-block'>
//                 <Link to='/trademark-registration-applications'>
//                   <h3>Trademark Applications</h3>
//                   <p>Comprehensive assistance with registration applications</p>
//                 </Link>
//               </div>
//               <div className="service-block">
//                 <Link to='/trademark-oar'>
//                   <h3>Office Actions</h3>
//                   <p>Expert responses to application rejections</p>
//                 </Link>
//               </div>
//               <div className="service-block">
//                 <Link to='/trademark-sou'>
//                   <h3>Statements of Use</h3>
//                   <p>Finalizing your registration with accurate usage declarations</p>
//                 </Link>
//               </div>
//               <div className="service-block">
//                 <Link to='/trademark-renewals'>
//                   <h3>Trademark Renewals</h3>
//                   <p>Renew your registered trademark for ongoing protection</p>
//                 </Link>
//               </div>
//               <div className="service-block">
//                 <Link to='/international-trademarks'>
//                   <h3>International Coverage</h3>
//                   <p>Extend your brand's reach with foreign trademark applications</p>
//                 </Link>
//               </div>
//               <div className="service-block">
//                 <Link to='/amazon-brand-registry'>
//                   <h3>Amazon Brand Registry</h3>
//                   <p>Protect your brand, empower your business</p>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='main-section'>
//             <h2 className='comp-name'><i>Your Trusted Partner for Trademark Services</i></h2>
//             <p>Our team of attorneys has decades of combined
//              experience navigating the U.S. Trademark Office to help individuals and business owners register their trademarks.</p>
//             <p>We are a law firm, not a filing service. Watch out for other “filing services” that will take your money and leave you to complete the process
//              on your own. We stay up to date with the latest business technologies and practices at the U.S. Patent and Trademark Office to make sure 
//              that our clients have the best representation possible. Our virtual model allows us to reduce costs and we pass those savings on to you. 
//              You can stay updated with our client centered portal that allows you to review your files directly at any time.</p>
//              <p><button className='form-button' onClick={toggleForm}>File A Trademark Application</button></p>
//           </div>
//           <div className='main-section-alt'>
//             <h2 className='comp-name'><i>Pricing</i></h2>
//             <p>Fixed rate billing guarantees no surprise charges. With our transparent pricing, you'll know your project budget every step of the way.</p>
//           </div>
//           <div className='main-section'>
//             <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
//             <FAQs tags="general" />
//             <p><button className='form-button' onClick={toggleForm}>File A Trademark Application</button></p>
//           </div>
//         {showForm && (
//           <div onClick={closeModal}>
//             <RegisterTrademarkForm toggleForm={toggleForm} />
//           </div>
//         )}
//         </main>
//       </div>
//     );
//   };
  
//   export default HomePage;

// HomePage.js
import React, { useState, useEffect } from "react";
import './App.css';
import RegisterTrademarkForm from './registerTrademarkForm.js';
import FAQs from "./FAQs.js";
import { Link } from 'react-router-dom';
import TestModal from './TestModal.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const HomePage = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
    // setShowForm((prev) => !prev);
  };

  // Effect to log state changes
  useEffect(() => {
    console.log("showForm Value: ", showForm);
}, [showForm]);

  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowForm(false);
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <main>
        <div className="container-fluid">
          {/* Company Info Section */}
          <div className="text-center mb-4">
            <h1 className="comp-name">San Novus Trademark LLP</h1>
            <p className="comp-descrip">Protecting Your Brand with Expertise and Precision</p>
            <button className="form-button" onClick={toggleForm}>File A Trademark Application</button>
          </div>

          {/* Our Services Section */}
          <div className="main-section-alt text-center p-4">
            <h2 className="comp-name"><i>Our Services</i></h2>
            <p className="comp-descrip">Discover what sets our trademark and renewal services apart</p>
            <div className="row">
              {/* Service Button Links */}
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-searches'>
                    <h3>Trademark Searching</h3>
                    <p>Ensuring unique and defensible branding for your mark</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-registration-applications'>
                    <h3>Trademark Applications</h3>
                    <p>Comprehensive assistance with registration applications</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-oar'>
                    <h3>Office Actions</h3>
                    <p>Expert responses to application rejections</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-sou'>
                    <h3>Statements of Use</h3>
                    <p>Finalizing your registration with accurate usage declarations</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-renewals'>
                    <h3>Trademark Renewals</h3>
                    <p>Renew your registered trademark for ongoing protection</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/international-trademarks'>
                    <h3>International Coverage</h3>
                    <p>Extend your brand's reach with foreign trademark applications</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3 mx-auto">
                <div className="service-block">
                  <Link to='/amazon-brand-registry'>
                    <h3>Amazon Brand Registry</h3>
                    <p>Protect your brand, empower your business</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Sections */}
          <div className="main-section my-4 text-center">
            <h2 className="comp-name"><i>Your Trusted Partner for Trademark Services</i></h2>
            <p>Our team of attorneys has decades of combined experience navigating the U.S. Trademark Office to help individuals and business owners register their trademarks.</p>
            <p>We are a law firm, not a filing service. Watch out for other “filing services” that will take your money and leave you to complete the process on your own. We stay up to date with the latest business technologies and practices to ensure our clients have the best representation possible.</p>
            <button className="form-button" onClick={toggleForm}>File A Trademark Application</button>
          </div>

          <div className="main-section-alt my-4 text-center">
            <h2 className="comp-name"><i>Pricing</i></h2>
            <p>Fixed rate billing guarantees no surprise charges. With our transparent pricing, you'll know your project budget every step of the way.</p>
          </div>

          {/* <div className="main-section my-4 text-center"> */}
          <div className="col-12 col-md-6 mb-3 mx-auto">
            <h2 className="comp-name"><i>Common Questions and Answers</i></h2>
            <FAQs tags="general" />
            <button className="form-button" onClick={toggleForm}>File A Trademark Application</button>
          </div>

          
        </div>
        {showForm && (
            <div className="test-modal" onClick={closeModal}>
              <RegisterTrademarkForm toggleForm={toggleForm} />
            </div>
          )}
      </main>
    </div>
  );
};

export default HomePage;

