import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SignatureModal = ({ show, handleClose, handleSave, name, setName, position, setPosition }) => {
    const handleSaveClick = () => {
        handleSave(name, position);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Apply Electronic Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your name" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                        />
                    </Form.Group>
                    <Form.Group controlId="formPosition">
                        <Form.Label>Position</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your position" 
                            value={position} 
                            onChange={(e) => setPosition(e.target.value)} 
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveClick}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SignatureModal;
