import React, { useState } from 'react';
import Select from 'react-select';

const useType = [
    {name: "Not currently in use", abbreviation: "itu"},
    {name: "Mark in use on website", abbreviation: "web"},
    {name: "Mark used on product/packaging", abbreviation: "use"},
    {name: "Based on a foreign application", abbreviation: "foreignApp"},
    {name: "Based on a foreign registration", abbreviation: "foreignReg"},
  ];

  const options = useType.map(use => ({
    value: use.abbreviation,
    label: use.name,
  }));

  const UseSelection = ({ value, onChange, excludeOptions = [] }) => {
    const filteredOptions = options.filter(option => !excludeOptions.includes(option.value));

    const selectedOption = filteredOptions.find(option => option.value === value)

    const handleChange = (selectedOption) => {
        onChange(selectedOption ? selectedOption.value : '');
    };

    const customStyles = {
      control: (styles) => ({
        ...styles,
        Height: '38px',
        width:'100%',
        minWidth:'14rem',
        maxWidth: '20rem',
        backgroundColor: 'white',
        fontSize: '1.2rem',
      }),
      singleValue: (styles) => ({...styles,
      color: 'black',
      }),
      valueContainer: (styles) => ({
        ...styles,
        height: '30px',
        padding: '0 5px',
        outerWidth: '250x'

      }),
      input: (styles) => ({
        ...styles,
        margin: '0px',
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        height: '30px',
      }),
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? 'lightgray' : isSelected ? 'gray' : null,
          color: 'black',
        };
      },
      placeholder: (styles) => ({
        ...styles,
        color: 'black',
      }),
    };

    return (
        <Select
          styles={customStyles}    
          options={filteredOptions}
          onChange={handleChange}
          value={selectedOption}
          isSearchable={true}
          name='useSelectionBox'
          id='useSelectionBox'
          className='useSelection'
        />
    );

};
  
  export default UseSelection;