import React, { useState, useEffect } from 'react';
import StateCombobox from './stateCB.js';
import CountryCombobox from './countryCB.js';
import Contact from './Contact2.png';
import { escape } from 'lodash';

const ContactContact = ({ formData, onFormDataChange, onValidationChange, attemptedSubmit }) => {
    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [isCountryDisabled, setIsCountryDisabled] = useState(false);
    const [isFormValid, setIsFormValid] = useState('false');

    const [fieldValidity, setFieldValidity] = useState({
        contactFirstName: true,
        contactLastName: true,
        contactCompany: true,
        contactAddress1: true,
        contactCity: true,
        contactZip: true,
        contactState: true, // Only necessary if country === United States
        contactCountry: true,
        contactEmail: true,
    });

    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    useEffect(() => {
        // Validate form data whenever there's a change
        const isValid = validateForm();
        setIsFormValid(isValid);
        onValidationChange(isValid);
    }, [formData, selectedState, selectedCountry]);

    // Adjusted validateForm function
    const validateForm = () => {
        const newFieldValidity = {
            contactFirstName: !!formData.contactFirstName || !!formData.contactCompany,
            contactLastName: !!formData.contactLastName || !!formData.contactCompany,
            contactCompany: !!formData.contactCompany || (!!formData.contactFirstName && !!formData.contactLastName),
            contactAddress1: !!formData.contactAddress1,
            contactCity: !!formData.contactCity,
            contactZip: !!formData.contactZip,
            contactState: !!formData.contactState || !!formData.contactCountry,
            contactCountry: !!formData.contactCountry || !!formData.contactState,
            contactEmail: !!formData.contactEmail && emailRegex.test(formData.contactEmail),
        };

        setFieldValidity(newFieldValidity);

        // Determine overall form validity based on individual fields
        const isValid = Object.values(newFieldValidity).every(Boolean);
        onValidationChange(isValid);
        
        return isValid; // Return the validity status
    };

    const sanitizeEmail = (email) => {
        return emailRegex.test(email) ? email : ''; // Return empty string or show an error if invalid
    }

    const sanitizeInput = (value, type = 'text') => {
        let sanitizedValue = value;
        switch(type) {
          case 'email':
            sanitizedValue = value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? value : '';
            break;
          case 'text':
          default:
            sanitizedValue = escape(value.trim());
        }
        return sanitizedValue;
      }
      
    
    const handleInputChange = (event) => {
        let { id, value } = event.target;

        // value = escape(value.trim());

        if(id ==='contactEmail'){
            value = sanitizeEmail(value.trim() );
        }

        // const inputType = id === 'contactEmail' ? 'email' : 'text';
        // value = sanitizeInput(value.trim(), inputType);

        onFormDataChange({ [id]: value });
    };

    const handleSelectChange = (selectedOption, id) => {
        if(selectedOption) {
            onFormDataChange({ [id]: selectedOption.value });
        } else {
            onFormDataChange({ [id]: '' })
        }
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption ? selectedOption.value : '');
        // Automatically set the country to "United States" when a state is selected
        if (selectedOption) {
            setSelectedCountry("United States"); // Directly using the full name
            onFormDataChange({
                ...formData,
                contactState: selectedOption.value,
                contactCountry: "United States" // Ensure formData uses the full name
            });
        } else {
            onFormDataChange({
                ...formData,
                contactState: '',
                contactCountry: '' // Reset country if no state is selected
            });
        }
    };

    const handleCountryChange = (selectedOption) => {
        // setSelectedCountry(selectedOption ? selectedOption.value : '');
        setSelectedCountry(selectedOption ? selectedOption.label : '');
        // Reset the state selection if the selected country is not "USX"
        if (selectedOption && selectedOption.value !== "USX") {
            setSelectedState('');
            // onFormDataChange({ contactCountry: selectedOption.value, contactState: '' });
            onFormDataChange({ contactCountry: selectedOption.label, contactState: '' });
        } else {
            // onFormDataChange({ contactCountry: selectedOption.value });
            onFormDataChange({ contactCountry: selectedOption.label });
        }
    };
   
    return (
        <div className='container sub-modal'>
            <div className='form-background-container'>
                <div className='col-12 px-0 form-content'>
                    {attemptedSubmit && !isFormValid && (
                        <div className='alert alert-danger'>
                            Please complete the required fields before proceeding:
                        </div>
                    )}
                    <form>
                        <div className="row contactName-container">
                            <div className="col-sm-4 px-custom name-group">
                                <label htmlFor='contactFirstName'>First Name*</label>
                                <input 
                                    type='text' 
                                    id='contactFirstName' 
                                    className='form-control' 
                                    defaultValue={formData.contactFirstName} 
                                    onChange={handleInputChange} 
                                    maxLength={50}
                                />
                            </div>
                            <div className="col-sm-4 px-custom name-group">
                                <label htmlFor='contactMiddleName'>Middle Name</label>
                                <input 
                                    type='text' 
                                    id='contactMiddleName' 
                                    className='form-control' 
                                    defaultValue={formData.contactMiddleName} 
                                    onChange={handleInputChange} 
                                    maxLength={50}
                                />
                            </div>
                            <div className="col-sm-4 px-custom name-group">
                                <label htmlFor='contactLastName'>Last Name*</label>
                                <input 
                                    type='text' 
                                    id='contactLastName' 
                                    className='form-control' 
                                    defaultValue={formData.contactLastName} 
                                    onChange={handleInputChange} 
                                    maxLength={50}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 px-custom address-group">
                                <label htmlFor='contactCompany'>Company Name</label>
                                <input 
                                    type='text' 
                                    id='contactCompany' 
                                    className='form-control' 
                                    defaultValue={formData.contactCompany} 
                                    onChange={handleInputChange} 
                                    maxLength={65}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 px-custom col-md-6 address-group">
                                <label htmlFor='contactAddress1'>Address 1*</label>
                                <input 
                                    type='text' 
                                    id='contactAddress1' 
                                    className='form-control' 
                                    defaultValue={formData.contactAddress1} 
                                    onChange={handleInputChange} 
                                    maxLength={65}
                                />
                            </div>
                            <div className="col-12 px-custom col-md-6 address-group">
                                <label htmlFor='contactAddress2'>Address 2</label>
                                <input 
                                    type='text' 
                                    id='contactAddress2' 
                                    className='form-control' 
                                    defaultValue={formData.contactAddress2} 
                                    onChange={handleInputChange} 
                                    maxLength={65}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3 px-custom city-group">
                                <label htmlFor='contactCity'>City*</label>
                                <input 
                                    type='text' 
                                    id='contactCity' 
                                    className='form-control' 
                                    defaultValue={formData.contactCity} 
                                    onChange={handleInputChange} 
                                    maxLength={50}
                                />
                            </div>
                            <div className="col-3 px-custom city-group">
                                <label htmlFor='contactState'>State</label>
                                <StateCombobox 
                                    className='form-control' 
                                    id='contactState' 
                                    value={formData.contactState} 
                                    onChange={handleStateChange} 
                                    isValid={fieldValidity.contactState}
                                    attemptedSubmit={attemptedSubmit}
                                />
                            </div>
                            <div className="col-3 px-custom city-group">
                                <label htmlFor='contactZip'>Zip Code*</label>
                                <input 
                                    type='text' 
                                    id='contactZip' 
                                    className='form-control' 
                                    defaultValue={formData.contactZip} 
                                    onChange={handleInputChange} 
                                    maxLength={15}
                                />
                            </div>
                            <div className="col-3 px-custom city-group">
                                <label htmlFor='contactCountry'>Country*</label>
                                <CountryCombobox 
                                    className='form-control' 
                                    id='contactCountry' 
                                    // value={selectedCountry} 
                                    value={formData.contactCountry}
                                    onChange={handleCountryChange} 
                                    isValid={fieldValidity.contactCountry}
                                    attemptedSubmit={attemptedSubmit}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 px-custom address-group">
                                <label htmlFor='contactEmail'>Email Address*</label>
                                <input 
                                    type='text' 
                                    id='contactEmail' 
                                    className='form-control' 
                                    defaultValue={formData.contactEmail} 
                                    onChange={handleInputChange} 
                                    maxLength={50}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-4 px-custom name-group">
                                <label htmlFor='refNo'>Reference/Internal Tracking Number</label>
                                <input 
                                    type='text' 
                                    id='refNo' 
                                    className='form-control' 
                                    defaultValue={formData.refNo} 
                                    onChange={handleInputChange}
                                    maxLength={35} 
                                    />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
    
};

export default ContactContact;

       