// import React, { useState } from 'react';
// import Loading from './loading.gif';
// import LoadingGIF from './loadingGIF.gif';
// import Submission from './submission.png';

// const statusMessages = {
//     conflictCheck: 'Performing Conflict Check',
//     createContactProfile: 'Creating Contact Profile',
//     createNewMatter: 'Creating New Matter',
//     uploadingDocuments: 'Uploading Documents',
//     submissionComplete: 'Submission Complete!',
// };

// const SubmissionStatus = ({ status, closeSubmission }) => {
//   const renderStatusIcon = (stepStatus) => {
//     switch (stepStatus) {
//         case 'inProcess':
//             return <img src={LoadingGIF} alt='Loading...' className='loading'/>;
//         case 'complete':
//             return <span style={{ color: 'green' }}>✔</span>;
//         default:
//             return null;
//     }
//   };

//   if (!status) {
//     return <div>Loading submission...</div>;
//   }

//   const filteredStatusKeys = Object.keys(status).filter(key => {
//     if(key === 'uploadingDocuments' && (status.uploadingDocuments === 'inProcess' || status.uploadingDocuments === 'complete')) {
//         return true;
//     }

//     if(key === 'createContactProfile' && (status.createContactProfile === 'inProcess' || status.createContactProfile === 'complete')) {
//         return true;
//     }

//     if(key === 'conflictCheck') {
//         return status[key] !== 'conflictFound'; // Only return true if conflictCheck is not 'conflictFound'
//     }

//     if(key === 'createNewMatter' && (status.createNewMatter === 'inProcess' || status.createNewMatter === 'complete')) {
//         return true;
//     }

//     if(key === 'submissionComplete' && status.submissionComplete === 'complete') {
//         return true;
//     }
    
//   });
    

//   return (
//     <div className='submission-form-container'>
//         <div className='submission-form-content'>
//             <ul>
//                 {status.conflictCheck === 'conflictFound' && (
//                     <div style={{color:'red'}}>Uh oh! It looks like we have a potential conflict on our end with your proposed mark. We 
//                         will look into this further on our end and contact you via email once we have had a chance to look at this 
//                         in more detail.
//                     </div>
//                 )}
//                 {filteredStatusKeys.map((key) => (
//                     <li key={key}>
//                         {renderStatusIcon(status[key])} 
//                         {status[key] !== 'conflictFound' ? statusMessages[key] : ''}
//                     </li>
//                 ))}
//             </ul>
            
//             <div >
//                 <img src={Submission} alt='Submission Form Decorative Image' className='submission-form-image' />
//             </div>
//         </div>
        
//         {(status.submissionComplete === 'complete' || status.conflictCheck === 'conflictFound') && (
//             <div style={{marginTop:'5px'}}>
//                 <button className='close-submit-button' onClick={closeSubmission}>Close</button>
//             </div>
//         )}
//     </div>
//   );
// };

// export default SubmissionStatus;

import React from 'react';
import LoadingGIF from './loadingGIF.gif';
import Submission from './submission.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const statusMessages = {
    conflictCheck: 'Performing Conflict Check',
    createContactProfile: 'Creating Contact Profile',
    createNewMatter: 'Creating New Matter',
    uploadingDocuments: 'Uploading Documents',
    submissionComplete: 'Submission Complete!',
};

const SubmissionStatus = ({ status, closeSubmission, engagementLetterUrl }) => {
  const renderStatusIcon = (stepStatus) => {
    switch (stepStatus) {
        case 'inProcess':
            return <img src={LoadingGIF} alt='Loading...' className='loading'/>;
        case 'complete':
            return <span className="text-success">✔</span>;
        default:
            return null;
    }
  };

  if (!status) {
    return <div>Loading submission...</div>;
  }

  const filteredStatusKeys = Object.keys(status).filter(key => {
    if(key === 'uploadingDocuments' && (status.uploadingDocuments === 'inProcess' || status.uploadingDocuments === 'complete')) {
        return true;
    }
    if(key === 'createContactProfile' && (status.createContactProfile === 'inProcess' || status.createContactProfile === 'complete')) {
        return true;
    }
    if(key === 'conflictCheck') {
        return status[key] !== 'conflictFound';
    }
    if(key === 'createNewMatter' && (status.createNewMatter === 'inProcess' || status.createNewMatter === 'complete')) {
        return true;
    }
    if(key === 'submissionComplete' && status.submissionComplete === 'complete') {
        return true;
    }
    return false;
  });

  return (
    <div className="container my-4 transparent-background">
      <div className="card transparent-background">
        <div className="card-body transparent-background">
          <div className="row">
            <div className="col-md-8">
              <ul className="list-group transparent-background">
                {status.conflictCheck === 'conflictFound' && (
                  <div className="alert alert-danger transparent-background" role="alert">
                    Uh oh! It looks like we have a potential conflict on our end with your proposed mark. We will look into this further on our end and contact you via email once we have had a chance to look at this in more detail.
                  </div>
                )}
                {filteredStatusKeys.map((key) => (
                  <li key={key} className="list-group-item d-flex align-items-center transparent-background">
                    {renderStatusIcon(status[key])}
                    <span className="ms-2">
                      {status[key] !== 'conflictFound' ? statusMessages[key] : ''}
                    </span>
                  </li>
                ))}
              </ul>
              {(status.submissionComplete === 'complete' || status.conflictCheck === 'conflictFound') && (
                <div>
                    <div className="text-center mt-3 download-container">
                        {/* <span className="download-link" onClick={() => window.open(engagementLetterUrl, '_blank')}>
                        Click here to download your engagement letter
                        </span> */}
                        <span>Click </span> <span className="download-link" onClick={() => window.open(engagementLetterUrl, '_blank')}>here</span><span> to 
                            download a signed copy of your engagement letter</span>
                    </div>
                    <div className="text-center mt-3">
                    <button className="btn btn-primary" onClick={closeSubmission}>Close</button>
                    </div>
                </div>
              )}
            </div>
            <div className="col-md-4 d-none d-md-block">
              <img src={Submission} alt='Submission Form Decorative Image' className='img-fluid submission-form-image' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionStatus;
