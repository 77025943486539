import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";
import RegisterTrademarkForm from "./registerTrademarkForm.js";
import Footer from "./footer.js";

const TrademarkSearchesPage = () => {
  const [showForm, setShowForm] =   useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if(e.target.className === 'modal') {
      setShowForm(false);
    }
  };    
  return (
      <div>
        <main>
          <div className='comp'>
            <span className='comp-name'>Trademark Searches</span>
            <span className='comp-descrip'>Ensuring Your Mark's Uniqueness and Registrability</span>
          </div>
          <div className='main-section-alt'>
            <p>Wondering if your chosen mark can be federally registered? San Novus Trademark is here to assist.
              Our initial step is a Knockout Search to identify any identical existing trademarks. This search is crucial prior to filing your application,
              providing a valuable preliminary assessment of your mark's registrability.</p>
          </div>
          <div className='main-section'>
            <h3>Try it yourself:</h3>
            <p>You can conduct a basic search with the U.S. Trademark Office using the powerful <a href='https://tmsearch.uspto.gov/search/search-information' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">Trademark Search tool</a>. Simply follow the link and input
              your mark into the search bar to explore existing trademarks. The Trademark Search tool uses regular expressions to efficiently comb through
              the federal trademark database. The provided help page is extremely useful if you’re not familiar with the system.<br /></p>
            <h2>For a More In-Depth Search:</h2>
            <p>Consider broadening the scope of your search to include similarly spelled marks. Instead of typing your exact mark in the search bar,
              tell the search tool to look for variations of the mark. You can do this by using the following format:<br /></p>
            <p>CM:/[search term]/<br /></p>
            <p>Replace any vowels (or letters that might be substituted) in the search term with similar letters in between square brackets. For example,
              instead of searching for:<br /></p>
            <p>GOOGLE<br /></p>
            <p>try searching for: (note that the first two letters MUST be in CAPS)<br /></p>
            <p>CM:/g[ou][ou]gl[ei]/<br /></p>
            <p>This would tell the search tool to look for the following combinations:<br /></p>
            <p>GOOGLE</p>
            <p>GOOGLI</p>
            <p>GOUGLE</p>
            <p>GOUGLI</p>
            <p>GUOGLE</p>
            <p>GUOGLI</p>
            <p>GUUGLE</p>
            <p>GUUGLI</p>
            {/* <ul>
              <li>GOOGLE</li>
              <li>GOOGLI</li>
              <li>GOUGLE</li>
              <li>GOUGLI</li>
              <li>GUOGLE</li>
              <li>GUOGLI</li>
              <li>GUUGLE</li>
              <li>GUUGLI</li>
            </ul> */}
            <p>Please remember that DIY searches have limitations. A comprehensive trademark search requires expertise and an
              understanding of the nuances of trademark law.</p>
          </div>
          <div className='main-section-alt'>
            <h3>Professional Trademark Search Services</h3>
            <p>Our team of experts is skilled in conducting thorough and effective trademark searches. We ensure a comprehensive 
                review, significantly reducing the risk of conflicts. This is included as a part of our services to you.</p>
          </div>
          {/* <div className='main-section'>
            <p><b>Need expert help with your trademark search?</b> Click <span onClick={toggleForm} style={{cursor:'pointer', color:'blue'}}>here
              </span> to submit an inquiry! Have questions first? Contact us at <a href='info@sannovustrademark.com' style={{color:'blue'}}>info@sannovustrademark.com</a> 
              for professional guidance and peace of mind.<br /></p>
          </div> */}
          {showForm && (
              <div onClick={closeModal}>
                <RegisterTrademarkForm />
              </div>
            )}
            <div className='main-section'>
              <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
              <FAQs tags="search" />
            </div>
            
        </main>
      </div>
    );
  };
  
  export default TrademarkSearchesPage;