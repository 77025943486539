import React, { useContext } from 'react';
import AuthContext from './AuthContext.js'; // Adjust the import path as needed

const LogoutButton = () => {
    const { logout } = useContext(AuthContext);

    return (
        <button onClick={logout} className='logout-button'>Log out</button>
    );
};

export default LogoutButton;
