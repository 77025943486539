import React, { useState } from 'react';
import Select from 'react-select';
import { components } from 'react-select';

  const CountryCombobox = ({ id, value, onChange, isDisabled, excludedOptions = [], isValid, attemptedSubmit, margin }) => {
    const countries = [
      {name: "Select Country", abbreviation: ""},  
      {name: "United States", abbreviation: "USX"},
      // {name: "United States", abbreviation: "United States"},
      {name: "China", abbreviation: "CNX"},
      {name: "", abbreviation: "", isSeparator: true},
      {name: "Afghanistan", abbreviation: "AFX"},
      {name: "Albania", abbreviation: "ALX"},
      {name: "Algeria", abbreviation: "DZX"},
      {name: "American Samoa", abbreviation: "ASX"},
      {name: "Andorra", abbreviation: "ADX"},
      {name: "Angola", abbreviation: "AOX"},
      {name: "Anguilla", abbreviation: "AIX"},
      {name: "Antigua and Barbuda", abbreviation: "AGX"},
      {name: "Argentina", abbreviation: "ARX"},
      {name: "Armenia", abbreviation: "AMX"},
      {name: "Aruba", abbreviation: "AWX"},
      {name: "Australia", abbreviation: "AUX"},
      {name: "Austria", abbreviation: "ATX"},
      {name: "Azerbaijan", abbreviation: "AZX"},
      {name: "Bahamas", abbreviation: "BSX"},
      {name: "Bahrain", abbreviation: "BHX"},
      {name: "Bangladesh", abbreviation: "BDX"},
      {name: "Barbados", abbreviation: "BBX"},
      {name: "Belarus", abbreviation: "BYX"},
      {name: "Belgium", abbreviation: "BEX"},
      {name: "Belize", abbreviation: "BZX"},
      {name: "Benin", abbreviation: "BJX"},
      {name: "Bermuda", abbreviation: "BMX"},
      {name: "BES Islands", abbreviation: "BQX"},
      {name: "Bhutan", abbreviation: "BTX"},
      {name: "Bolivia", abbreviation: "BOX"},
      {name: "Bosnia and Herzegovina", abbreviation: "BAX"},
      {name: "Botswana", abbreviation: "BWX"},
      {name: "Bouvet Island", abbreviation: "BVX"},
      {name: "Brazil", abbreviation: "BRX"},
      {name: "British Indian Ocean Territory", abbreviation: "IOX"},
      {name: "British Virgin Islands", abbreviation: "VGX"},
      {name: "Brunei Darussalam", abbreviation: "BNX"},
      {name: "Bulgaria", abbreviation: "BGX"},
      {name: "Burkina Faso", abbreviation: "BFX"},
      {name: "Burundi", abbreviation: "BIX"},
      {name: "Côte d'Ivoire", abbreviation: "CIX"},
      {name: "Cambodia", abbreviation: "KHX"},
      {name: "Cameroon", abbreviation: "CMX"},
      {name: "Canada", abbreviation: "CAX"},
      {name: "Cabo Verde", abbreviation: "CVX"},
      {name: "Cayman Islands", abbreviation: "KYX"},
      {name: "Centralican Republic", abbreviation: "CFX"},
      {name: "Chad", abbreviation: "TDX"},
      {name: "Chile", abbreviation: "CLX"},
      {name: "Christmas Island", abbreviation: "CXX"},
      {name: "Cocos Islands", abbreviation: "CCX"},
      {name: "Colombia", abbreviation: "COX"},
      {name: "Comoros", abbreviation: "KMX"},
      {name: "Congo", abbreviation: "CGX"},
      {name: "Cook Islands", abbreviation: "CKX"},
      {name: "Costa Rica", abbreviation: "CRX"},
      {name: "Croatia", abbreviation: "HRX"},
      {name: "Cuba", abbreviation: "CUX"},
      {name: "Curacao", abbreviation: "CWX"},
      {name: "Cyprus", abbreviation: "CYX"},
      {name: "Czech Republic", abbreviation: "CZX"},
      {name: "Denmark", abbreviation: "DKX"},
      {name: "Djibouti", abbreviation: "DJX"},
      {name: "Dominica", abbreviation: "DMX"},
      {name: "Dominican Republic", abbreviation: "DOX"},
      {name: "Ecuador", abbreviation: "ECX"},
      {name: "Egypt", abbreviation: "EGX"},
      {name: "El Salvador", abbreviation: "SVX"},
      {name: "England", abbreviation: "GB2"},
      {name: "Equatorial Guinea", abbreviation: "GQX"},
      {name: "Eritrea", abbreviation: "ERX"},
      {name: "Estonia", abbreviation: "EEX"},
      {name: "Ethiopia", abbreviation: "ETX"},
      {name: "Falkland Islands", abbreviation: "FKX"},
      {name: "Faroe Islands", abbreviation: "FOX"},
      {name: "Fiji", abbreviation: "FJX"},
      {name: "Finland", abbreviation: "FIX"},
      {name: "France", abbreviation: "FRX"},
      {name: "French Guiana", abbreviation: "GFX"},
      {name: "French Polynesia", abbreviation: "PFX"},
      {name: "Gabon", abbreviation: "GAX"},
      {name: "Gambia", abbreviation: "GMX"},
      {name: "Georgia", abbreviation: "GEX"},
      {name: "Germany", abbreviation: "DEX"},
      {name: "Ghana", abbreviation: "GHX"},
      {name: "Gibraltar", abbreviation: "GIX"},
      {name: "Great Britain", abbreviation: "GB3"},
      {name: "Greece", abbreviation: "GRX"},
      {name: "Greenland", abbreviation: "GLX"},
      {name: "Grenada", abbreviation: "GDX"},
      {name: "Guadeloupe", abbreviation: "GPX"},
      {name: "Guam", abbreviation: "GUX"},
      {name: "Guatemala", abbreviation: "GTX"},
      {name: "Guernsey", abbreviation: "GGX"},
      {name: "Guinea", abbreviation: "GNX"},
      {name: "Guinea-Bissau", abbreviation: "GWX"},
      {name: "Guyana", abbreviation: "GYX"},
      {name: "Haiti", abbreviation: "HTX"},
      {name: "Heard Island and McDonald Islands", abbreviation: "HMX"},
      {name: "Honduras", abbreviation: "HNX"},
      {name: "Hong Kong", abbreviation: "HKX"},
      {name: "Hungary", abbreviation: "HUX"},
      {name: "Iceland", abbreviation: "ISX"},
      {name: "India", abbreviation: "INX"},
      {name: "Indonesia", abbreviation: "IDX"},
      {name: "Iran", abbreviation: "IRX"},
      {name: "Iraq", abbreviation: "IQX"},
      {name: "Ireland", abbreviation: "IEX"},
      {name: "Isle of Man", abbreviation: "IMX"},
      {name: "Israel", abbreviation: "ILX"},
      {name: "Italy", abbreviation: "ITX"},
      {name: "Jamaica", abbreviation: "JMX"},
      {name: "Japan", abbreviation: "JPX"},
      {name: "Jersey", abbreviation: "JEX"},
      {name: "Johnston Atoll", abbreviation: "JTX"},
      {name: "Jordan", abbreviation: "JOX"},
      {name: "Kazakhstan", abbreviation: "KZX"},
      {name: "Kenya", abbreviation: "KEX"},
      {name: "Kiribati", abbreviation: "KIX"},
      {name: "Korea, North", abbreviation: "KPX"},
      {name: "Korea, South", abbreviation: "KRX"},
      {name: "Kuwait", abbreviation: "KWX"},
      {name: "Kyrgyzstan", abbreviation: "KGX"},
      {name: "Laos", abbreviation: "LAX"},
      {name: "Latvia", abbreviation: "LVX"},
      {name: "Lebanon", abbreviation: "LBX"},
      {name: "Lesotho", abbreviation: "LSX"},
      {name: "Liberia", abbreviation: "LRX"},
      {name: "Libya", abbreviation: "LYX"},
      {name: "Liechtenstein", abbreviation: "LIX"},
      {name: "Lithuania", abbreviation: "LTX"},
      {name: "Luxembourg", abbreviation: "LUX"},
      {name: "Macau", abbreviation: "MOX"},
      {name: "Macedonia", abbreviation: "MKX"},
      {name: "Madagascar", abbreviation: "MGX"},
      {name: "Malawi", abbreviation: "MWX"},
      {name: "Malaysia", abbreviation: "MYX"},
      {name: "Maldives", abbreviation: "MVX"},
      {name: "Mali", abbreviation: "MLX"},
      {name: "Malta", abbreviation: "MTX"},
      {name: "Marshall Islands", abbreviation: "MHX"},
      {name: "Martinique", abbreviation: "MQX"},
      {name: "Mauritania", abbreviation: "MRX"},
      {name: "Mauritius", abbreviation: "MUX"},
      {name: "Mayotte", abbreviation: "YTX"},
      {name: "Mexico", abbreviation: "MXX"},
      {name: "Micronesia", abbreviation: "FMX"},
      {name: "Moldova", abbreviation: "MDX"},
      {name: "Monaco", abbreviation: "MCX"},
      {name: "Mongolia", abbreviation: "MNX"},
      {name: "Montserrat", abbreviation: "MSX"},
      {name: "Morocco", abbreviation: "MAX"},
      {name: "Montenegro", abbreviation: "MEX"},
      {name: "Mozambique", abbreviation: "MZX"},
      {name: "Myanmar", abbreviation: "MMX"},
      {name: "Namibia", abbreviation: "NAX"},
      {name: "Nauru", abbreviation: "NRX"},
      {name: "Navassa Island", abbreviation: "NVX"},
      {name: "Nepal", abbreviation: "NPX"},
      {name: "Netherlands", abbreviation: "NLX"},
      {name: "New Zealand", abbreviation: "NZX"},
      {name: "Nicaragua", abbreviation: "NIX"},
      {name: "Niger", abbreviation: "NEX"},
      {name: "Nigeria", abbreviation: "NGX"},
      {name: "Northern Ireland", abbreviation: "GB5"},
      {name: "Northern Mariana Islands", abbreviation: "MPX"},
      {name: "Norway", abbreviation: "NOX"},
      {name: "Oman", abbreviation: "OMX"},
      {name: "Pakistan", abbreviation: "PKX"},
      {name: "Palau", abbreviation: "PWX"},
      {name: "Palestinian Authority", abbreviation: "PXX"},
      {name: "Panama", abbreviation: "PAX"},
      {name: "Papua New Guinea", abbreviation: "PGX"},
      {name: "Paraguay", abbreviation: "PYX"},
      {name: "Peru", abbreviation: "PEX"},
      {name: "Philippines", abbreviation: "PHX"},
      {name: "Poland", abbreviation: "PLX"},
      {name: "Portugal", abbreviation: "PTX"},
      {name: "Puerto Rico", abbreviation: "PRX"},
      {name: "Qatar", abbreviation: "QAX"},
      {name: "Romania", abbreviation: "ROX"},
      {name: "Russia", abbreviation: "RUX"},
      {name: "Rwanda", abbreviation: "RWX"},
      {name: "Saint Kitts and Nevis", abbreviation: "KNX"},
      {name: "Saint Lucia", abbreviation: "LCX"},
      {name: "Saint Vincent and Grenadines", abbreviation: "VCX"},
      {name: "Samoa", abbreviation: "OOX"},
      {name: "San Marino", abbreviation: "SMX"},
      {name: "Sao Tome and Principe", abbreviation: "STX"},
      {name: "Saudi Arabia", abbreviation: "SAX"},
      {name: "Scotland", abbreviation: "GB6"},
      {name: "Senegal", abbreviation: "SNX"},
      {name: "Serbia", abbreviation: "RSX"},
      {name: "Seychelles", abbreviation: "SCX"},
      {name: "Sierra Leone", abbreviation: "SLX"},
      {name: "Singapore", abbreviation: "SGX"},
      {name: "Slovakia", abbreviation: "SKX"},
      {name: "Slovenia", abbreviation: "SIX"},
      {name: "Solomon Islands", abbreviation: "SBX"},
      {name: "Somalia", abbreviation: "SOX"},
      {name: "South Africa", abbreviation: "ZAX"},
      {name: "Spain", abbreviation: "ESX"},
      {name: "Sri Lanka", abbreviation: "LKX"},
      {name: "St. Maarten", abbreviation: "SXX"},
      {name: "Sudan", abbreviation: "SDX"},
      {name: "Suriname", abbreviation: "SRX"},
      {name: "Swaziland", abbreviation: "SZX"},
      {name: "Sweden", abbreviation: "SEX"},
      {name: "Switzerland", abbreviation: "CHX"},
      {name: "Syria", abbreviation: "SYX"},
      {name: "Taiwan", abbreviation: "TWX"},
      {name: "Tajikistan", abbreviation: "TJX"},
      {name: "Tanzania", abbreviation: "TZX"},
      {name: "Thailand", abbreviation: "THX"},
      {name: "Timor-Leste", abbreviation: "TLX"},
      {name: "Togo", abbreviation: "TGX"},
      {name: "Tonga", abbreviation: "TOX"},
      {name: "Trinidad and Tobago", abbreviation: "TTX"},
      {name: "Tunisia", abbreviation: "TNX"},
      {name: "Turkey", abbreviation: "TRX"},
      {name: "Turkmenistan", abbreviation: "TMX"},
      {name: "Turks/Caicos Islands", abbreviation: "TCX"},
      {name: "Tuvalu", abbreviation: "TVX"},
      {name: "Uganda", abbreviation: "UGX"},
      {name: "Ukraine", abbreviation: "UAX"},
      {name: "United Arab Emirates", abbreviation: "AEX"},
      {name: "United Kingdom", abbreviation: "GBX"},
      {name: "United States", abbreviation: "USA"},
      {name: "Uruguay", abbreviation: "UYX"},
      {name: "US Virgin Islands", abbreviation: "VIX"},
      {name: "Uzbekistan", abbreviation: "UZX"},
      {name: "Vanuatu", abbreviation: "VUX"},
      {name: "Vatican City", abbreviation: "VAX"},
      {name: "Venezuela", abbreviation: "VEX"},
      {name: "Vietnam", abbreviation: "VNX"},
      {name: "Wake Island", abbreviation: "WKX"},
      {name: "Wales", abbreviation: "GB7"},
      {name: "Wallis and Futuna", abbreviation: "WFX"},
      {name: "Western Sahara", abbreviation: "EHX"},
      {name: "Yemen", abbreviation: "YEX"},
      {name: "Zambia", abbreviation: "ZMX"},
      {name: "Zimbabwe", abbreviation: "ZWX"},
      {name: "Stateless", abbreviation: "ZZX"},
    ];
  
    // Filter options based on the excludedOptions prop
    const options = countries.filter(country => 
        !excludedOptions.includes(country.abbreviation)
    ).map(country => ({
      value: country.abbreviation,
      label: country.name,
      isSeparator: country.isSeparator
    }));
  
     const CustomOption = (props) => {
        // Check if the option is a separator
        if (props.data.isSeparator) {
          return (
            <div style={{ margin: '10px 0', borderTop: '1px solid #ccc', pointerEvents: 'none' }}></div>
          );
        }
        // Apply bold styling conditionally
        const isBold = props.data.label.includes('United') || props.data.label.includes('China');
        
        return (
          <components.Option {...props}>
            <div style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
              {props.data.label}
            </div>
          </components.Option>
          );
      };
    
    const MenuList = (props) => {
      return (
        <components.MenuList {...props}>
          {props.children.map(child => {
            // Check if the current child is a separator and apply custom rendering
            if (child.props.data.isSeparator) {
              return (
                <div key="separator" style={{ margin: '10px 0', borderTop: '5px solid #ccc' }} />
              );
            }
            return child;
          })}
        </components.MenuList>
      );
    };

    // const selectedOption = options.find(option => option.value === value)
    const selectedOption = value ? options.find(option => option.label === value) : null;

    const handleChange = (selectedOption) => {
        // onChange(selectedOption, id);
        // onChange({ value: selectedOption.value, label: selectedOption.label }, id);
        onChange({ value: selectedOption.label, label: selectedOption.label }, id);
    };

    const customStyles = {
      control: (styles) => ({
        ...styles,
        height: '38px',
        minHeight: '38px',
        width: '225px',
        borderColor: attemptedSubmit && !isValid ? 'red' : 'defaultBorderColor',
        borderWidth: attemptedSubmit && !isValid ? '1.5px' : '1px',
        backgroundColor: 'white',
        fontSize: '1.2rem',
        marginLeft: margin,
      }),
      singleValue: (styles) => ({...styles,
      color: 'black',
      }),
      valueContainer: (styles) => ({
        ...styles,
        height: '38px',
        padding: '0 6px',
        outerWidth: '150px'

      }),
      input: (styles) => ({
        ...styles,
        margin: '0px',
        padding: '0px',
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        height: '30px',
      }),
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? 'lightgray' : isSelected ? 'gray' : null,
          color: 'black',
        };
      },
      placeholder: (styles) => ({
        ...styles,
        color: 'black',
      }),
    };

   return (
        <Select
          styles={customStyles}  
          options={options}
          onChange={handleChange}
          value={selectedOption}
          placeholder="Select Country"
          isSearchable={true}
          components={{ Option: CustomOption, MenuList }}
          isOptionDisabled={(option) => option.isSeparator}
          name='countryCB'
          id='countryCB'
          isDisabled={isDisabled}
        />
    );
};
  
  export default CountryCombobox;