// import React, { useState } from "react";
// import './App.css';
// import Header from './header.js';
// import FAQs from "./FAQs.js";
// import RegisterTrademarkForm from "./registerTrademarkForm.js";
// import Footer from "./footer.js";
// import HelpGuide from "./useHelpGuide.js";

// const TrademarkRegistrationApplications = () => {
//   const [showForm, setShowForm] =   useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);

//   const toggleForm = () => {
//     setShowForm(!showForm);
//   };

//   const closeModal = (e) => {
//     if(e.target.className === 'modal') {
//       setShowForm(false);
//     }
//   };    
  
//   const toggleHelpGuide = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const ImageEnlargeView = ({ src, onClose }) => {
//     if (!src) return null;
  
//     return (
//       <div className="image-enlarge-modal" onClick={onClose}>
//         <img src={src} alt="Enlarged view" style={{ maxWidth: '90%', maxHeight: '80vh' }} />
//       </div>
//     );
//   };

//   return (
//       <div>
//           {/* <Header /> */}
  
//         <main>
//           <div className='comp'>
//             <span className='comp-name'>Trademark Registration Applications</span>
//             <span className='comp-descrip'>Ready to Secure Your Brand? Let Us Guide You Through the Process</span>
//           </div>
//           <p><button className='form-button' onClick={toggleForm}>File A Trademark Application</button></p>
//           <div className='main-section'>
//             <p><b>Starting your federal trademark application can be complex, but San Novus Trademark is here to simplify it for you. </b> 
//               We’ll begin by gathering some basic information from you with our easy-to-use intake form. If you have the following details ready, 
//               completing the questionnaire should only take about 5 minutes.</p>
//           </div>
//           <div className='main-section-alt' style={{textAlign:'left'}}>
//             <ol>
//               <li><b><u>Contact Person:</u></b> Who will we contact about the trademark application? Invoices will be addressed to this person.</li>
//               <li><b><u>Trademark Owner:</u></b> Who will be the legal owner of the trademark?</li>
//               <li><b><u>Type of Mark:</u></b> Is it a word mark (text only, no design elements),
//                 a design logo (a piece of 2-D artwork), or a sound mark?</li>
//               <li><b><u>The Mark Itself:</u></b> What is the exact mark you wish to register?
//                 We'll need you to provide the text of the word mark, an image file of the design logo, or a recording of the sound mark.</li>
//               <li><b><u>Related Goods/Services:</u></b> What goods and/or services are you offering in connection with the mark?</li>
//               <li><b><u>Usage of the Mark:</u></b> Are you currently using the mark to sell your goods and/or services?
//                 <ol type='a'>
//                   <li >If yes, when did you first start using the mark commercially?</li>
//                   <li>Can you provide evidence of commercial use? Click 
//                     <a href='#' onClick={toggleHelpGuide}>here</a> 
//                     or check out our <a href='/FAQ'>FAQ</a> section for accepted evidence types.</li>
//                   <li>Not using the mark quite yet? That's no problem! We can file the application now and submit the evidence of use later.
//                     Evidence of commercial use will eventually be required to complete the registration process, but we can secure your rights
//                     while you get everything ready.
//                   </li>
//                 </ol>
//               </li>
//             </ol>
//           </div>
//           <div className='main-section'>
//             <p>After we file your federal trademark application, <b>San Novus Trademark</b> will monitor the status of your application with the U.S. Patent and Trademark Office and 
//               keep you apprised of updates. You'll be able to track the status of your application through our client portal and stay up to date through every step of the process.</p>
//           </div>
//           <button className='form-button' onClick={toggleForm}>File A Trademark Application</button>

//           <div className='main-section'>
//             <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
//             <FAQs tags="application, usage" />
//           </div>
//           <HelpGuide 
//             isOpen={isModalOpen} 
//             onClose={toggleHelpGuide} 
//             toggleHelpGuide={toggleHelpGuide}
//             selectedImage={selectedImage}
//             setSelectedImage={setSelectedImage}
//           />
//           <ImageEnlargeView src={selectedImage} onClose={() => setSelectedImage(null)} />  
//           {showForm && (
//             <div onClick={closeModal}>
//               <RegisterTrademarkForm toggleForm={toggleForm} />
//             </div>
//           )}
//         </main>
//       </div>
//     );
//   };
  
//   export default TrademarkRegistrationApplications;

import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";
import RegisterTrademarkForm from "./registerTrademarkForm.js";
import Footer from "./footer.js";
import HelpGuide from "./useHelpGuide.js";

const TrademarkRegistrationApplications = () => {
  const [showForm, setShowForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowForm(false);
    }
  };

  const toggleHelpGuide = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ImageEnlargeView = ({ src, onClose }) => {
    if (!src) return null;

    return (
      <div className="image-enlarge-modal" onClick={onClose}>
        <img src={src} alt="Enlarged view" style={{ maxWidth: '90%', maxHeight: '80vh' }} />
      </div>
    );
  };

  return (
    <div>
      <main>
        <div className='comp'>
          <span className='comp-name'>Trademark Registration Applications</span>
          <span className='comp-descrip'>Ready to Secure Your Brand? Let Us Guide You Through the Process</span>
        </div>
        <p><button className='form-button' onClick={toggleForm}>File A Trademark Application</button></p>
        <div className='main-section'>
          <p><b>Starting your federal trademark application can be complex, but San Novus Trademark is here to simplify it for you.</b>
            We’ll begin by gathering some basic information from you with our easy-to-use intake form. If you have the following details ready,
            completing the questionnaire should only take about 5 minutes.</p>
        </div>
        <div className='main-section-alt' style={{ textAlign: 'left' }}>
          <ol>
            <li><b><u>Contact Person:</u></b> Who will we contact about the trademark application? Invoices will be addressed to this person.</li>
            <li><b><u>Trademark Owner:</u></b> Who will be the legal owner of the trademark?</li>
            <li><b><u>Type of Mark:</u></b> Is it a word mark (text only, no design elements),
              a design logo (a piece of 2-D artwork), or a sound mark?</li>
            <li><b><u>The Mark Itself:</u></b> What is the exact mark you wish to register?
              We'll need you to provide the text of the word mark, an image file of the design logo, or a recording of the sound mark.</li>
            <li><b><u>Related Goods/Services:</u></b> What goods and/or services are you offering in connection with the mark?</li>
            <li><b><u>Usage of the Mark:</u></b> Are you currently using the mark to sell your goods and/or services?
              <ol type='a'>
                <li>If yes, when did you first start using the mark commercially?</li>
                <li>Can you provide evidence of commercial use? Click <a href='#' onClick={toggleHelpGuide}>here</a> or check out 
                  our <a href='/FAQ#faq-9' target='_blank' rel='noopener noreferrer'>FAQ</a> section for accepted evidence types.</li>
                <li>Not using the mark quite yet? That's no problem! We can file the application now and submit the evidence of use later.
                  Evidence of commercial use will eventually be required to complete the registration process, but we can secure your rights
                  while you get everything ready.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className='main-section'>
          <p>After we file your federal trademark application, <b>San Novus Trademark</b> will monitor the status of your application with the U.S. Patent and Trademark Office and
            keep you apprised of updates. You'll be able to track the status of your application through our client portal and stay up to date through every step of the process.</p>
        </div>
        <button className='form-button' onClick={toggleForm}>File A Trademark Application</button>

        <div className='main-section'>
          <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
          <FAQs tags="application, usage" />
        </div>
        <HelpGuide
          isOpen={isModalOpen}
          onClose={toggleHelpGuide}
          toggleHelpGuide={toggleHelpGuide}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
        <ImageEnlargeView src={selectedImage} onClose={() => setSelectedImage(null)} />
        {showForm && (
          <div onClick={closeModal} className="modal">
            <RegisterTrademarkForm toggleForm={toggleForm} />
          </div>
        )}
      </main>
    </div>
  );
};

export default TrademarkRegistrationApplications;
