//clientPortalLogin.js
import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { Table, Modal, Button, Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import { json } from 'body-parser';
import { Document, Page } from 'react-pdf';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
        };
        checkMobile();

        // Optional: Listen for window resizing (if needed for responsiveness)
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    return isMobile;
};


const ClientPortalLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [step, setStep] = useState(1); // 1 for email/password input, 2 for OTP input
    const [errorMessage, setErrorMessage] = useState('');
    const [userData, setUserData] = useState(null);
    const [clientFiles, setClientFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const inputRefs = useRef([]);
    const [user, setUser] = useState('');
    const [userSession, setUserSession] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verifiedNewPassword, setVerifiedNewPassword] = useState('');
    const [resettingPassword, setResettingPassword] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [designLogos, setDesignLogos] = useState('');
    const [fileContent, setFileContent] = useState({ url: null, matterId: null });
    const [selectedDocUrl, setSelectedDocUrl] = useState(null);
    const [selectedDocName, setSelectedDocName] = useState(null);
    const [showDocumentViewerModal, setShowDocumentViewerModal] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [upcomingDeadlines, setUpcomingDeadlines] = useState({ deadlines: [], matterId: null });;
    
    const isMobileView = useIsMobile();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const navigationType = performance.getEntriesByType('navigation')[0].type;
            if (navigationType !== 'reload') {
                // Clear any existing authentication data from local storage
                localStorage.removeItem('sntmAuth');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Check for existing session on initial load
        const checkSession = async () => {
            const authData = localStorage.getItem('sntmAuth');
            if (authData) {
                console.log('Found sntmAuth cookie:', authData);
                const parsedData = JSON.parse(authData);

                const user = parsedData.data?.user;
                const session = parsedData.data?.session;

                if (session && session.access_token) {
                    const isValidSession = await verifySession(session.access_token);
                    if (isValidSession) {
                        setUserData(user);
                        setUser(user);
                        setUserSession(session);
                    } else {
                        localStorage.removeItem('sntmAuth');
                    }
                } else {
                    console.error('Session or access token is undefined');
                }
            } else {
                console.log('Did not find sntmAuth cookie!');
            }
        };

        checkSession();

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    // useEffect(() => {
    //     let isFetching = false;
    
    //     if (user && userSession && !dataFetched && !isFetching) {
    //         isFetching = true;
    //         console.log('Fetching client files! Current user info:', user);
    //         fetchClientData(user, userSession.access_token).finally(() => {
    //             isFetching = false;
    //         });
    //     }
    // }, [user, userSession]);
    
    

    useEffect(() => {
        const checkDataAndFetchClientFiles = async () => {
            const maxRetries = 5; // Number of retries before giving up
            let attempts = 0;
    
            while ((!userSession || !user || !user.user_metadata?.clientId) && attempts < maxRetries) {
                // console.log(`Attempt ${attempts + 1}: Waiting for userSession and clientID...`);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retrying
                attempts++;
            }
    
            // if (userSession && user && user.user_metadata?.clientId && !dataFetched) {
            //     fetchClientData(user, userSession.access_token);
            // } else {
            //     // console.error('Failed to retrieve client files after multiple attempts');
            //     setErrorMessage('Unable to retrieve client data. Please try again.');
            // }

            if (userSession && user && user.user_metadata?.clientId && !dataFetched) {
                fetchClientData(user, userSession.access_token);
            }

            // if (attempts === maxRetries && !dataFetched && !loading) {
            //     setErrorMessage('Unable to retrieve client data. Please try again.');
            // }
        };
    
        if (!dataFetched) {
            checkDataAndFetchClientFiles();
        }
    }, [user, userSession]);
    
    

    const verifySession = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-session`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.ok;
        } catch (error) {
            return false;
        }
    };

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return;
    
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);
    
        // Move to the next input box if available
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };
    
    const handleVerifyOtp = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setLoading(true);
    
        try {
            const otpString = otp.join('');
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, otp: otpString })
            });
    
            const responseText = await response.text(); // Get the raw response text
    
            if (!response.ok) {
                throw new Error('Invalid OTP');
            }
    
            // Parse the JSON response
            const responseData = JSON.parse(responseText);
            
            try {
                localStorage.setItem('sntmAuth', JSON.stringify(responseData));
                console.log('Successfully set sntmAuth cookie:', JSON.stringify(responseData));
            } catch (error){
                console.log('Unexpected error setting sntmAuth cookie:', error);
            }
                
            const parsedUser = responseData.data.user;
            const parsedSession = responseData.data.session;

            if (resettingPassword) {

                setStep(3); // Proceed to password reset

            } else {
                setUserData(parsedUser);
                setUser(parsedUser);
                setUserSession(parsedSession);
                setStep(1); // Proceed to client data after login

                // Trigger the data fetching process
                fetchClientData(parsedUser, parsedSession.access_token);
            }
            
            // Clear OTP state after successful verification
            setOtp(new Array(6).fill(''));
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error in handleVerifyOtp:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setLoading(true);

        setOtp(new Array(6).fill('')); // Reset OTP state to empty array

        // Clear any existing authentication data from local storage
        localStorage.removeItem('sntmAuth');

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/clientPortalPasswordLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (!response.ok) {
                throw new Error('Invalid email or password');
            }

            // Send OTP
            const otpResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (!otpResponse.ok) {
                throw new Error('Failed to send OTP');
            }

            setStep(2);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchClientData = async (user, token) => {
        if (isFetchingData) return;  // Prevent multiple fetches
        setIsFetchingData(true);  // Start fetching
        
        if (!user || !token || !user.user_metadata?.clientId) {
            return;
        }

        setLoading(true);
        
        const clientID = user.user_metadata.clientId;
        const hasFullProfileAccess = user.user_metadata.hasFullProfileAccess;
        
        if (!clientID) {
            setErrorMessage('No client matters found');
            setLoading(false);
            return;
        }
        
        let filteredMatterIds = [];
        let clioDocs = [];
        let clientFilesWithDocs = [];  // Declare clientFilesWithDocs at the top
    
        try {
            // Step 1: Fetch all matters associated with the clientID
            const clioResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/getClientFiles?clientID=${clientID}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!clioResponse.ok) {
                throw new Error('Failed to fetch client files');
            }
    
            const clioData = await clioResponse.json();
    
            clientFilesWithDocs = clioData.data.map(file => ({
                ...file,
                documents: [], // Initialize documents array
                logoUrl: null  // Initialize logoUrl as null
            }));
    
            if (!hasFullProfileAccess) {
                console.log('User does not have full profile access! Finding only directly related matters...');
                const matterRelationshipIds = clioData.data
                    .flatMap(matter => matter.relationships)
                    .filter(relationship => relationship)
                    .map(relationship => relationship.id);
                
                if (matterRelationshipIds.length > 0) {
                    const queryString = matterRelationshipIds.map(id => `ids[]=${id}`).join('&');
                
                    const filteredResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/getFilteredClientFiles?${queryString}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
    
                    if (!filteredResponse.ok) {
                        throw new Error('Failed to fetch client relationship info');
                    }
    
                    const filteredMatters = await filteredResponse.json();
    
                    filteredMatterIds = filteredMatters.data.map(relationship => relationship.matter.id);
    
                    clientFilesWithDocs = clientFilesWithDocs.filter(file =>
                        filteredMatterIds.includes(file.id) ||
                        file.client?.primary_email_address === user.email
                    );
                } else {
                    clientFilesWithDocs = [];
                }
            }
    
            setClientFiles(clientFilesWithDocs); // Set the clientFiles state after filtering
        } catch (error) {
            setErrorMessage('Failed to fetch client data.');
            console.error(error);
            setLoading(false);
            return;
        }
    
        // Step 2: Fetch all document data related to the client's matters
        try {
            const clioDocData = await fetch(`${process.env.REACT_APP_DOMAIN}/api/getClientDocs`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clientID, filteredMatterIds })
            });
    
            if (!clioDocData.ok) {
                throw new Error('Failed to fetch client documents');
            }
    
            clioDocs = await clioDocData.json();   
            if (!Array.isArray(clioDocs)) {
                throw new Error('Unexpected format for client documents');
            }
            
            // Sort documents by created_at in descending order
            const sortedDocs = sortDocumentsByDate(clioDocs);
    
            // Organize documents by matterId and add to clientFiles
            const updatedClientFiles = clientFilesWithDocs.map(file => {
                const matterDocuments = sortedDocs.filter(doc => doc.matter.id === file.id);
                return {
                    ...file,
                    documents: matterDocuments // Assign the sorted documents to the respective file
                };
            });
    
            // Step 3: Filter and download necessary design logo files
            await Promise.all(updatedClientFiles.map(async file => {
                const designLogoDoc = clioDocs.find(doc => doc.name.includes('Design Logo') && doc.matter.id === file.id);
                if (designLogoDoc) {
                    const fileLogo = await downloadDoc({ docId: designLogoDoc.id, matterId: file.id, token });
                    file.logoUrl = fileLogo.fileUrl;  // Add logoUrl directly to the file
                }
            }));
    
            setClientFiles(updatedClientFiles); // Set the updated clientFiles with documents and logos
        } catch (error) {
            console.error('Error fetching client documents or logos:', error);
            setLoading(false); // Stop loading if fetching documents fails
            return;
        } finally {
            setDataFetched(true);
            setLoading(false);
        }
    };
    
    // const downloadDoc = async ({ docId, matterId, token, responseType = 'blob' }) => {
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/downloadDoc?docId=${docId}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    
    //         if (!response.ok) {
    //             throw new Error(`Failed to download document with ID: ${docId}`);
    //         }
    
    //         // Handle different response types (blob, json, text, etc.)
    //         let fileContent;
    //         if (responseType === 'blob') {
    //             fileContent = await response.blob();
    //         } else if (responseType === 'json') {
    //             fileContent = await response.json();
    //         } else {
    //             fileContent = await response.text();
    //         }
    
    //         const url = URL.createObjectURL(fileContent);
    //         return { matterId, docId, fileUrl: url };
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error(`Error downloading document: ${error.message}`);
    //     }
    // };

    const downloadDoc = async ({ docId, matterId, token }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/downloadDoc?docId=${docId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error(`Failed to download document with ID: ${docId}`);
            }
    
            const blob = await response.blob(); // Convert the response to a blob
            const fileUrl = URL.createObjectURL(blob); // Create a blob URL to display or download the document
            return { matterId, docId, fileUrl };
        } catch (error) {
            console.error(error);
            throw new Error(`Error downloading document: ${error.message}`);
        }
    };
    
    

    const organizeDocumentsByMatter = (documents) => {
        const documentsByMatter = {};
    
        documents.forEach(doc => {
            const matterId = doc.matter.id;
            if (!documentsByMatter[matterId]) {
                documentsByMatter[matterId] = [];
            }
            documentsByMatter[matterId].push(doc);
        });
    
        return documentsByMatter;
    };
    
    const sortDocumentsByDate = (documents) => {
        return documents.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    };

    const handleRowClick = (file) => {
        setFileContent(null);  // Clear content first
    
        // Add a slight delay to allow state to update before reopening the modal
        setTimeout(() => {
            setSelectedFile(file);
            fetchCalendarEntries(file);
            setShowModal(true);
            console.log('selectedFile:', file);
        }, 100);  // Adjust the delay time as needed
    };
    
    const handleModalClose = () => {
        setShowModal(false);
        setFileContent({ url: null, matterId: null }); // Reset fileContent when the modal closes
    };

    const fetchCalendarEntries = async (file) => {
        // Check if calendar entries are already stored in the selected file
        if (file.calendarEntries) {
            setUpcomingDeadlines(file.calendarEntries);
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/getCalendarEntries`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userSession.access_token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ matterId: file.id })
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch calendar entries');
            }
    
            const result = await response.json();
            const data = result.data;  // Assuming the API returns an object with a "data" array
            const currentDate = new Date();
    
            // Filter and sort the entries by 'start_at'
            const futureDeadlines = data.filter(entry => new Date(entry.start_at) >= currentDate)
                .sort((a, b) => new Date(a.start_at) - new Date(b.start_at));
    
            // Update the selected file with the fetched calendar entries
            setClientFiles(prevClientFiles =>
                prevClientFiles.map(f =>
                    f.id === file.id
                        ? { ...f, calendarEntries: { matterId: file.id, deadlines: futureDeadlines } }
                        : f
                )
            );
    
            setUpcomingDeadlines({ matterId: file.id, deadlines: futureDeadlines });
    
            console.log('futureDeadlines:', futureDeadlines);
        } catch (error) {
            console.error('Error fetching calendar entries:', error);
        }
    };
    

    // Function to format date
    const formatDate = (dateStr) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateStr).toLocaleDateString(undefined, options);
    };

    // Render the "Upcoming Deadlines" tab content
    const renderUpcomingDeadlines = () => {
        if ((upcomingDeadlines && upcomingDeadlines.deadlines.length === 0) || upcomingDeadlines.matterId !== selectedFile.id) {
            return (
                <div className='client-file-deadlines-overview'>
                    <div className='client-file-deadline'>
                        <div className='client-file-no-deadlines'>No upcoming deadlines for this file</div> 
                    </div>
                </div>
            );
        }
    
        return (
            <ul>
                {upcomingDeadlines.deadlines.map((entry, index) => (
                    <li key={index}>
                        <div className='client-file-deadlines-overview'>
                            <div className='client-file-deadline'>
                                <span className='client-file-deadline-date'>{new Date(entry.start_at).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}:</span>
                                <span className='client-file-deadline-event'>{entry.description}</span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const handleSignOut = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        // Clear any existing authentication data from local storage
        localStorage.removeItem('sntmAuth');

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/clientPortalSignOut`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Error Signing Out');
            }

            //Reset state
            setUserData(null);
            setUser(null);
            setUserSession(null);
            setStep(1);
            setOtp(new Array(6).fill('')); // Reset OTP state to empty array
        } catch (error) {
            setErrorMessage(error.message);
        } 
    }

    const handleForgotPassword = async () => {
        if(!email) {
            setErrorMessage('Please enter email address associated with account');
            return;
        }
        setLoading(true);
        setResettingPassword(true);
        setErrorMessage('');
        try {
            const otpResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (!otpResponse.ok) {
                throw new Error('Failed to send OTP');
            }

            setStep(2); // Move to OTP verification step
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setLoading(true);

        // Check if the passwords match
        if(newPassword !== verifiedNewPassword){
            setErrorMessage('Passwords do not match');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, newPassword })
            });

            if (!response.ok) {
                throw new Error('Failed to reset password. Please try again.');
            }

            alert('Password reset successfully. Please log in with your new password.');
            setStep(1); // Move back to login step
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
            setResettingPassword(false);
        }
    };

    const groupGoodsServices = (customFieldValues) => {
        // Initialize an object to hold blocks, keyed by the class number
        const blocks = {};
    
        customFieldValues.forEach(field => {
            // Match the class number based on the field name
            const match = field.field_name.match(/(\d+)$/);
            if (match) {
                const classNumber = match[1]; // Extract the class number
    
                if (!blocks[classNumber]) {
                    blocks[classNumber] = { class: classNumber }; // Initialize a new block if it doesn't exist
                }
    
                // Map the fields based on the class number
                if (field.field_name.includes('Class')) {
                    blocks[classNumber].class = field.value;
                } else if (field.field_name.includes('Description')) {
                    blocks[classNumber].description = field.value;
                } else if (field.field_name.includes('Date of First Use in Commerce')) {
                    blocks[classNumber].dateOfFirstUseInCommerce = field.value;
                } else if (field.field_name.includes('Date of First Use')) {
                    blocks[classNumber].dateOfFirstUse = field.value;
                } else if (field.field_name.includes('Foreign App Country')) {
                    blocks[classNumber].foreignAppCountry = field.value;
                } else if (field.field_name.includes('Foreign App Serial Number')) {
                    blocks[classNumber].foreignAppSerialNumber = field.value;
                } else if (field.field_name.includes('Foreign App Filing Date')) {
                    blocks[classNumber].foreignAppFilingDate = field.value;
                } else if (field.field_name.includes('Foreign Reg Country')) {
                    blocks[classNumber].foreignRegCountry = field.value;
                } else if (field.field_name.includes('Foreign Reg Number')) {
                    blocks[classNumber].foreignRegNumber = field.value;
                } else if (field.field_name.includes('Foreign Reg Date')) {
                    blocks[classNumber].foreignRegDate = field.value;
                } else if (field.field_name.includes('Foreign Reg Expiration Date')) {
                    blocks[classNumber].foreignRegExpirationDate = field.value;
                } else if (field.field_name.includes('Foreign Reg Renewed Date')) {
                    blocks[classNumber].foreignRegRenewedDate = field.value;
                }
            }
        });
    
        // Convert blocks object to an array
        return Object.values(blocks);
    };
    
    const displayFileDetails = () => {
        if (!selectedFile) return null;

        const handleDocClick = async (doc) => {
            try {
                const existingDoc = selectedFile.documents.find(d => d.id === doc.id);
                if (existingDoc && existingDoc.url) {
                    setSelectedDocUrl({ url: existingDoc.url, matterId: selectedFile.id });
                    setFileContent({ url: existingDoc.url, matterId: selectedFile.id });
                    setSelectedDocName(doc.name);  // Set the name of the selected document
                } else {
                    const result = await downloadDoc({ docId: doc.id, matterId: doc.matter.id, token: userSession.access_token });
                    setClientFiles(prevClientFiles =>
                        prevClientFiles.map(file =>
                            file.id === result.matterId
                                ? {
                                    ...file,
                                    documents: file.documents.map(d =>
                                        d.id === result.docId
                                            ? { ...d, url: result.fileUrl }
                                            : d
                                    )
                                }
                                : file
                        )
                    );
                    setSelectedDocUrl({ url: result.fileUrl, matterId: selectedFile.id });
                    setFileContent({ url: result.fileUrl, matterId: selectedFile.id });
                    setSelectedDocName(doc.name);
                }

                if (isMobileView) {
                    setShowDocumentViewerModal(true);  // Open document viewer in a new modal for mobile
                }
            } catch (error) {
                console.error('Error downloading document:', error);
            }
        };

        return (
            <div className='client-file-file-container' style={{ display: 'flex' }}>
                <div>
                    {selectedFile.documents && selectedFile.documents.length > 0 ? (
                        <ul>
                            {selectedFile.documents.map(doc => (
                                <li key={doc.id}>
                                    <button
                                        onClick={() => handleDocClick(doc)}
                                        className='client-file-file'
                                        style={{ background: 'none', border: 'none', textAlign: 'left', padding: '0', color: '#007bff', cursor: 'pointer' }}
                                    >
                                        {doc.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No documents available for this file.</p>
                    )}
                </div>

                {!isMobileView && (
                    <div style={{ width: '60%', paddingLeft: '20px' }}>
                        {selectedDocUrl?.url && selectedDocUrl.matterId === selectedFile.id ? (
                            <FileViewer fileUrl={selectedDocUrl.url} fileName={selectedDocName} />
                        ) : (
                            <p>Select a document to view its contents.</p>
                        )}
                    </div>
                )}
            </div>
        );
    };
    

    const handleDocumentViewerModalClose = () => {
        setShowDocumentViewerModal(false);
        setFileContent({ url: null, matterId: null });
    };
    
    // const FileViewer = ({ fileUrl, fileName }) => {
    //     const isMobile = useIsMobile();
    
    //     useEffect(() => {
    //         if (isMobile && fileUrl) {
    //             // Open the file in a new tab/window for mobile devices
    //             window.open(fileUrl, '_blank');
    //         }
    //     }, [isMobile, fileUrl]);
    
    //     return (
    //         <div className="file-viewer">
    //             {fileUrl ? (
    //                 !isMobile ? (
    //                     <iframe
    //                         src={fileUrl}
    //                         title={fileName}
    //                         style={{ width: '100%', height: '650px', border: '1px solid black' }}
    //                     />
    //                 ) : (
    //                     <p>Opening file in a new tab...</p>
    //                 )
    //             ) : (
    //                 <p>No file selected or available to view.</p>
    //             )}
    //         </div>
    //     );
    // };
    
    const FileViewer = ({ fileUrl, fileName }) => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
        const handleOpenInNewTab = () => {
            // Open the PDF in a new tab, which should prompt a download/view action on mobile
            window.open(fileUrl, '_blank');
        };
    
        useEffect(() => {
            if (isMobile && fileUrl) {
                handleOpenInNewTab();
            }
        }, [isMobile, fileUrl]);
    
        return (
            <div className="file-viewer">
                {fileUrl ? (
                    !isMobile ? (
                        <iframe
                            src={fileUrl}
                            title={fileName}
                            style={{ width: '100%', height: '650px', border: '1px solid black' }}
                        />
                    ) : (
                        <div>
                            <p>Opening document...</p>
                            <button onClick={handleOpenInNewTab}>Click here to open manually</button>
                        </div>
                    )
                ) : (
                    <p>No file selected or available to view.</p>
                )}
            </div>
        );
    };
    
    
    
    return (
        <div className="container mt-5 client-portal-container">
            {loading && <div className="alert">Loading...</div>}
            {user && userSession && !loading ? (
                <div>
                    <h2>Welcome {userData.user_metadata.first_name}!</h2>
                    <div>
                        <span className='signout' onClick={handleSignOut}>Sign Out</span>
                    </div>
                    <div className="col-md-3">
                        <Button variant="primary" onClick={() => alert('Request New File')}>
                            Request New File
                        </Button>
                    </div>
                    <div className="col-md-9">
                        {/* <div className='table-responsive'> */}
                            <Table striped bordered hover className='client-portal_results-table table-striped'>
                                <thead>
                                    <tr>
                                        <th className="d-none d-md-table-cell">File Number</th>
                                        <th className="d-none d-md-table-cell">Your Reference</th>
                                        <th>Trademark</th>
                                        <th className="d-none d-md-table-cell">Application Serial Number</th>
                                        <th className="d-none d-md-table-cell">Filing Date</th>
                                        <th className="d-none d-md-table-cell">File Status</th>
                                        <th>USPTO Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientFiles.map((file) => {
                                        const fileNumber = file.display_number || 'N/A';
                                        const clientDescription = file.client_reference?.value || 'N/A';
                                        const description = file.description === 'Word Mark' ? file.custom_field_values.find(field => field.field_name === 'Mark')?.value : file.description;
                                        const logoUrl = designLogos[file.id];  // Lookup the logo URL by matter ID
                                        const serialNumber = file.custom_field_values.find(field => field.field_name === 'Application Serial Number')?.value || 'N/A';
                                        const filingDate = file.custom_field_values.find(field => field.field_name === 'Application Filing Date')?.value || 'N/A';
                                        const fileStatus = file.custom_field_values.find(field => field.field_name === 'Trademark Status')?.picklist_option?.option || 'N/A';
                                        const fileUSPTOLink = `https://tsdr.uspto.gov/#caseNumber=${serialNumber}&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch`
                                        return (
                                            <tr key={file.id} onClick={() => handleRowClick(file)}>
                                                <td className="d-none d-md-table-cell file-number">{fileNumber}</td>
                                                <td className="d-none d-md-table-cell reference">{clientDescription}</td>
                                                <td className="description">
                                                    {file.description === 'Design Logo' && file.logoUrl ? (
                                                        <img src={file.logoUrl} alt="Design Logo" style={{ maxWidth: 'auto', maxHeight: '50px' }} />
                                                    ) : description}
                                                </td>
                                                <td className="d-none d-md-table-cell serial-number">{serialNumber}</td>
                                                <td className="d-none d-md-table-cell filing-date">{filingDate}</td>
                                                <td className="d-none d-md-table-cell file-status">{fileStatus}</td>
                                                <td className="uspto-link">
                                                    {serialNumber !== 'N/A' ? (
                                                        <a 
                                                            href={fileUSPTOLink} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer" 
                                                            className='appUSPTOLink' 
                                                            onClick={(e) => e.stopPropagation()}
                                                        >View at USPTO</a>
                                                    ) : 'N/A'}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        {/* </div> */}
                    </div>
                    {showModal && (
                        <div className='client-file-modal-container'>
                            <div className='client-file-modal'>
                                <Button className="close-modal-btn" onClick={handleModalClose}>
                                    &times;
                                </Button>
                                <Container fluid>
                                    <Tabs defaultActiveKey="details">
                                        <Tab eventKey="details" title="Details">
                                            <div className='client-file-overview'>
                                                <div className='client-file-item'>
                                                    <strong>Description:</strong> 
                                                    {selectedFile.description === 'Design Logo' && selectedFile.logoUrl 
                                                        ? (
                                                            <img src={selectedFile.logoUrl} alt="Design Logo" style={{ maxWidth: 'auto', maxHeight: '50px' }} />
                                                        ) : (
                                                            selectedFile.custom_field_values.find(field => field.field_name === 'Mark')?.value || selectedFile.description
                                                        )
                                                    }
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>File Number:</strong> 
                                                    {selectedFile.display_number || 'N/A'}
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>Current Status:</strong>
                                                    {selectedFile.custom_field_values.find(field => field.field_name === 'Trademark Status')?.picklist_option?.option || 'N/A'}
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>Application Serial Number:</strong> 
                                                    {selectedFile.custom_field_values.find(field => field.field_name === 'Application Serial Number')?.value || 'N/A'}
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>Filing Date:</strong>
                                                    {selectedFile.custom_field_values.find(field => field.field_name === 'Application Filing Date')?.value || 'N/A'}
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>Registration Number:</strong>
                                                    {selectedFile.custom_field_values.find(field => field.field_name === 'Registration Number')?.value || 'N/A'}
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>Registration Date:</strong>
                                                    {selectedFile.custom_field_values.find(field => field.field_name === 'Registration Date')?.value || 'N/A'}
                                                </div>
                                                <div className='client-file-item'>
                                                    <strong>Registration Date:</strong>
                                                    {selectedFile.custom_field_values.find(field => field.field_name === 'Registration Date')?.value || 'N/A'}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="deadlines" title="Upcoming Deadlines">
                                            {renderUpcomingDeadlines()}
                                        </Tab>
                                        <Tab eventKey="documents" title="Documents">
                                            {displayFileDetails()}
                                        </Tab>
                                        <Tab eventKey="goods" title="Goods/Services">
                                            {groupGoodsServices(selectedFile.custom_field_values).map((block, index) => (
                                                <div key={index} className="goods-block">
                                                    <h5>Class {block.class || index + 1}</h5>
                                                    <p><strong>Description:</strong> {block.description || 'N/A'}</p>
                                                    <p><strong>Date of First Use:</strong> {block.dateOfFirstUse || 'N/A'}</p>
                                                    <p><strong>Date of First Use in Commerce:</strong> {block.dateOfFirstUseInCommerce || 'N/A'}</p>
                                                    {block.foreignAppCountry && 
                                                        <>
                                                            <p><strong>Foreign App Country:</strong> {block.foreignAppCountry || 'N/A'}</p>
                                                            <p><strong>Foreign App Serial Number:</strong> {block.foreignAppSerialNumber || 'N/A'}</p>
                                                            <p><strong>Foreign App Filing Date:</strong> {block.foreignAppFilingDate || 'N/A'}</p>
                                                        </>
                                                    }
                                                    {block.foreignRegCountry && 
                                                        <>
                                                            <p><strong>Foreign Reg Country:</strong> {block.foreignRegCountry || 'N/A'}</p>
                                                            <p><strong>Foreign Reg Number:</strong> {block.foreignRegNumber || 'N/A'}</p>
                                                            <p><strong>Foreign Reg Date:</strong> {block.foreignRegDate || 'N/A'}</p>
                                                            <p><strong>Foreign Reg Expiration Date:</strong> {block.foreignRegExpirationDate || 'N/A'}</p>
                                                            <p><strong>Foreign Reg Renewed Date:</strong> {block.foreignRegRenewedDate || 'N/A'}</p>
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </Tab>
                                    </Tabs>
                                </Container>
                                <Button variant='secondary' onClick={handleModalClose}>Close</Button>
                            </div>
                        </div>
                    )}
                    {showDocumentViewerModal && window.innerWidth < 810 && (
                        <div className='document-viewer-modal-container'>
                            <div className='document-viewer-modal'>
                                <Button variant='secondary' onClick={handleDocumentViewerModalClose}>Close Viewer</Button>
                                <FileViewer fileUrl={selectedDocUrl.url} fileName={selectedDocName} />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                step === 1 ? (
                    <form onSubmit={handleLogin}>
                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="emailInput" className="form-label">Email:</label>
                                <input
                                    type="email"
                                    id="emailInput"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="passwordInput" className="form-label">Password:</label>
                                <input
                                    type="password"
                                    id="passwordInput"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        {errorMessage && <div className="row mb-3">
                            <div className="col-12">
                                <div className="alert alert-danger">{errorMessage}</div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <button type="submit" className={loading ? "btn btn-primary login-button client-portal-login-button-loading" : "btn btn-primary login-button client-portal-login-button"}>Login</button>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <span onClick={handleForgotPassword} className='forgot-password'>Forgot Password?</span>
                            </div>
                        </div>
                    </form>
                ) : step === 2 ? (
                    <form onSubmit={handleVerifyOtp}>
                        <div className="row mb-3">
                            <div className="col-12 d-flex justify-content-center">
                                {otp.map((data, index) => {
                                    return (
                                        <input
                                            key={index}
                                            type="text"
                                            className="form-control otp-input mx-1"
                                            maxLength="1"
                                            value={data}
                                            onChange={(e) => handleOtpChange(e.target, index)}
                                            ref={el => inputRefs.current[index] = el}
                                            style={{ width: '40px', textAlign: 'center' }}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="alert alert-danger">{errorMessage}</div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <button type="submit" className={loading ? "btn btn-primary login-button client-portal-login-button-loading" : "btn btn-primary login-button client-portal-login-button"}>Verify OTP</button>
                            </div>
                        </div>
                    </form>
                ) : step === 3 ? (
                    <form onSubmit={handlePasswordReset}>
                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="newPasswordInput" className="form-label">New Password:</label>
                                <input
                                    type="password"
                                    id="newPasswordInput"
                                    className="form-control"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="verifyNewPasswordInput" className="form-label">Verify New Password:</label>
                                <input
                                    type="password"
                                    id="verifyNewPasswordInput"
                                    className="form-control"
                                    value={verifiedNewPassword}
                                    onChange={(e) => setVerifiedNewPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        {errorMessage && <div className="row mb-3">
                            <div className="col-12">
                                <div className="alert alert-danger">{errorMessage}</div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <button type="submit" className="btn btn-primary">Reset Password</button>
                            </div>
                        </div>
                    </form>
                ) : null
            )}
        </div>
    );
    
};

export default ClientPortalLogin;


