import React, { useEffect, useState } from 'react';
import logo from './FullLogo6.png';

// Helper function to generate the HTML content for the PDF
export const generateFormContent = (formData) => {
    // Function to format description strings including core descriptions and any accompanying additional input
    const formatDescriptions = (descriptions) => {
        return descriptions.map(desc => {
            const coreDescription = desc.core; // Assuming desc.core is the core description string
            const additionalInputsStr = Object.entries(desc.additionalInputs || {})
                .map(([key, value]) => `${value}`).join(', ');
            return `${coreDescription}${additionalInputsStr ? ` ${additionalInputsStr}` : ''}`;
        }).join('; ');
    };
    
    const renderContactInfo = () => `
        <div>
            <h3 style="font-size: 1.4rem; font-weight: bold; text-decoration: underline;">Contact Information:</h3>
            ${formData.contactFirstName ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">First Name:</label>
                <span>${formData.contactFirstName}</span>
            </div>` : ''}
            ${formData.contactMiddleName ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Middle Name:</label>
                <span>${formData.contactMiddleName}</span>
            </div>` : ''}
            ${formData.contactLastName ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Last Name:</label>
                <span>${formData.contactLastName}</span>
            </div>` : ''}
            ${formData.contactCompany ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Company Name:</label>
                <span>${formData.contactCompany}</span>
            </div>` : ''}
            ${formData.contactAddress1 ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Address 1:</label>
                <span>${formData.contactAddress1}</span>
            </div>` : ''}
            ${formData.contactAddress2 ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Address 2:</label>
                <span>${formData.contactAddress2}</span>
            </div>` : ''}
            ${formData.contactCity ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">City:</label>
                <span>${formData.contactCity}</span>
            </div>` : ''}
            ${formData.contactState ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">State:</label>
                <span>${formData.contactState}</span>
            </div>` : ''}
            ${formData.contactZip ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Postal Code:</label>
                <span>${formData.contactZip}</span>
            </div>` : ''}
            ${formData.contactCountry ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Country:</label>
                <span>${formData.contactCountry}</span>
            </div>` : ''}
            ${formData.contactEmail ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Email Address:</label>
                <span>${formData.contactEmail}</span>
            </div>` : ''}
            ${formData.refNo ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Reference Number:</label>
                <span>${formData.refNo}</span>
            </div>` : ''}
        </div>
    `;

    const renderOwnerInfo = () => `
        <div>
            <h3 style="font-size: 1.4rem; font-weight: bold; text-decoration: underline;">Owner Information:</h3>
            ${formData.ownerFirstName ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">First Name:</label>
                <span>${formData.ownerFirstName}</span>
            </div>` : ''}
            ${formData.ownerMiddleName ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Middle Name:</label>
                <span>${formData.ownerMiddleName}</span>
            </div>` : ''}
            ${formData.ownerLastName ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Last Name:</label>
                <span>${formData.ownerLastName}</span>
            </div>` : ''}
            ${formData.ownerCompany ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Company Name:</label>
                <span>${formData.ownerCompany}</span>
            </div>
            <div>
                <label style="width: 9.375rem; display: inline-block;">Company Country:</label>
                <span>${formData.ownerCompanyCountry}</span>
            </div>
            <div>
                <label style="width: 9.375rem; display: inline-block;">Company Type:</label>
                <span>${formData.ownerCompanyType}</span>
            </div>` : ''}
            ${formData.ownerAddress1 ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Address 1:</label>
                <span>${formData.ownerAddress1}</span>
            </div>` : ''}
            ${formData.ownerAddress2 ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Address 2:</label>
                <span>${formData.ownerAddress2}</span>
            </div>` : ''}
            ${formData.ownerCity ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">City:</label>
                <span>${formData.ownerCity}</span>
            </div>` : ''}
            ${formData.ownerState ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">State:</label>
                <span>${formData.ownerState}</span>
            </div>` : ''}
            ${formData.ownerZip ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Postal Code:</label>
                <span>${formData.ownerZip}</span>
            </div>` : ''}
            ${formData.ownerCountry ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Country:</label>
                <span>${formData.ownerCountry}</span>
            </div>` : ''}
            ${formData.ownerEmail ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Email Address:</label>
                <span>${formData.ownerEmail}</span>
            </div>` : ''}
        </div>
    `;

    const renderMarkInfo = () => {
        let designMarkDataUrl;

        return `
        <div>
            <h3 style="font-size: 1.4rem; font-weight: bold; text-decoration: underline;">Mark Information:</h3>
            ${formData.wordMark ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Mark:</label>
                <span>${formData.wordMark}</span>
            </div>` : ''}
            ${formData.applicationType !== 'word' && formData.designMarkFile  ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Mark:</label>
                <img src="DESIGN_MARK_PLACEHOLDER" alt="Design Logo" style="max-width: 12.5rem; height: auto; margin-top: 0.625rem;">
            </div>
            ${formData.markDescription ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Description:</label>
                <span>${formData.markDescription}</span>
            </div>` : ''}
            ${formData.markColorClaim ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Color Claim:</label>
                <span>${formData.markColorClaim}</span>
            </div>` : ''}` : ''}
            ${formData.soundMarkFile && formData.soundMarkFile.length > 0 ? `
            <div>
                <label style="width: 9.375rem; display: inline-block;">Mark:</label>
                <span>${formData.soundMarkDescription ? formData.soundMarkDescription : 'Sound Mark'}</span>
            </div>` : ''}
        </div>
        `;
    };
    
    const renderGoodsServices = () => `
        <div>
            <h3 style="font-size: 1.4rem; font-weight: bold; text-decoration: underline;">Goods/Services</h3>
            <ol style="padding-left: 0; margin-left: 1%;">
                ${formData.classBlocks.map((block) => `
                <li key=${block.id} style="font-size: 1rem; font-weight: normal; margin-left: 0; margin-bottom: 1rem; list-style: decimal; list-style-position: outside; padding-left: 0; margin-left: 0;">
                    International Class ${block.classNo}: ${block.descriptions ? formatDescriptions(block.descriptions) : block.description}
                    ${block.dateFirstUse ? `
                    <div>
                        <label style="width: 14rem; display: inline-block; margin-right: 0.3rem;">Date of First Use:</label>
                        <span>${block.dateFirstUse}</span>
                    </div>
                    <div>
                        <label style="width: 14rem; display: inline-block; margin-right: 0.3rem;">Date of First Use in Commerce:</label>
                        <span>${block.dateFirstUseInCommerce}</span>
                    </div>` : ''}
                    ${block.url ? `
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">URL:</label>
                        <span>${block.url}</span>
                    </div>` : ''}
                    ${block.foreignAppNo ? `
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Country:</label>
                        <span>${block.foreignAppCountry}</span>
                    </div>
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Serial No.:</label>
                        <span>${block.foreignAppNo}</span>
                    </div>
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Filing Date:</label>
                        <span>${block.foreignAppFilingDate}</span>
                    </div>` : ''}
                    ${block.foreignRegNo ? `
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Country:</label>
                        <span>${block.foreignAppCountry}</span>
                    </div>
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Registration No.:</label>
                        <span>${block.foreignRegNo}</span>
                    </div>
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Renewed Date:</label>
                        <span>${block.foreignRegRenewedDate}</span>
                    </div>
                    <div>
                        <label style="width: 10rem; display: inline-block; margin-right: 0.3rem;">Parent Expiration Date:</label>
                        <span>${block.foreignExpirationDate}</span>
                    </div>` : ''}
                </li>`).join('')}
            </ol>
        </div>
    `;

    const content = `
    <div style="font-family: 'Roboto Serif', serif; color: black;">
      <div style="font-size: 1rem; font-weight: normal;">
        <h2 style="font-size: 1.5rem; font-weight: bold; text-align: center;">SUBMITTED INFORMATION</h2>
        ${renderContactInfo()}
        ${renderOwnerInfo()}
        ${renderMarkInfo()}
        ${renderGoodsServices()}
      </div>
    </div>
  `;

  return content;
};

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
};
  
// FormDownload Component
const FormDownload = ({ formData, setCapturedFormData }) => {
    // State to hold the encoded images
    const [encodedImages, setEncodedImages] = useState(false);

    // Function to capture the content
    const captureContent = async () => {
      console.log('Running captureContent'); // Debug log
      try {
        const base64Logo = await getBase64(formData.designMarkFile);
        console.log('base64Logo:', base64Logo); // Debug log
        const base64DesignLogo = await getBase64(logo);
        console.log('base64DesignLogo:', base64DesignLogo); // Debug log
    
        const updatedFormData = {
          ...formData,
          base64Logo,
          base64DesignLogo,
        };
    
        console.log('Updated Form Data:', updatedFormData); // Debug log
    
        const content = generateFormContent(updatedFormData);
        setCapturedFormData(content);
        setEncodedImages(true);
      } catch (error) {
        console.error('Error encoding images:', error);
      }
    };

    useEffect(() => {
      if (formData && !encodedImages) {
        console.log('FormData or encodedImages changed'); // Debug log
        captureContent();
      }
    }, [formData, encodedImages]);
  
    return null;
};
  
export default FormDownload;


//<img src="${formData.base64Logo}" alt="San Novus Trademark Logo" style="max-width: 12.5rem; height: auto; margin-bottom: 0.625rem;">
{/* <div style="text-align: center;">
        <div style="font-size: 0.9rem; color: rgb(46, 89, 166);">
          <span>info@sannovustrademark.com</span>
          <span> | </span>
          <span>www.sannovustrademark.com</span>
        </div>
      </div> */}