// import React from 'react';

// const StepIndicator = ({ currentStep }) => {
//     return (
//       <div className="step-indicator">
//         {[1, 2, 3, 4, 5].map(step => (
//           <div
//             key={step}
//             className={`circle ${currentStep === step ? 'current' : currentStep > step ? 'filled' : ''}`}
//           >
//             {step}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   export default StepIndicator;
import React from 'react';
import PropTypes from 'prop-types';

const StepIndicator = ({ currentStep, totalSteps }) => {
    const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

    return (
        <div className="step-indicator">
            {steps.map(step => (
                <div
                    key={step}
                    className={`circle ${currentStep === step ? 'current' : currentStep > step ? 'filled' : ''}`}
                >
                    {step}
                </div>
            ))}
        </div>
    );
};

StepIndicator.propTypes = {
    currentStep: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
};

export default StepIndicator;
