import React, { useState } from "react";
import goodsProduct from './goods-product.jpg';
import goodsPackaging from './goods-product_packaging.jpg';
import goodsWebsite from './goods-website.jpg';
import servicesSignage from './services-signage.jpg';
import servicesWebsite from './services-website.jpg';

const HelpGuide = ({ isOpen, onClose, toggleHelpGuide, selectedImage, setSelectedImage}) => {

    if (!isOpen) return null;
  
    return (
      <div className='specimens-help-modal'>
        <div className='specimens-help-content'>
            <h2>Acceptable Specimens (Proof of Use)</h2>
            <p>Let's ensure your evidence of use meets the U.S. Trademark Office's standards — it's known to be quite strict about what 
                constitutes acceptable proof.</p>
            <p>The examining attorney seeks a clear link between your mark and the goods/services offered. For products, this means 
                displaying your mark on the item, its packaging, or on a sales website. Consider these examples: (images may be clicked to enlarge)</p>
            <div className='specimen-sample-container'>
                <div className='specimen-sample' onClick={() => setSelectedImage(goodsProduct)}>
                    <img src={goodsProduct} alt='sample trademark specimen of goods on product'/>
                    <label>Trademark on Actual Goods</label>
                </div>
                <div className='specimen-sample' onClick={() => setSelectedImage(goodsPackaging)}>
                    <img src={goodsPackaging} alt='sample trademark specimen of goods on product packaging'/>
                    <label>Trademark on Product Packaging</label>
                </div>
                <div className='specimen-sample' onClick={() => setSelectedImage(goodsWebsite)}>
                    <img src={goodsWebsite} alt='sample trademark specimen of goods on website'/>
                    <label>Trademark on Website</label>
                </div>
            </div>
            <p>As seen above, Coca-Cola places its mark directly on bottles or boxes — this is ideal since the mark is visibly connected to the product. 
                Similarly, Nike features its mark on a sales page, accompanied by an "Add to Bag" button, directly linking the mark to a purchasable product.</p>
            <p>Services can demonstrate use more flexibly, as there isn't a physical product to mark. Here, the examining attorney looks 
                for advertising that pairs the mark with a description of the offered services, like store signage or a service website:</p>
            <div className='specimen-sample-container'>
                <div className='specimen-sample' onClick={() => setSelectedImage(servicesSignage)}>
                    <img src={servicesSignage} alt='sample trademark specimen of services on signage'/>
                    <label>Trademark on Store Signage</label>
                </div>
                <div className='specimen-sample' onClick={() => setSelectedImage(servicesWebsite)}>
                    <img src={servicesWebsite} alt='sample trademark specimen of services on website'/>
                    <label>Trademark on Website</label>
                </div>
            </div>
            <p>For instance, Walmart's storefront signage with the trademark informs customers of the retail services offered within. Similarly, Target's website, 
                bearing the "Target" mark, advertises its online retail services.</p>
            <p>If your evidence involves website use, simply select "Mark in use on website" and provide the URL — we'll handle the rest. Alternatively, if your 
                mark appears on physical products or packaging, please share photos demonstrating clear use of the mark.</p>
            <div className='warning-modal-buttons'>
                <button onClick={toggleHelpGuide} className='goodsService-help-button'>Close</button>
            </div>
        </div>
      </div>
    );
};

export default HelpGuide;