//userRegistration.js
import React, { useState, useEffect } from 'react';
import StateCombobox from './stateCB.js';
import CountryCombobox from './countryCB.js';

const UserRegistration = ({ formData, setFormData, setCurrentStep, onFormDataChange }) => {
    const [step, setStep] = useState(1); // 1 for login, 2 for registration
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    // const [formData, setFormData] = useState({
    //     contactFirstName: '',
    //     contactMiddleName: '',
    //     contactLastName: '',
    //     contactCompany: '',
    //     contactAddress1: '',
    //     contactAddress2: '',
    //     contactCity: '',
    //     contactState: '',
    //     contactZip: '',
    //     contactCountry: '',
    //     contactEmail: '',
    //     refNo: '',
    //     regPassword:'',
    //     clientId:'',
    //     contactId:'',
    //     altEmails:'',
    // });
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [tableId, setTableId] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [isCountryDisabled, setIsCountryDisabled] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [resettingPassword, setResettingPassword] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [user, setUser] = useState('');
    const [userSession, setUserSession] = useState('');
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        console.log('Form data updated:', formData);
    }, [formData]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        // setFormData({ ...formData, [id]: value });
        if (id === 'emailInput') {
            setEmail(value);
        } else {
            setFormData({ ...formData, [id]: value });
        }
    };

    const checkUserExists = async (email) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verifyUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error checking user existence:', error);
            return false;
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setLoading(true);

        try {
            const userExists = await checkUserExists(email);

            if (!userExists.exists) {
                setErrorMessage('User not found. Please register as a new user.');
                setStep(2);
                setLoading(false);
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/clientPortalPasswordLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    setErrorMessage('Invalid email or password. Please try again.');
                } else {
                    setErrorMessage('An unexpected error occurred. Please try again later.');
                }
            } else {
                const data = await response.json();
                setFormData({ ...formData, contactCompanyID: userExists.clientId, contactID: userExists.contactId }); //update formData
                setCurrentStep(2);
            }
        } catch (error) {
            setErrorMessage('An error occurred while processing your request. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setAttemptedSubmit(true);

        if (!formData.contactFirstName || !formData.contactLastName || !formData.contactAddress1 || !formData.contactCity || !formData.contactZip || !formData.contactCountry || !formData.contactEmail) {
            setIsFormValid(false);
            return;
        }

        //Check if user exists in system (pre-authorized by another user or is an existing registered user)
        const userExists = await checkUserExists(formData.contactEmail);

        if(userExists.ID && userExists.UID){ //userExists.UID indicates existing registered user - UID value not assigned until user registers
            setErrorMessage('User profile already exists. Please log in with your username and password.');
            return;
        }

        let clientId;
        let contactId;
        let hasFullProfileAccess;

        //Assign clientId if user is pre-authorized
        if(userExists.clientId){
            clientId = userExists.clientId;
            contactId = await createClioPerson(clientId); //Create contact profile in Clio for new user
            setFormData({ ...formData, contactCompanyID: clientId, contactID: contactId }); //update formData
        } else {
            //Check if user appears to belong to an existing organization but was not pre-authorized by another user
            if (formData.contactCompany) {
                const emailDomain = extractDomain(formData.contactEmail);
                const url = `${process.env.REACT_APP_DOMAIN}/api/verifyClioContact?emailDomain=${encodeURIComponent(emailDomain)}&company=${encodeURIComponent(formData.contactCompany)}`;
                
                const clioResponse = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                const clioContactData = await clioResponse.json();
    
                if (clioContactData.data && clioContactData.data.length > 0) {
                    clientId = clioContactData.data[0].id; //Provisionally assign client ID
                    contactId = await createClioPerson(clientId); //Create contact profile in Clio for new user
                    hasFullProfileAccess = false; //Grant access only to matters directly associated with email address
                    setFormData({ ...formData, contactCompanyID: clientId, contactID: contactId }); //update formData
                } else {
                    clientId = await createClioCompany(); //Create client profile for new client - business entity
                    contactId = await createClioPerson(clientId); //Create contact profile for individual user
                    hasFullProfileAccess = true; //Allow full profile access since this is for a new client
                    setFormData({ ...formData, contactCompanyID: clientId, contactID: contactId }); //update formData
                }
            } else {
                clientId = await createClioPerson(); //Create client profile for new client - individual
                contactId = clientId; //Contact ID will match client ID since there is no contact profile for a business entity
                hasFullProfileAccess = true; //Allow full profile access since this is for a new client
                setFormData({ ...formData, contactCompanyID: '', contactID: contactId }); //update formData
            }
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/registerUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    first_name: formData.contactFirstName,
                    last_name: formData.contactLastName,
                    email: formData.contactEmail,
                    password: formData.regPassword,
                    clientId: clientId || formData.clientId ? clientId || formData.clientId : '',
                    contactId: contactId || formData.contactId ? contactId || formData.contactId : '',
                    altEmails: formData.altEmails ? formData.altEmails.split(' ').join(',') : '',
                    hasFullProfileAccess: hasFullProfileAccess,
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                setErrorMessage(errorData.error || 'Registration failed');
            } else {
                const data = await response.json();
                setCurrentStep(2);
                setErrorMessage('Congratulations! Registration Successful!');

            }
        } catch (error) {
            setErrorMessage('Registration failed');
        }
    };

    const extractDomain = (email) => {
        const domainMatch = email.match(/@(.+)$/);
        if (domainMatch) {
            return domainMatch[1];
        }
        return null;
    };

    const createClioCompany = async () => {
        const contactInfo = {
          firstName: formData.contactFirstName,
          middleName: formData.contactMiddleName,
          lastName: formData.contactLastName,
          company: formData.contactCompany,
          address1: formData.contactAddress1,
          address2: formData.contactAddress2,
          city: formData.contactCity,
          state: formData.contactState,
          zip: formData.contactZip,
          country: formData.contactCountry,
          email: formData.contactEmail,
        };
      
        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
        const clioContact = {
          data: {
            "addresses":[
              {
                "name":"Billing",
                "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
                "city":`${contactInfo.city}`,
                "province":`${contactInfo.state}`,
                "postal_code":`${contactInfo.zip}`,
                "country":`${contactInfo.country}`,
              }
            ],
            "clio_connect_email":`${contactInfo.email}`,
            "email_addresses":[
              {
                "name": "Work",
                "address":`${contactInfo.email}`,
                "default_email":true,
              }
            ],
            "name":`${contactInfo.company}`,
            "type":"Company",
          }
        };
      
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(clioContact)
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
          
          return data && data.data.id? data.data.id : null;
      
        } catch (error) {
          console.error('Error creating new Clio company contact:',error);
        }
    };

    const createClioPerson = async (clientId) => {
        const contactInfo = {
            firstName: formData.contactFirstName,
            middleName: formData.contactMiddleName,
            lastName: formData.contactLastName,
            company: formData.contactCompanyID,
            companyID: clientId ? clientId : '',
            address1: formData.contactAddress1,
            address2: formData.contactAddress2,
            city: formData.contactCity,
            state: formData.contactState,
            zip: formData.contactZip,
            country: formData.contactCountry,
            email: formData.contactEmail,
        };
      
        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
        const clioContact = {
          data: {
            "addresses":[
              {
                "name": "Billing",
                "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
                "city":`${contactInfo.city}`,
                "province":`${contactInfo.state}`,
                "postal_code":`${contactInfo.zip}`,
                "country":`${contactInfo.country}`,
              }
            ],
            "clio_connect_email":`${contactInfo.email}`,
            "company": {
              "id":clientId ? clientId : ''
            },
            "email_addresses":[
              {
                "name":clientId ? "Work" : "Home",
                "address":`${contactInfo.email}`,
                "default_email":true,
              }
            ],
            "first_name":`${contactInfo.firstName}`,
            "last_name":`${contactInfo.lastName}`,
            "middle_name":`${contactInfo.middleName}`,
            "type":"Person",
          }
        };
      
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(clioContact)
            });
      
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
      
            const data = await response.json();
      
            return data.data.id;
          
        } catch (error) {
            console.error('Error creating new Clio contact:',error);
        }
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption ? selectedOption.value : '');
        // Automatically set the country to "United States" when a state is selected
        if (selectedOption) {
            setSelectedCountry("United States"); // Directly using the full name
            onFormDataChange({
                ...formData,
                contactState: selectedOption.value,
                contactCountry: "United States" // Ensure formData uses the full name
            });
        } else {
            onFormDataChange({
                ...formData,
                contactState: '',
                contactCountry: '' // Reset country if no state is selected
            });
        }
    };

    const handleCountryChange = (selectedOption) => {
        // setSelectedCountry(selectedOption ? selectedOption.value : '');
        setSelectedCountry(selectedOption ? selectedOption.label : '');
        // Reset the state selection if the selected country is not "USX"
        if (selectedOption && selectedOption.value !== "USX") {
            setSelectedState('');
            // onFormDataChange({ contactCountry: selectedOption.value, contactState: '' });
            onFormDataChange({ contactCountry: selectedOption.label, contactState: '' });
        } else {
            // onFormDataChange({ contactCountry: selectedOption.value });
            onFormDataChange({ contactCountry: selectedOption.label });
        }
    };

    const handleForgotPassword = async () => {
        if(!email) {
            setErrorMessage('Please enter email address associated with account');
            return;
        }
        setLoading(true);
        setResettingPassword(true);
        setErrorMessage('');
        try {
            const otpResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (!otpResponse.ok) {
                throw new Error('Failed to send OTP');
            }

            setStep(3); // Move to OTP verification step
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, newPassword })
            });

            if (!response.ok) {
                throw new Error('Failed to reset password. Please try again.');
            }

            alert('Password reset successfully. Please log in with your new password.');
            setStep(1); // Move back to login step
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to the next input box if available
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    const handleVerifyOtp = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setLoading(true);
    
        try {
            const otpString = otp.join('');
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, otp: otpString })
            });
    
            const responseText = await response.text(); // Get the raw response text
            console.log('Raw Response:', responseText); // Log the response
    
            if (!response.ok) {
                throw new Error('Invalid OTP');
            }
    
            // Parse the JSON response
            const responseData = JSON.parse(responseText);
            console.log('Supabase Response:', responseData);
    
            localStorage.setItem('sntmAuth', JSON.stringify(responseData));
    
            const parsedUser = responseData.data.user;
            const parsedSession = responseData.data.session;

            if (resettingPassword) {
                setStep(4); // Proceed to password reset
            } else {
                setUserData(parsedUser);
                setUser(parsedUser);
                setUserSession(parsedSession);
                setStep(1); // Proceed to client data after login
            }
            
            // Clear OTP state after successful verification
            setOtp(new Array(6).fill(''));
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error in handleVerifyOtp:', error);
        } finally {
            setLoading(false);
        }
    };
      

    return (
        <div className='container mt-5 user-registration-container'>
            {step === 1 ? (
                <div className='form-background-container'>
                    <div className='col-12 px-0 form-content'>
                        {errorMessage && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )}
                        <form onSubmit={handleLogin}>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="emailInput" className="form-label user-registration-label">Email:</label>
                                    <input 
                                        type="email" 
                                        id="emailInput" 
                                        className="form-control"
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="passwordInput" className="form-label user-registration-label">Password:</label>
                                    <input 
                                        type="password" 
                                        id="passwordInput" 
                                        className="form-control"
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row user-registration-link-container">
                                <div className="col-12 d-flex justify-content-between">
                                    <button type="submit" className={loading ? "btn btn-secondary client-portal-login-button-loading" : "btn btn-secondary user-registration-cancel-button"} onClick={() => setStep(2)}>Cancel</button>
                                    <button type="submit" className={loading ? "btn btn-primary client-portal-login-button-loading" : "btn btn-primary user-registration-login-button"}>Login</button>
                                </div>
                            </div>
                        </form>
                        <div className="row user-registration-link-container">
                            <div className="col-12 d-flex justify-content-between">
                                <span onClick={() => setStep(2)} className="clickable-span user-registration-link">New Client? Register Here</span>
                                <span onClick={handleForgotPassword} className="clickable-span user-registration-link">Forgot Password</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : step === 2 ? (
                <div className='container sub-modal'>
                    <div className='form-background-container'>
                        <div className='col-12 px-0 form-content'>
                            {attemptedSubmit && !isFormValid && (
                                <div className='alert alert-danger'>
                                    Please complete the required fields before proceeding:
                                </div>
                            )}
                            {errorMessage && (
                                <div className='alert alert-danger'>
                                    {errorMessage}
                                </div>
                            )}
                            <form onSubmit={handleFormSubmit}>
                                <div className="row contactName-container">
                                    <div className="col-sm-4 px-custom name-group">
                                        <label htmlFor='contactFirstName'>First Name*</label>
                                        <input 
                                            type='text' 
                                            id='contactFirstName' 
                                            className='form-control' 
                                            value={formData.contactFirstName} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                    <div className="col-sm-4 px-custom name-group">
                                        <label htmlFor='contactMiddleName'>Middle Name</label>
                                        <input 
                                            type='text' 
                                            id='contactMiddleName' 
                                            className='form-control' 
                                            value={formData.contactMiddleName} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                    <div className="col-sm-4 px-custom name-group">
                                        <label htmlFor='contactLastName'>Last Name*</label>
                                        <input 
                                            type='text' 
                                            id='contactLastName' 
                                            className='form-control' 
                                            value={formData.contactLastName} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 px-custom address-group">
                                        <label htmlFor='contactCompany'>Company Name</label>
                                        <input 
                                            type='text' 
                                            id='contactCompany' 
                                            className='form-control' 
                                            value={formData.contactCompany} 
                                            onChange={handleInputChange} 
                                            maxLength={65}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 px-custom col-md-6 address-group">
                                        <label htmlFor='contactAddress1'>Address 1*</label>
                                        <input 
                                            type='text' 
                                            id='contactAddress1' 
                                            className='form-control' 
                                            value={formData.contactAddress1} 
                                            onChange={handleInputChange} 
                                            maxLength={65}
                                        />
                                    </div>
                                    <div className="col-12 px-custom col-md-6 address-group">
                                        <label htmlFor='contactAddress2'>Address 2</label>
                                        <input 
                                            type='text' 
                                            id='contactAddress2' 
                                            className='form-control' 
                                            value={formData.contactAddress2} 
                                            onChange={handleInputChange} 
                                            maxLength={65}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='contactCity'>City*</label>
                                        <input 
                                            type='text' 
                                            id='contactCity' 
                                            className='form-control' 
                                            value={formData.contactCity} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='contactState'>State</label>
                                        <StateCombobox 
                                            className='form-control' 
                                            id='contactState' 
                                            value={formData.contactState} 
                                            onChange={handleStateChange} 
                                        />
                                    </div>
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='contactZip'>Zip Code*</label>
                                        <input 
                                            type='text' 
                                            id='contactZip' 
                                            className='form-control' 
                                            value={formData.contactZip} 
                                            onChange={handleInputChange} 
                                            maxLength={15}
                                        />
                                    </div>
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='contactCountry'>Country*</label>
                                        <CountryCombobox 
                                            className='form-control' 
                                            id='contactCountry' 
                                            value={formData.contactCountry}
                                            onChange={handleCountryChange} 
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 px-custom name-group">
                                        <label htmlFor='refNo'>Reference/Internal Tracking Number</label>
                                        <input 
                                            type='text' 
                                            id='refNo' 
                                            className='form-control' 
                                            value={formData.refNo} 
                                            onChange={handleInputChange}
                                            maxLength={35} 
                                            />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 px-custom address-group">
                                        <label htmlFor='contactEmail'>Email Address*</label>
                                        <input 
                                            type='text' 
                                            id='contactEmail' 
                                            className='form-control' 
                                            value={formData.contactEmail} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 px-custom address-group">
                                        <label htmlFor='regPassword'>Password*</label>
                                        <input 
                                            type='password' 
                                            id='regPassword' 
                                            className='form-control' 
                                            value={formData.regPassword} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 px-custom address-group">
                                        <label htmlFor='contactEmail'>Would you like any other email accounts to have access to your trademark profile? (separate each address with a space)</label>
                                        <input 
                                            type='text' 
                                            id='altEmails' 
                                            className='form-control' 
                                            value={formData.altEmails} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Register</button>
                            </form>
                            <span onClick={() => setStep(1)} className="clickable-span">Back to Login</span>
                        </div>
                    </div>
                </div>
            ) : step === 3 ? (
                <form onSubmit={handleVerifyOtp}>
                {/* OTP Verification Form */}
                <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-center">
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control otp-input mx-1"
                                maxLength="1"
                                value={data}
                                onChange={(e) => handleOtpChange(e.target, index)}
                                style={{ width: '40px', textAlign: 'center' }}
                            />
                        ))}
                    </div>
                </div>
                {errorMessage && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="alert alert-danger">{errorMessage}</div>
                        </div>
                    </div>
                )}
                <button type="submit" className="btn btn-primary">Verify OTP</button>
            </form>
        ) : step === 4 ? (
            <form onSubmit={handlePasswordReset}>
                {/* New Password Form */}
                <div className="row mb-3">
                    <div className="col-12">
                        <label htmlFor="newPasswordInput" className="form-label">New Password:</label>
                        <input
                            type="password"
                            id="newPasswordInput"
                            className="form-control"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                </div>
                {errorMessage && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="alert alert-danger">{errorMessage}</div>
                        </div>
                    </div>
                )}
                <button type="submit" className="btn btn-primary">Reset Password</button>
            </form>
            ) : null}
        </div>
    );
};

export default UserRegistration;
