import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext.js'
import './App.css';
import Header from './header.js';
import Footer from "./footer.js";

const PricingPage = () => {
    const navigate = useNavigate();
    const { user, loading } = useContext(AuthContext);

    const pricingData = [
        { category: "", services: [{ service: "Trademark Comprehensive Search (standalone)", price: "$250" }] },
        { category: "", services: [{ service: "Trademark Registration Application (includes Knockout Search)", price: "$375" }] },
        {
            category: "Trademark Office Action", services: [
                { service: "Likelihood of Confusion", price: "$500" },
                { service: "Merely Descriptive", price: "$500" },
                { service: "Geographically Descriptive", price: "$500" },
                { service: "Objection to Description of Goods/Services", price: "$100 per class" },
                { service: "Rejection of Specimen", price: "$175" },
                { service: "Objection to Mark Description", price: "$100" },
                { service: "Primarily a Surname", price: "$100" },
                { service: "Disclaimer", price: "$100" },
                { service: "Translation", price: "$100" },
            ]
        },
        {
            category: "Renewals", services: [
                { service: "Section 8 only", price: "$200" },
                { service: "Section 15 only (incontestability)", price: "$200" },
                { service: "Section 9 only", price: "$200" },
                { service: "Section 8 & 15", price: "$375" },
                { service: "Section 8 & 9", price: "$375" },
            ]
        },
        {
            category: "International Applications", services: [
                { service: "Madrid Protocol", price: "$375" },
                { service: "Direct Filing", price: "$100 per country" }
            ]
        },
    ]

    return (
        <div className='pricing-page'>
            {/* <Header /> */}
            <main className='container'>
                <div className='comp text-center my-4'>
                    <h1 className='comp-name'>Pricing</h1>
                    <p className='comp-descrip'>Clear, Transparent Pricing</p>
                </div>
                <div className='main-section mb-4'>
                    <p>San Novus Trademark values a transparent and simple pricing structure. We offer fixed prices for our services,
                        billing by project rather than on an hourly basis. This approach ensures that you know exactly what to expect in terms of costs,
                        without any surprises.</p>
                </div>
                <div className='price-table'>
                    {pricingData.map((category, index) => (
                        <div key={`category-${index}`} className='mb-4'>
                            {category.category && (
                                <div className='category-title font-weight-bold bg-primary text-white p-2'>{category.category}</div>
                            )}
                            <div className='d-flex flex-column'>
                                {category.services.map((service, serviceIndex) => (
                                    <div key={`service-${index}-${serviceIndex}`} className='d-flex justify-content-between p-2 service-row'>
                                        <div className='service-name'>{service.service}</div>
                                        <div className='service-price text-center'>{service.price}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='main-section'>
                    <p>Please note: Listed prices do not include government filing fees. These fees are set by the U.S.
                        Patent and Trademark Office and are subject to change. For the most current fee schedule, please visit 
                        the <a href='https://www.uspto.gov/learning-and-resources/fees-and-payment/uspto-fee-schedule#Trademark%20Fees' className='text-primary'>
                            USPTO Fee Schedule</a>.</p>
                </div>
            </main>
        </div>
    );

};

export default PricingPage;