import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";
import RegisterTrademarkForm from "./registerTrademarkForm.js";
import Footer from "./footer.js";

const InternationalTrademarksPage = () => {
  const [showForm, setShowForm] =   useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if(e.target.className === 'modal') {
      setShowForm(false);
    }
  };        
  return (
      <div className='international-trademarks' style={{ fontFamily: 'Arial, sans-serif'}}>
        {/* <Header /> */}
        <div className='comp'>
          <span className='comp-name'>International Trademark Applications</span>
          <span className='comp-descrip'>Expanding Your Brand's Reach Globally</span>
        </div>
        <main>
          <div className='main-section-alt'>
            <h3>Thinking of taking your brand international?</h3>
            <p>San Novus Trademark is here to guide you in registering your trademark 
              beyond the United States. We understand the complexities of the global marketplace and offer tailored foreign filing strategies 
              to align with your needs and budget.</p>
          </div>
          <div className='main-section'>
            <h3>Adaptable Strategies for Global Protection</h3>
            <p>Our approach to international trademark protection is designed to be versatile. Whether your goal is to 
              penetrate specific foreign markets or to secure broad international coverage, our seasoned team of trademark professionals is equipped 
              to devise a strategy that aligns seamlessly with your brand's global aspirations.</p>
          </div>
          <div className='main-section-alt'>
            <h3>Expertise in WIPO Filings and Global Connections</h3>
            <p>By filing applications directly with the World Intellectual Property Organization (“WIPO”) and utilizing our 
              vast network of associate attorneys across the globe, we offer a dual approach. This strategy merges the efficiency of centralized filing 
              with the localized expertise of attorneys in your target countries, ensuring comprehensive and cost-effective solutions for your international 
              trademark needs.</p>
          </div>
          <div className='main-section'>
            <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
            <FAQs tags="international" />
          </div>
        </main>
      </div>
    );
  };
  
  export default InternationalTrademarksPage;