// import React, { useState } from 'react';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import HomePage from './homePage.js';
// import ServicesPage from './services.js';
// import TrademarkSearchesPage from './trademark-searches.js';
// import TrademarkRegistrationApplications from './trademark-registration-applications.js';
// import TrademarkOARsPage from './trademark-oar.js';
// import TrademarkSOUPage from './trademark-sou.js';
// import TrademarkRenewalsPage from './trademark-renewals.js';
// import InternationalTrademarksPage from './international-trademarks.js';
// import AboutPage from './about.js';
// import WhyUsPage from './why-us.js';
// import FAQPage from './FAQPage.js';
// import PricingPage from './pricing.js';
// import Login from './Login.js';
// import AuthContext from './AuthContext.js';
// import { AuthProvider } from './AuthContext.js';
// import Header from './header.js';
// import Footer from './footer.js';
// import AuthNavigationHandler from './authNavigationHandler.js';
// import ProtectedRoute from './protectedRoute.js';
// import MattersList from './mattersList.js';
// import AmazonBrandRegistryPage from './amazonBrandRegistry.js';
// import ContactUs from './contactUs.js';

// const App = () => {
//   const [user, setUser] = useState(null);
//   return (

//     <Router>
//       <AuthProvider>
//         <div id='root'>
//           <Header />
//           <div className='site-content'>
//             <Routes>
//               <Route element={<ProtectedRoute />}>  
//                 <Route path='/' element={<HomePage />} />
//                 <Route path='/services' element={<ServicesPage />} />
//                 <Route path='/trademark-searches' element={<TrademarkSearchesPage />} />
//                 <Route path='/trademark-registration-applications' element={<TrademarkRegistrationApplications />} />
//                 <Route path='/trademark-oar' element={<TrademarkOARsPage />} />
//                 <Route path='/trademark-sou' element={<TrademarkSOUPage />} />
//                 <Route path='/trademark-renewals' element={<TrademarkRenewalsPage />} />
//                 <Route path='/amazon-brand-registry' element={<AmazonBrandRegistryPage />} />
//                 <Route path='/international-trademarks' element={<InternationalTrademarksPage />} />
//                 <Route path='/about' element={<AboutPage />} />
//                 <Route path='/why-us' element={<WhyUsPage />} />
//                 <Route path='/faq' element={<FAQPage />} />
//                 <Route path='/contact-us' element={<ContactUs />} />
//                 {/* <Route element={<ProtectedRoute />}> */}
//                   <Route path='/pricing' element={<PricingPage />} />
//                   <Route path='mattersList' element={<MattersList />} />
//                 {/* </Route> */}
//               </Route>
//               <Route path='/atty-login' element={<Login />} />
//             </Routes>
//           </div>
//           <Footer />
//         </div>
//       </AuthProvider>
//     </Router>
//   );
// }

// export default App;

// App.js
import React, { useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './homePage.js';
import ServicesPage from './services.js';
import TrademarkSearchesPage from './trademark-searches.js';
import TrademarkRegistrationApplications from './trademark-registration-applications.js';
import TrademarkOARsPage from './trademark-oar.js';
import TrademarkSOUPage from './trademark-sou.js';
import TrademarkRenewalsPage from './trademark-renewals.js';
import InternationalTrademarksPage from './international-trademarks.js';
import AboutPage from './about.js';
import WhyUsPage from './why-us.js';
import FAQPage from './FAQPage.js';
import PricingPage from './pricing.js';
import Login from './Login.js';
import { AuthProvider } from './AuthContext.js';
import Header from './header.js';
import Footer from './footer.js';
import ProtectedRoute from './protectedRoute.js';
import MattersList from './mattersList.js';
import AmazonBrandRegistryPage from './amazonBrandRegistry.js';
import ContactUs from './contactUs.js';
import Flowchart from './tmProsecution.js';
import ClientPortalLogin from './clientPortalLogin.js';
import PortalComponent from './portalComponent.js';
import UserRegistration from './userRegistration.js';

const App = () => {
  const [user, setUser] = useState(null);

  return (
    <Router>
      <AuthProvider>
        <div id='root'>
          <Header />
          <div className='site-content'>
            <Routes>
              {/* Public Routes */}
              <Route path='/atty-login' element={<Login />} />

              {/* Protected Routes */}
              <Route element={<ProtectedRoute />}>
                <Route path='/' element={<HomePage />} />
                <Route path='/services' element={<ServicesPage />} />
                <Route path='/trademark-searches' element={<TrademarkSearchesPage />} />
                <Route path='/trademark-registration-applications' element={<TrademarkRegistrationApplications />} />
                <Route path='/trademark-oar' element={<TrademarkOARsPage />} />
                <Route path='/trademark-sou' element={<TrademarkSOUPage />} />
                <Route path='/trademark-renewals' element={<TrademarkRenewalsPage />} />
                <Route path='/amazon-brand-registry' element={<AmazonBrandRegistryPage />} />
                <Route path='/international-trademarks' element={<InternationalTrademarksPage />} />
                <Route path='/about' element={<AboutPage />} />
                <Route path='/why-us' element={<WhyUsPage />} />
                <Route path='/faq' element={<FAQPage />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/pricing' element={<PricingPage />} />
                <Route path='/mattersList' element={<MattersList />} />
                <Route path='/tmProsecution' element={<Flowchart />} />
              </Route>
              <Route path='/clientPortal' element={<ClientPortalLogin />} />
              {/* <Route path='/clientPortal' element={<PortalComponent />} /> */}
              <Route path='/newUserRegistration' element={<UserRegistration />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
