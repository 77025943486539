import React, { useState } from 'react';
import Select from 'react-select';

const entities = [
    {name: "Same as contact on previous page", abbreviation: "previous"},
    {name: "Individual different from contact on previous page", abbreviation: "individual"},
    {name: "Business entity different from contact on previous page", abbreviation: "business"},
  ];

  const options = entities.map(entity => ({
    value: entity.abbreviation,
    label: entity.name,
  }));

  const OwnerSelection = ({ value, onChange }) => {
    const selectedOption = options.find(option => option.value === value)

    const handleChange = (selectedOption) => {
        onChange(selectedOption ? selectedOption.value : '');
    };

    const customStyles = {
      control: (styles) => ({
        ...styles,
        height: '40px',
        minHeight: '40px',
        width: '100%',
        maxWidth: '15rem',
      }),
      singleValue: (styles) => ({...styles,
      color: 'black',
      }),
      valueContainer: (styles) => ({
        ...styles,
        height: '30px',
        padding: '0 5px',
        outerWidth: '250x'

      }),
      input: (styles) => ({
        ...styles,
        margin: '0px',
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        height: '30px',
      }),
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? 'lightgray' : isSelected ? 'gray' : null,
          color: 'black',
        };
      },
      placeholder: (styles) => ({
        ...styles,
        color: 'black',
      }),
    };

    return (
        <Select
          styles={customStyles}    
          options={options}
            onChange={handleChange}
            value={selectedOption}
            placeholder="Trademark Ownership"
            isSearchable={true}
            name='ownerSelection'
            id='ownerSelection'
        />
    );

};
  
  export default OwnerSelection;