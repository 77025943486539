// import React, { useState, useEffect } from 'react';
// import FAQList from './faqData.js';

// const FAQs = ({ tags }) => {
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [initializedFromHash, setInitializedFromHash] = useState(false);

//   // const filteredFAQs = FAQList.filter(faq => tags.includes(faq.tag));
//   // Filtering FAQs based on selected tags
//   const filteredFAQs = FAQList.filter(faq => {
//     // Ensure faq.tags is defined and is an array
//     const faqTags = faq.tags || [];
//     return faqTags.some(tag => tags.includes(tag));
//   });

//   const toggleAccordion = (index) => {
//     setActiveIndex(prevIndex => (prevIndex === index ? null : index));
//   };

//   // Check if there is a specific FAQ to open based on URL hash
//   useEffect(() => {
//     if (!initializedFromHash) {
//       const hash = window.location.hash;
//       if (hash) {
//         const index = filteredFAQs.findIndex(faq => `faq-${faq.id}` === hash.substring(1));
//         if (index !== -1) {
//           setActiveIndex(index);
//           setInitializedFromHash(true);
//           // Scroll to the specific FAQ
//           document.getElementById(hash.substring(1)).scrollIntoView();
//         }
//       }
//     }
//   }, [filteredFAQs, initializedFromHash]);

//   return (
//     <div className="accordion my-4" id="accordionFAQ">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-12">
//             {filteredFAQs.map((faq, index) => (
//               <div className="accordion-item" key={faq.id} id={`faq-${faq.id}`}>
//                 <h2 className="accordion-header" id={`heading-${index}`}>
//                   <button
//                     className={`accordion-question ${activeIndex === index ? '' : 'collapsed'}`}
//                     type="button"
//                     onClick={() => toggleAccordion(index)}
//                     aria-expanded={activeIndex === index}
//                     aria-controls={`collapse-${index}`}
//                   >
//                     {faq.question}
//                   </button>
//                 </h2>
//                 <div
//                   id={`collapse-${index}`}
//                   className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
//                   aria-labelledby={`heading-${index}`}
//                   data-bs-parent="#accordionFAQ"
//                 >
//                   <div className="accordion-answer">{faq.answer}</div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FAQs;

import React, { useState, useEffect } from 'react';
import FAQList from './faqData.js';

const FAQs = ({ tags }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [initializedFromHash, setInitializedFromHash] = useState(false);

  // Filtering FAQs based on selected tags
  const filteredFAQs = FAQList.filter(faq => {
    // Ensure faq.tags is defined and is an array
    const faqTags = faq.tags || [];
    return faqTags.some(tag => tags.includes(tag));
  });

  const toggleAccordion = (index) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  // Check if there is a specific FAQ to open based on URL hash
  useEffect(() => {
    if (!initializedFromHash) {
      const hash = window.location.hash;
      if (hash) {
        const index = filteredFAQs.findIndex(faq => `faq-${faq.id}` === hash.substring(1));
        if (index !== -1) {
          setActiveIndex(index);
          setInitializedFromHash(true);
          // Scroll to the specific FAQ
          document.getElementById(hash.substring(1)).scrollIntoView();
        }
      }
    }
  }, [filteredFAQs, initializedFromHash]);

  return (
    <div className="accordion my-4" id="accordionFAQ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            {filteredFAQs.map((faq, index) => (
              <div className="accordion-item" key={faq.id} id={`faq-${faq.id}`}>
                <h2 className="accordion-header" id={`heading-${index}`}>
                  <button
                    className={`accordion-question ${activeIndex === index ? '' : 'collapsed'}`}
                    type="button"
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={activeIndex === index}
                    aria-controls={`collapse-${index}`}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`collapse-${index}`}
                  className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                  aria-labelledby={`heading-${index}`}
                  data-bs-parent="#accordionFAQ"
                >
                  <div className="accordion-answer">{faq.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
