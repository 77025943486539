// descriptionInput.js
import React, { useState, useEffect } from 'react';

const DescriptionInput = ({ isOpen, description, placeholders, onClose, onSave, initialInputs, classId, inputKey, existingValue, onSaveDescriptionInput }) => {
  const [inputs, setInputs] = useState({});

    // Parse placeholders when the component receives a new description
    useEffect(() => {
        const regex = /{([^}]+)}/g;
        let match;
        const newInputs = {};
        while ((match = regex.exec(description)) !== null) {
        // For each placeholder found, initialize an input state for it
        newInputs[match[1]] = '';
        }
        setInputs(newInputs);
    }, [description]);

    const handleInputChange = (key, value) => {
        setInputs(prev => ({ ...prev, [key]: value }));
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Process and compile the updated description with input values
    //     let updatedDescription = description;
    //     Object.keys(inputs).forEach(key => {
    //       updatedDescription = updatedDescription.replace(`{${key}}`, `[[${inputs[key]}]]`);
    //     });
    //     onSaveDescriptionInput(classId, description, updatedDescription, inputs);
    //     onClose(); // Close the modal
    // };

    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Split the original description into core segments and placeholders
      const segments = description.split(/\{[^}]+\}/);
      const placeholders = description.match(/\{[^}]+\}/g) || [];
  
      // Filter out empty strings from segments and trim each segment
      let core = segments.map(segment => segment.trim()).filter(segment => segment);
  
      // Initialize an object to hold the mappings of placeholder texts to user inputs
      let additionalInputs = {};
  
      // Map each placeholder to its corresponding user input
      placeholders.forEach((placeholder, index) => {
          // Remove curly braces to match the key in inputs object
          const key = placeholder.replace(/[{}]/g, '').trim();
          // Assuming inputs object has a matching key for the placeholder
          if (inputs[key] !== undefined) {
              // Construct a key for the additionalInputs object that reflects the placeholder's position
              // additionalInputs[`input${index + 1}`] = inputs[key];
              additionalInputs[index] = inputs[key];
          }
      });
  
      // Now pass the core array and additionalInputs object
      onSaveDescriptionInput(classId, core, additionalInputs);
      onClose(); // Close the modal
  };
  
  
    
  

  if (!isOpen) return null;

  return (
    <div className="description-input-form" onClick={(e) => e.stopPropagation()}>
      <div className="description-input-form-content">
        <h2>You have selected a description that requires some additional input. Please fill in the fields below:</h2>
            {Object.keys(inputs).map(key => (
            <div key={key} className='description-input-line'>
                <label>{key}:</label>
                <input
                    type="text"
                    value={inputs[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                    className='search-box'
                />
            </div>
            ))}
        <button onClick={handleSubmit} className='search-button'>Save</button>
        <button onClick={onClose} className='search-button'>Cancel</button>
      </div>
    </div>
  );
};

export default DescriptionInput;
