import React, { useState, useEffect } from 'react';

const OaReview = ({
    formData,
    searchTmApplication,
    searchResults,
    setFormData,
    searchTmDocs,
    oaURL,
    drawingURL,
    analyzingOa,
    oaSummary,
    estimatedCost,
    setEstimatedCost,
    }) => {

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [appSearchNo, setAppSearchNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [docInfo, setDocInfo] = useState('');
    const [issues, setIssues] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const issueKeywords = {
        "2(d)": {description: "Likelihood of Confusion", cost: 500},
        "likelihood of confusion": {description: "Likelihood of Confusion", cost: 500},
        "identification of goods": {description: "Objection to Description of Goods/Services", cost: 100},
        "goods and/or services": {description: "Objection to Description of Goods/Services", cost: 100},
        "goods and services": {description: "Objection to Description of Goods/Services", cost: 100},
        "multiple-class application": {description: "Multiple Classes Found - Deficient Filing Fee", cost: 350},
        "mark description": {description: "Objection to Mark Description", cost: 100},
        "2(e)(1)": {description: "Merely Descriptive", cost: 100},
        "merely descriptive": {description: "Merely Descriptive", cost: 100},
        "geographic": {description: "Geographically Descriptive", cost: 500},
        "geographically": {description: "Geographically Descriptive", cost: 500},
        "specimen": {description: "Rejection of Specimen", cost: 175},
        "1 and 45": {description: "Rejection of Specimen", cost: 175},
        "surname": {description: "Primarily a Surname", cost: 100},
        "living individual": {description: "Primarily a Surname", cost: 100},
        "domicile address": {description: "Objection to Address on Record", cost: 100},
        "disclaimer": {description: "Disclaimer Requirement", cost: 100},
        "translation": {description: "Translation Requirement", cost: 100},
    };

    useEffect(() => {
        if (oaSummary) {
            const processedIssues = processIssues(oaSummary);
            setIssues(processedIssues);
            const total = processedIssues.reduce((acc, { cost }) => acc + Number(cost), 0);
            setTotalCost(total);
        }
    }, [oaSummary]);
    

    const processIssues = (summaryText) => {
        const issues = summaryText.split("•").filter(issue => issue.trim() !== "");
        const results = issues.map(issue => {
            const trimmedIssue = issue.trim().toLowerCase();
            for (const [keyword, { description, cost }] of Object.entries(issueKeywords)) {
                if (trimmedIssue.includes(keyword)) {
                    if (keyword === "multiple-class application") {
                        // Handle the special case for multiple classes
                        return { description, cost: `${cost} (per class)`, specialCase: true };
                    }
                    return { description, cost };
                }
            }
            return null;
        }).filter(issue => issue !== null);
    
        return results;
    };
    
    const calculateTotal = (issues) => {
        let total = 0;
        let additionalText = '';
        issues.forEach(issue => {
            if (issue.specialCase) {
                additionalText = ' + $350 per additional class added';
            } else {
                total += Number(issue.cost);
            }
        });
        setEstimatedCost(total);
        return `${total}${additionalText}`;
    };
    

    // return (
    //     <div>
    //         <div className='oa-summary'>
    //             {(analyzingOa.isFound && !analyzingOa.isAnalyzed) && <div className='analysis-error'>Analyzing trademark office action...</div> }
    //             {(analyzingOa.isAnalyzed && !oaSummary) && (
    //                 <div className='analysis-error'>
    //                     <p>We ran into an issue analyzing the office action. Please continue your submission and we'll review the office action 
    //                         on our end. We'll reach out to you via email once we've had an opporutnity to look things over and determine what needs to be
    //                         addressed. Please allow 24 - 72 hours for our review.
    //                     </p>
    //                 </div>
    //             )}
    //             {!analyzingOa.isFound && (
    //                 <div className='analysis-error'>
    //                     <p>We were unable to locate an office action in the record for your trademark application. This may be due to a technical 
    //                         issue with the U.S. Patent and Trademark Office. Please continue your submission and 
    //                         our team will look into this further. We'll reach out to you via email once we've had an opporutnity to look things over and 
    //                         determine what needs to be addressed. Please allow 24 - 72 hours for our review.
    //                     </p>
    //             </div>
    //             )}
    //             {(analyzingOa.isAnalyzed && oaSummary) && (<div /* className='trademark-review-block' */>
    //                 <div className='analysis-summary'>
    //                     <p>Summary of Issues:</p>
    //                     <table className='analysis-summary-table'>
    //                         <thead>
    //                             <tr>
    //                                 <th>Issue</th>
    //                                 <th>Cost</th>
    //                             </tr>
    //                         </thead>
    //                         <tbody>
    //                             {processIssues(oaSummary).map((item, index) => (
    //                                 <tr key={index}>
    //                                     <td>{item.description}</td>
    //                                     <td>${item.cost}</td>
    //                                 </tr>
    //                             ))}
    //                             <tr className='analysis-total'>
    //                                 <td style={{textAlign:'right'}}><strong>Total</strong></td>
    //                                 <td><strong>${calculateTotal(processIssues(oaSummary))}</strong></td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             </div>)}
    //         </div>
    //     </div>
    // );

    return (
        <div className='container'>
            <div className='oa-summary'>
                {(analyzingOa.isFound && !analyzingOa.isAnalyzed) && <div className='alert alert-warning'>Analyzing trademark office action...</div> }
                {(analyzingOa.isAnalyzed && !oaSummary) && (
                    <div className='alert alert-danger'>
                        <p>We ran into an issue analyzing the office action. Please continue your submission and we'll review the office action 
                            on our end. We'll reach out to you via email once we've had an opportunity to look things over and determine what needs to be
                            addressed. Please allow 24 - 72 hours for our review.
                        </p>
                    </div>
                )}
                {!analyzingOa.isFound && (
                    <div className='alert alert-danger'>
                        <p>We were unable to locate an office action in the record for your trademark application. This may be due to a technical 
                            issue with the U.S. Patent and Trademark Office. Please continue your submission and 
                            our team will look into this further. We'll reach out to you via email once we've had an opportunity to look things over and 
                            determine what needs to be addressed. Please allow 24 - 72 hours for our review.
                        </p>
                    </div>
                )}
                {(analyzingOa.isAnalyzed && oaSummary) && (
                    <div>
                        <div className='analysis-summary'>
                            <p>Summary of Issues:</p>
                            <table className='table table-striped table-responsive'>
                                <thead>
                                    <tr>
                                        <th>Issue</th>
                                        <th>Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {processIssues(oaSummary).map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.description}</td>
                                            <td>${item.cost}</td>
                                        </tr>
                                    ))}
                                    <tr className='analysis-total'>
                                        <td style={{textAlign:'right', color:'red'}}><strong>Total</strong></td>
                                        <td style={{textAlign:'center', color:'red'}}><strong>${calculateTotal(processIssues(oaSummary))}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default OaReview;